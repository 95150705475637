/* eslint-disable no-restricted-globals */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import '../HotelComponent/Discount.css'
import './Event.css'
import { Button, Image, Input, Table, TimePicker } from 'antd'
import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import PlusIco from '../../../images/PlusIco.svg'
import Dropdown from '../../Dropdown/Dropdown'
import Remove from '../../../images/close-black.svg'
import Event_Logo from '../../../images/local_event_logo.svg'
import PersonImage from '../../../images/menimage.svg'
import Events from '../../../images/event.svg'
import roomImage from '../../../images/roomimg.svg'
import Avatar1 from '../../../images/Hotel_Avatar.svg'
import Avatar2 from '../../../images/Hotel_Avtar2.svg'
import Edit from '../../../images/edit_icon.svg'
import Delete from '../../../images/delete_icon.svg'
import speaker_img1 from '../../../images/speaker_img1.svg'
import speaker_img2 from '../../../images/speaker_img2.svg'
import speaker_img3 from '../../../images/speaker_img3.svg'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { capitalizeFirstLetter } from '../../../helpers/Utility'
import { imageUploadLocal, industryTypeList, performerTypeList, s3ImagePathLocal } from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import plusimg from '../../../images/plusimg.svg'
import Loader from '../../Loader/Loader'
import plusgredient from '../../../images/plus-rounded.svg'

const Event = () => {
  const SpeakerInfo = [
    {
      name: 'John Lock',
      image: PersonImage,
      description: 'Motivational Speaker'
    },
    {
      name: 'John Lock',
      image: PersonImage,
      description: 'Motivational Speaker'
    },
    {
      name: 'John Lock',
      image: PersonImage,
      description: 'Motivational Speaker'
    },
    {
      name: 'John Lock',
      image: PersonImage,
      description: 'Motivational Speaker'
    }
  ]

  const EventList = [
    {
      Imgs: Avatar1,
      Name: 'Rock am Ring',
      Image: Events,
      Date: 'Sep 21, 2025',
      Timing: '09:00 AM-06:00 PM',
      Industry: 'Home & Office',
      TicketQty: '4.5k'
      // Speakers: '121'
    },
    {
      Imgs: Avatar2,
      Name: 'Ivan Bundura',
      Image: Events,
      Date: 'Mar 01, 2025',
      Timing: '07:00 AM-04:00 PM',
      Industry: 'Arts & Crafts',
      TicketQty: '2.5k'
      // Speakers: '121'
    }
  ]

  const [isAddDiscount, setIsAddDiscount] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [isAddRoom, setIsAddRoom] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    name: '',
    industry_id: '',
    performer_id: '',
    opening_time: '',
    closing_time: '',
    min_price: '',
    max_price: '',
    tickets_qty: '',
    date: '',
    timing: '',
    description: '',
    images: []
  })
  const [isIndustryType, setIsIndustryType] = useState([])
  const [isPerformerType, setIsPerformerType] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)

  const addDiscount = () => {
    setIsAddDiscount(true)
  }
  const handleCancels = () => {
    setIsAddDiscount(false)
    setIsRoomFeature(false)
    setIsAddRoom(false)
  }
  const nextClick = () => {
    setIsAddDiscount(false)
    setIsRoomFeature(true)
  }
  const addEventSave = () => {
    setIsAddDiscount(false)
    setIsRoomFeature(false)
  }
  const eventEdit = () => {
    setIsAddRoom(true)
  }
  const eventEditSave = () => {
    setIsAddRoom(false)
  }
  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const columns = [
    {
      title: 'EVENT NAME & IMAGE',
      dataIndex: 'Name',
      key: 'Name',
      render: (text, record) => (
        <div className="image_name">
          <Image
            width={100}
            src={record.Imgs}
            alt="img"
            style={{ marginRight: 10 }}
          />
          <span>
            {record.Name.length > 15
              ? `${record.Name.substring(0, 15)}...`
              : record.Name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      onFilter: (value, record) => record.Name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'DATE',
      dataIndex: 'Date',
      key: 'Date',
      sorter: (a, b) => a.Date.localeCompare(b.Date)
    },
    {
      title: 'TIMING',
      dataIndex: 'Timing',
      key: 'Timing',
      sorter: (a, b) => a.Timing.localeCompare(b.Timing)
    },
    {
      title: 'INDUSTRY',
      dataIndex: 'Industry',
      key: 'Industry',
      sorter: (a, b) => a.Industry.localeCompare(b.Industry)
    },
    {
      title: 'TICKET QTY',
      dataIndex: 'TicketQty',
      key: 'TicketQty',
      sorter: (a, b) => a.TicketQty.localeCompare(b.TicketQty)
    },
    {
      title: 'SPEAKERS',
      dataIndex: 'Speakers',
      key: 'Speakers',
      render: () => (
        <div className="follower-shows">
          <div className="follower-img-group d-flex">
            <img alt="speaker 1" src={speaker_img1} />
            <img alt="speaker 2" src={speaker_img2} />
            <img alt="speaker 3" src={speaker_img3} />
            <div className="plus_speaker">
              <span>+2</span>
            </div>
          </div>
        </div>
      ),
      sorter: (a, b) => a.Speakers.localeCompare(b.Speakers)
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div className="image-buttons-discounts">
          <Button
            icon={<img src={Edit} alt="Edit" />}
            onClick={() => eventEdit(record)}
          />
          <Button
            icon={<img src={Delete} alt="Delete" />}
            onClick={() => eventEdit(record.key)}
          />
        </div>
      )
    }
  ]

  const filteredData = EventList.filter((item) => item.Name.toLowerCase().includes(searchText))

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const onChange = (time, timeString) => {
    setInputFields((prevState) => ({
      ...prevState,
      time_left: timeString
    }))
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'hotel')

        const uploadResponse = await imageUploadLocal(coverFormData)

        if (!uploadResponse.data.success) {
          throw new Error(
            uploadResponse.data.message || `${fieldName} upload failed`
          )
        }

        const decryptedImageData = dataDecrypt(uploadResponse.data.data)

        setInputFields((prev) => {
          const prevData = prev[fieldName]
          if (
            (index !== undefined || index !== null) &&
            Array.isArray(prevData)
          ) {
            prevData[index] = decryptedImageData
          }
          return {
            ...prev,
            [fieldName]: prevData
          }
        })
      }

      reader.readAsDataURL(file)
    }
  }

  const onChangeOpenTime = (time, timeString) => {
    setInputFields((prevState) => ({
      ...prevState,
      opening_time: timeString
    }))
  }
  const onChangeCloseTime = (time, timeString) => {
    setInputFields((prevState) => ({
      ...prevState,
      closing_time: timeString
    }))
  }

  const typeofIndustryListData = () => {
    industryTypeList({}).then((res) => {
      if (res.data.success) {
        setIsIndustryType(dataDecrypt(res.data.data))
      }
    })
  }

  const typeofPerformerListData = () => {
    performerTypeList({}).then((res) => {
      if (res.data.success) {
        setIsPerformerType(dataDecrypt(res.data.data))
      }
    })
  }

  const getIndustryName = (id) => {
    const categoryObj = isIndustryType.find(
      (cat) => cat.id === id
    )
    return categoryObj ? categoryObj.industry_type : 'Select Industry'
  }

  const getPerformerName = (id) => {
    const categoryObj = isPerformerType.find(
      (cat) => cat.id === id
    )
    return categoryObj ? categoryObj.performer_type : 'Select Performer'
  }

  const datePickerRef = useRef(null)

  const handleDateChange = (date) => {
    const today = new Date()
    const minDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate()
    )

    if (date >= minDate) {
      setInputFields({
        ...inputFields,
        expiry_date: moment(date).format('YYYY-MM-DD')
      })
    } else {
      setInputFields({
        ...inputFields,
        expiry_date: null
      })

      if (datePickerRef.current) {
        datePickerRef.current.setOpen(true)
      }
    }
  }

  // const formatDateToISO = (dateString) => {
  //   if (!dateString) return null

  //   const date = new Date(dateString)
  //   if (isNaN(date)) return null

  //   const year = date.getFullYear()
  //   const month = String(date.getMonth() + 1).padStart(2, '0')
  //   const day = String(date.getDate()).padStart(2, '0')

  //   return `${year}-${month}-${day}`
  // }

  const today = new Date()
  const minYear = today.getFullYear()
  const maxYear = today.getFullYear() + 10

  const years = []
  for (let year = minYear; year <= maxYear; year++) {
    years.push(year)
  }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImagePathLocal + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  useEffect(() => {
    typeofIndustryListData()
    typeofPerformerListData()
  }, [])

  return (
    <div className="">
      <div className="position-relative">
        {EventList.length > 2 ? (
          <div className="add_room_text">
            <img className="d-block" src={Event_Logo} alt="bedroom" />
            <h3>CREATE YOUR FIRST DEAL</h3>
            <p>You have not created any deal yet</p>
            <div className="add-video-rows" onClick={addDiscount}>
              <div className="add_new_content">
                <img src={PlusIco} alt="" />
                Create Deal
              </div>
            </div>
          </div>
        ) : (
          <div className="local-room-main">
            <h3 className="d-flex align-items-center justify-content-between">
              Events
              <button
                type="button"
                className="primary-btn"
                onClick={addDiscount}
              >
                <img src={PlusIco} alt="add" />
                Create Event
              </button>
            </h3>
            <div className="discount_list">
              <Input
                placeholder="Search by Item Name"
                onChange={handleSearch}
                style={{ width: 200, marginBottom: '1rem' }}
              />
              <div className="discount_list">
                <Table
                  columns={columns}
                  dataSource={filteredData}
                  pagination={{ pageSize: 5 }}
                />
              </div>
            </div>
          </div>
        )}

        {isAddDiscount && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal">
              <div className="type_close mb-2">
                <div className="d-flex align-items-center col-gap-10">
                  ADD EVENT
                </div>
                <button type="button" onClick={handleCancels}>
                  <img src={Remove} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0">
                <div className="input-grid">
                  <div>
                    <label>Event Name</label>
                    <CommonInput
                      placeholder="Enter Event Name"
                      name="name"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.name)}
                    />
                  </div>
                  <div>
                    <label>Industry</label>
                    <Dropdown
                      title={getIndustryName(inputFields.industry_id)}
                      name="room_type_id"
                      onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        industry_id: d.id
                      })
                    }}
                      Data={isIndustryType.map((d) => ({
                      title: d.industry_type,
                      id: d.id
                    }))}
                      customClass={
                      inputFields.room_type_id
                        ? 'selected_class_local'
                        : 'local-dropdown'
                    }
                    />
                  </div>
                </div>
                <div className="input-grid mb-2">
                  <div>
                    <label>Performer</label>
                    <Dropdown
                      title={getPerformerName(inputFields.performer_id)}
                      name="room_type_id"
                      onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        performer_id: d.id
                      })
                    }}
                      Data={isPerformerType.map((d) => ({
                      title: d.performer_type,
                      id: d.id
                    }))}
                      customClass={
                      inputFields.room_type_id
                        ? 'selected_class_local'
                        : 'local-dropdown'
                    }
                    />
                  </div>
                  <div>
                    <label>Expiry Date</label>
                    <div className="calendar">
                      <DatePicker
                        ref={datePickerRef}
                        // selected={formatDateToISO(inputFields.expiry_date)}
                        selected={
                          inputFields.expiry_date
                            ? new Date(inputFields.expiry_date)
                            : null
                        }
                        required
                        showMonthDropdown
                        autoComplete="off"
                        showYearDropdown
                        onChange={handleDateChange}
                        name="expiry_date"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Please Choose Date"
                        minDate={new Date()}
                        onKeyDown={(e) => {
                            e.preventDefault()
                        }}
                        className="custom-datepicker"
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled
                        }) => (
                          <div className="datepicker-header">
                            <button
                              type="button"
                              className="datepicker-button"
                              onClick={(e) => {
                                e.preventDefault()
                                decreaseMonth()
                              }}
                              disabled={prevMonthButtonDisabled}
                            >
                              {'<'}
                            </button>
                            <select
                              className="datepicker-select"
                              value={date.getFullYear()}
                              onChange={({ target: { value } }) => changeYear(Number(value))}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <select
                              className="datepicker-select"
                              value={months[date.getMonth()]}
                              onChange={({ target: { value } }) => {
                                changeMonth(months.indexOf(value))
                              }}
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                            <button
                              type="button"
                              className="datepicker-button"
                              onClick={(e) => {
                                e.preventDefault()
                                increaseMonth()
                              }}
                              disabled={nextMonthButtonDisabled}
                            >
                              {'>'}
                            </button>
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>Opening Time</label>
                    <TimePicker
                    // className="ant-design"
                      name="opening_time"
                      onChange={onChangeOpenTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder="Select from options"
                      value={
                      inputFields?.opening_time
                        ? dayjs(inputFields?.opening_time, 'HH:mm')
                        : null
                    }
                      format="HH:mm"
                      className="ant-design event_time"
                      style={{ 'margin-top': '0px' }}
                    />
                  </div>
                  <div>
                    <label>Closing Time</label>
                    <TimePicker
                    // className="ant-design"
                      name="closing_time"
                      onChange={onChangeCloseTime}
                      showNow={false}
                      showOk={false}
                      suffixIcon={null}
                      placeholder="Select from options"
                      value={
                      inputFields?.closing_time
                        ? dayjs(inputFields?.closing_time, 'HH:mm')
                        : null
                    }
                      format="HH:mm"
                      className="ant-design event_time"
                      style={{ 'margin-top': '0px' }}
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>Minimum Price</label>
                    <CommonInput
                      placeholder="Min.price"
                      name="min_price"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={4}
                      onKeyPress={(e) => {
                      if (e.target.value.length === 4) {
                        e.preventDefault()
                      }
                    }}
                      type="number"
                      onChange={onChange}
                      value={inputFields.min_price}
                    />
                  </div>
                  <div>
                    <label>Maximum Price</label>
                    <CommonInput
                      placeholder="Max.price"
                      name="max_price"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={4}
                      onKeyPress={(e) => {
                      if (e.target.value.length === 4) {
                        e.preventDefault()
                      }
                    }}
                      onChange={onChange}
                      value={inputFields.max_price}
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>Booking/Guestlist Link</label>
                    <CommonInput
                      placeholder="€ 00.00"
                      name="link"
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <label>Price</label>
                    <CommonInput
                      placeholder="€ 00.00"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="">
                  <div>
                    <label>Description</label>
                    <textarea
                      placeholder="Briefly describe about your hotel"
                      name="description"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.description)}
                    />
                  </div>
                </div>
                <div className="hotel_image_upload_section">
                  <div className="hotel_upload_div mt-0">
                    <div className="input-image-show gap-3">
                      {renderImg}
                      {inputFields.images.length < 4 &&
                      <div className="input-img-label">
                        <CommonImageUpload
                          handleImageChange={(file) => onUploadImage(
                            file,
                            'images',
                            inputFields.images.length
                          )}
                          buttonText="Add Image"
                          selectedImg=""
                          defaultImg={plusgredient}
                          className="room_add_img"
                        />
                      </div>}
                    </div>
                  </div>
                </div>
                <div className="local_button_row">
                  <CommonButton
                    label="Cancel"
                    onClick={handleCancels}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Next"
                    type="button"
                    isPrimary
                    onClick={nextClick}
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isRoomFeature && (
          <div className="add-item-model">
            <div className="add-item-content local_type_modal">
              <div className="type_close mb-2">
                <div className="d-flex align-items-center col-gap-10">
                  ADD EVENT
                </div>
                <button type="button" onClick={handleCancels}>
                  <img src={Remove} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0">
                <div className="event_speaker_dropdown mb-4">
                  <div className="event_speaker_drop">
                    <label>Speaker</label>
                    <Dropdown
                      customClass="local_event_speaker_dropdown"
                      title="Select from options"
                      name="speaker_id"
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          speaker_id: d.id
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="speaker_info">
                  {SpeakerInfo.map((location, index) => (
                    <div className="event_speaker" key={index}>
                      <div className="speaker_img">
                        <img src={location.image} alt={location.name} />
                      </div>
                      <div className="event_speaker_name">
                        <h1>{location.name}</h1>
                        <p>{location.description}</p>
                      </div>
                      <div className="event_speaker_remove">
                        <CommonButton
                          label="Remove"
                          type="button"
                          isPrimary={false}
                          context="Profile"
                          className="event_speaker_btn"
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="local_button_row">
                  <CommonButton
                    label="CANCEL"
                    onClick={handleCancels}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Save"
                    type="button"
                    isPrimary
                    onClick={addEventSave}
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {isAddRoom && (
          <div className="add-item-model">
            <div className="add-item-content add_room_modal">
              <div className="type_close mb-2">
                <div className="d-flex align-items-center col-gap-10">
                  Managing Stress & Work-Life Balance 2025
                </div>
                <button type="button" onClick={handleCancels}>
                  <img src={Remove} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0">
                <div className="room_image">
                  <img src={roomImage} alt="roomImage" />
                </div>
                <div className="edit_description ">
                  <p>
                    Lorem ipsum dolor sit amet, lorem consectetur adipiscing
                    elit. Pretium ac praesent eget ornare mattis. Zeitalter der
                    Stadt im 17. Jahrhundert stammen.
                  </p>
                </div>
                <div className="mb-3">
                  <div>
                    <label>Speakers</label>
                    <div className="mb-3">
                      <div className="d-flex row">
                        <div className="col">
                          <div className="podcast-profile-img">
                            <img src={Avatar1} alt="" />
                            <div className="podcast-profile-detail">
                              <span>Marathon Training</span>
                              <p className="designation">Manager Operations</p>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="podcast-profile-img">
                            <img src={Avatar2} alt="" />

                            <div className="podcast-profile-detail">
                              <span>Marathon Training</span>
                              <p className="designation">Booking Manger</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div />
                    </div>
                  </div>
                </div>
                <div className="input-grid detail-grid">
                  <div>
                    <label>Dates</label>
                    <p>09 - 13 Sep 2025</p>
                  </div>
                  <div>
                    <label>Price</label>
                    <p>€6.25</p>
                  </div>
                </div>
                <div className="input-grid detail-grid">
                  <div>
                    <label>Timings</label>
                    <p>09:00 AM-06:00 PM (expected)</p>
                  </div>
                  <div>
                    <label>Industry</label>
                    <p>Arts & Crafts</p>
                  </div>
                </div>
                <div className="local_button_row">
                  <CommonButton
                    label="CANCEL"
                    onClick={handleCancels}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="EDIT"
                    type="button"
                    isPrimary
                    onClick={eventEditSave}
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
export default Event
