/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useMemo, useState } from 'react'
import '../HotelComponent/RoomAdd.css'
import './Menu.css'
import { Table, Button, Image } from 'antd'
import Input from 'antd/es/input/Input'
import { toast } from 'react-toastify'
import _ from 'lodash'
import PlusIco from '../../../images/PlusIco.svg'
import Dropdown from '../../Dropdown/Dropdown'
import Remove from '../../../images/close-black.svg'
// import UploadImg from '../../../images/upload.svg'
// import roomImage from '../../../images/roomimg.svg'
import MenuBook from '../../../images/menu_book.svg'
// import MenuFood from '../../../images/menu_Food.svg'
// import MenuFood1 from '../../../images/menu_Food1.svg'
import Edit from '../../../images/edit_icon.svg'
import Delete from '../../../images/delete_icon.svg'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { capitalizeFirstLetter } from '../../../helpers/Utility'
import {
  cuisinesList,
  imageUploadLocal,
  menuAdd,
  menuDelete,
  menuEdit,
  particularMenuDetails,
  RestaurantMenuGet,
  s3ImagePathLocal,
  typeofMealList
} from '../../../services/Service'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import plusimg from '../../../images/plusimg.svg'
import Loader from '../../Loader/Loader'
import plusgredient from '../../../images/plus-rounded.svg'
import Pagination from '../../Pagination/Pagination'
import ConfirmationModal from '../../ConfirmationModal/ConfirmationModal'

const Menu = ({ isRestaurantId }) => {
  // const DiscountList = [
  //   {
  //     Img: MenuFood,
  //     Name: 'Karaage Sticky Chicken',
  //     Cuisine: 'British',
  //     Meal: 'Lunch',
  //     Price: '€6.25'
  //   },
  //   {
  //     Img: MenuFood1,
  //     Name: 'Famous Butterscotch Pudding',
  //     Cuisine: 'Grill',
  //     Meal: 'Dinner',
  //     Price: '€10.80'
  //   },
  //   {
  //     Img: MenuFood1,
  //     Name: 'Momos',
  //     Cuisine: 'Nepal',
  //     Meal: 'BreakFast',
  //     Price: '€25'
  //   },
  //   {
  //     Img: MenuFood1,
  //     Name: 'Grill Sandwich ',
  //     Cuisine: 'italy',
  //     Meal: 'Snacks',
  //     Price: '€5.23'
  //   }
  // ]

  const [isAddRoom, setIsAddRoom] = useState(false)
  const [isRoomFeature, setIsRoomFeature] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [inputFields, setInputFields] = useState({
    name: '',
    cuisine_id: '',
    meal_type_id: '',
    price: '',
    description: '',
    images: [],
    restaurant_id: isRestaurantId,
    menu_id: ''
  })

  const clearValue = () => {
    setInputFields({
      ...inputFields,
      name: '',
      cuisine_id: '',
      meal_type_id: '',
      description: '',
      price: '',
      images: []
    })
  }

  const [isMealType, setIsMealType] = useState([])
  const [isCuisinesType, setIsCuisinesType] = useState([])
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [delMenuId, setDelMenuId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [menuListData, setMenuListData] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const [particularMenu, setParticularMenu] = useState([])

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const handleCancels = () => {
    setIsAddRoom(false)
    // setIsRoomFeature(false)
    clearValue()
  }

  const handleCancel = () => {
    setIsRoomFeature(false)
  }

  const ShowConfirm = (record) => {
    setDelMenuId(record.menu_id)
    setShowConfirmModel(true)
  }
  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }

  const showMenu = (record) => {
    setIsRoomFeature(true)
    particularMenuData(record.menu_id)
  }

  const menuEdits = (record) => {
    if (Object.keys(record).length > 0) {
      setIsEdit(true)
      setInputFields({
        menu_id: record.menu_id,
        restaurant_id: record.restaurant_id,
        name: record.name,
        price: record.price,
        cuisine_id: record.cuisine_id,
        meal_type_id: record.meal_type_id,
        description: record.description,
        images: record.images
      })
    }
    setInitialValues({
      menu_id: record.menu_id,
      restaurant_id: record.restaurant_id,
      name: record.name,
      price: record.price,
      cuisine_id: record.cuisine_id,
      meal_type_id: record.meal_type_id,
      description: record.description,
      images: record.images
    })
    setIsAddRoom(true)
  }

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase())
  }

  const columns = [
    {
      title: 'ITEM NAME & IMAGE',
      dataIndex: 'Name',
      key: 'Name',
      render: (text, record) => (
        <div className="image_name">
          <Image
            width={100}
            src={s3ImagePathLocal + record.images[0]}
            alt="img"
            style={{ marginRight: 10 }}
          />
          <span>
            {record.name.length > 15
              ? `${record.name.substring(0, 15)}...`
              : record.name}
          </span>
        </div>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
      onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
      className: 'custom-column'
    },
    {
      title: 'MEAL',
      dataIndex: 'meal_type',
      key: 'meal_type',
      sorter: (a, b) => a.meal_type.localeCompare(b.meal_type)
    },
    {
      title: 'CUISINE',
      dataIndex: 'cuisine',
      key: 'cuisine',
      sorter: (a, b) => a.cuisine.localeCompare(b.cuisine)
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      key: 'price',
      sorter: (a, b) => parseFloat(a.price.slice(1)) - parseFloat(b.price.slice(1))
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div className="image-buttons-discounts">
          <Button
            icon={<img src={Edit} alt="Edit" />}
            onClick={(e) => {
              e.stopPropagation()
              menuEdits(record)
            }}
          />
          <Button
            icon={<img src={Delete} alt="Delete" />}
            onClick={(e) => {
              e.stopPropagation()
              ShowConfirm(record)
            }}
          />
        </div>
      )
    }
  ]

  const filteredData = menuListData.filter((item) => item.name.toLowerCase().includes(searchText))

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'restaurant')

        const uploadResponse = await imageUploadLocal(coverFormData)

        if (!uploadResponse.data.success) {
          throw new Error(
            uploadResponse.data.message || `${fieldName} upload failed`
          )
        }

        const decryptedImageData = dataDecrypt(uploadResponse.data.data)

        setInputFields((prev) => {
          const prevData = prev[fieldName]
          if (
            (index !== undefined || index !== null) &&
            Array.isArray(prevData)
          ) {
            prevData[index] = decryptedImageData
          }
          return {
            ...prev,
            [fieldName]: prevData
          }
        })
      }

      reader.readAsDataURL(file)
    }
  }

  const openAddRoom = () => {
    setIsAddRoom(true)
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImagePathLocal + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  const typeofMealListData = () => {
    typeofMealList({}).then((res) => {
      if (res.data.success) {
        setIsMealType(dataDecrypt(res.data.data))
      }
    })
  }

  const cuisinesListData = () => {
    cuisinesList({}).then((res) => {
      if (res.data.success) {
        setIsCuisinesType(dataDecrypt(res.data.data))
      }
    })
  }

  const RestaurantMenuGetData = (currentPage) => {
    const payload = {
      restaurant_id: isRestaurantId
    }
    RestaurantMenuGet(payload, currentPage).then((res) => {
      if (res.data.success) {
        setMenuListData(dataDecrypt(res.data.data).data)
        setTotalPages(dataDecrypt(res.data.data).last_page)
        setCurrentPage(dataDecrypt(res.data.data).current_page)
      }
    })
  }

  const menuAddApiCall = () => {
    const payload = {
      menu_id: inputFields.menu_id,
      name: inputFields.name,
      cuisine_id: inputFields.cuisine_id,
      meal_type_id: inputFields.meal_type_id,
      images: inputFields.images,
      price: inputFields.price,
      description: inputFields.description,
      restaurant_id: isRestaurantId
    }
    if (isEdit) {
      menuEdit(payload).then((res) => {
        if (res.data.success) {
          setIsAddRoom(false)
          toast.success(res?.data?.message)
          setIsEdit(false)
          RestaurantMenuGetData(currentPage)
        } else {
          toast.error(res?.data?.message)
          setIsEdit(false)
        }
      })
    } else {
      menuAdd(payload).then((res) => {
        if (res.data.success) {
          toast.success(res?.data?.message)
          setIsAddRoom(false)
          clearValue()
          RestaurantMenuGetData(currentPage)
        } else {
          toast.error(res?.data?.message)
        }
      })
    }
  }

  const deleteMenu = () => {
    const data = {
      menu_id: delMenuId
    }
    setLoading(true)
    menuDelete(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        setLoading(false)
        RestaurantMenuGetData(currentPage)
        toast.success(res?.data?.message)
      }
    })
  }

  const particularMenuData = (menu_id) => {
    const payload = {
      restaurant_id: isRestaurantId,
      menu_id
    }
    particularMenuDetails(payload).then((res) => {
      if (res.data.success) {
        setParticularMenu(dataDecrypt(res.data.data))
      }
    })
  }

  const handlePageChange = (pageNumber) => {
    RestaurantMenuGetData(pageNumber)
    setCurrentPage(pageNumber)
  }

  const validateForm = () => {
    const { name, cuisine_id, meal_type_id, price, images, description } =
      inputFields
    if (name && cuisine_id && price && images && meal_type_id && description) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const getMealName = (meal_type_id) => {
    const categoryObj = isMealType.find(
      (cat) => cat.meal_type_id === meal_type_id
    )
    return categoryObj ? categoryObj.type : 'Select Meal'
  }
  const getCuisineName = (cuisine_id) => {
    const categoryObj = isCuisinesType.find(
      (cat) => cat.cuisine_id === cuisine_id
    )
    return categoryObj ? categoryObj.name : 'Select Cuisine'
  }

  useEffect(() => {
    typeofMealListData()
    cuisinesListData()
    RestaurantMenuGetData(currentPage)
  }, [])

  useEffect(() => {
    if (!isEdit) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEdit) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEdit])

  return (
    <div className="content-manager-Statistics">
      <div className="statistic-chart-container position-relative">
        {menuListData.length === 0 ? (
          <div className="add_room_text">
            <img className="d-block" src={MenuBook} alt="bedroom" />
            <h3>MENU NOT ADDED</h3>
            <p>You have not added any menu yet</p>
            <div className="add-video-rows" onClick={openAddRoom}>
              <div className="add_new_content">
                <img src={PlusIco} alt="" />
                Add Items
              </div>
            </div>
          </div>
        ) : (
          <div className="local-room-main">
            <h3 className="d-flex align-items-center justify-content-between">
              MENU
              <button
                type="button"
                className="primary-btn"
                onClick={openAddRoom}
              >
                <img src={PlusIco} alt="add" />
                Add Items
              </button>
            </h3>
            <Input
              placeholder="Search by Item Name"
              onChange={handleSearch}
              style={{ width: 200, marginBottom: '1rem' }}
            />
            <div className="discount_list">
              <Table
                columns={columns}
                dataSource={filteredData}
                pagination={false}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      showMenu(record)
                    }
                  }
                }}
              />
            </div>
            <ConfirmationModal
              isVisible={ShowConfirmModel}
              onConfirm={deleteMenu}
              onCancel={handleDeleteCancel}
              message="Do you want to delete this?"
              isLoading={loading}
            />
            {loading && <Loader />}
            {totalPages > 1 && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </div>
        )}
      </div>

      {isAddRoom && (
        <div className="add-item-model">
          <div className="add-item-content add_room_modal local_type_modal">
            <div className="type_close mb-2">
              <div className="d-flex align-items-center col-gap-10">
                Add Item
              </div>
              <button type="button" onClick={handleCancels}>
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0">
              <div className="input-grid">
                <div>
                  <label>Item Name</label>
                  <CommonInput
                    placeholder="Enter room name / Number"
                    name="name"
                    onChange={handleChange}
                    value={capitalizeFirstLetter(inputFields.name)}
                  />
                </div>
                <div>
                  <label>Meal</label>
                  <Dropdown
                    title={getMealName(inputFields.meal_type_id)}
                    name="meal_type_id"
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        meal_type_id: d.id
                      })
                    }}
                    Data={isMealType.map((d) => ({
                      title: d.type,
                      id: d.meal_type_id
                    }))}
                    customClass="local_menu_dropdown"
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>Cuisine</label>
                  <Dropdown
                    customClass="local_menu_dropdown"
                    title={getCuisineName(inputFields.cuisine_id)}
                    name="cuisine_id"
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        cuisine_id: d.id
                      })
                    }}
                    Data={isCuisinesType.map((d) => ({
                      title: d.name,
                      id: d.cuisine_id
                    }))}
                  />
                </div>
                <div>
                  <label>Price</label>
                  <CommonInput
                    placeholder="€ 00.00 / Night"
                    name="price"
                    type="number"
                    onChange={handleChange}
                    pattern="/^-?\d+\.?\d*$/"
                    maxLength={4}
                    onKeyPress={(e) => {
                      if (e.target.value.length === 4) {
                        e.preventDefault()
                      }
                    }}
                    value={inputFields.price}
                  />
                </div>
              </div>
              <div className="pt-2">
                <div>
                  <label>Description</label>
                  <CommonInput
                    placeholder="Briefly describe about your hotel"
                    name="description"
                    onChange={handleChange}
                    isTextArea
                    maxLength={250}
                    value={capitalizeFirstLetter(inputFields.description)}
                  />
                </div>
              </div>
              <div className="hotel_image_upload_section">
                <div className="hotel_upload_div mt-0">
                  <div className="input-image-show gap-3">
                    {renderImg}
                    {inputFields.images.length < 4 && (
                      <div className="input-img-label">
                        <CommonImageUpload
                          handleImageChange={(file) => onUploadImage(
                              file,
                              'images',
                              inputFields.images.length
                            )}
                          buttonText="Add Image"
                          selectedImg=""
                          defaultImg={plusgredient}
                          className="room_add_img"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="local_button_row">
                <CommonButton
                  label="Cancel"
                  onClick={handleCancels}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Save"
                  type="button"
                  isPrimary
                  onClick={menuAddApiCall}
                  context="Profile"
                  disabled={!isFormComplete}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isRoomFeature && (
        <div className="add-item-model">
          {particularMenu.map((data, index) => (
            <div className="add-item-content add_room_modal" key={index}>
              <div className="type_close mb-2">
                <div className="d-flex align-items-center col-gap-10">
                  {data.name}
                </div>
                <button type="button" onClick={handleCancel}>
                  <img src={Remove} alt="" />
                </button>
              </div>
              <div className="item-details-input pt-0">
                <div className="room_image menu_image_show">
                  {data.images.map((d, i) => {
                    return (
                      <img
                        className="menu_image"
                        src={s3ImagePathLocal + d}
                        alt="roomImage"
                        key={i}
                      />
                    )
                  })}
                </div>
                <div className="edit_description ">
                  <p>{data.description}</p>
                </div>
                <div className="mb-3">
                  <div className="deal-label">
                    <label>Deal</label>
                    <p>Flat 20% OFF</p>
                  </div>
                </div>
                <div className="input-grid detail-grid">
                  <div>
                    <label>Meal</label>
                    <p>{data.meal_type}</p>
                  </div>
                  <div>
                    <label>Cuisine</label>
                    <p>{data.cuisine}</p>
                  </div>
                </div>
                <div className="input-grid detail-grid">
                  <div>
                    <label>Price</label>
                    <p>
                      €&nbsp;
                      {data.price}
                    </p>
                  </div>
                </div>
                <div className="local_button_row">
                  <CommonButton
                    label="CANCEL"
                    onClick={handleCancel}
                    isPrimary={false}
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
export default Menu
