/* eslint-disable no-nested-ternary */
/* eslint-disable no-lone-blocks */
/* eslint-disable max-len */
// /* eslint-disable import/no-duplicates */
// import React, { useState } from 'react'
// import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
// import ReactDatePicker from 'react-datepicker'
// import { Link } from 'react-router-dom'
// import { Swiper, SwiperSlide } from 'swiper/react'
// import { Navigation, Pagination } from 'swiper/modules'
// import OrderChart from './chart'
// import CustomerChart from './chart'
// import ProductChart from './chart'
// import RatingStar from '../../images/rating-start.svg'
// import Location from '../../images/Locations.svg'
// import threedot from '../../images/three-dot.svg'

// const Hotels = () => {
//   const items = [
//     {
//       cover: './images/hotels/3.jpg',
//       name: 'Hotel Name',
//       offerPrice: '€30',
//       location: '3517 W. Gray St. Utica, ',
//       rating: '4.9'
//     },
//     {
//       cover: './images/hotels/1.jpg',
//       name: 'Hotel Name',
//       offerPrice: '€30',
//       location: '3517 W. Gray St. Utica, ',
//       rating: '4.9'
//     },
//     {
//       cover: './images/hotels/2.jpg',
//       name: 'Hotel Name',
//       offerPrice: '€30',
//       location: '3517 W. Gray St. Utica, ',
//       rating: '4.9'
//     },
//     {
//       cover: './images/hotels/3.jpg',
//       name: 'Hotel Name',
//       offerPrice: '€30',
//       location: '3517 W. Gray St. Utica, ',
//       rating: '4.9'
//     }
//   ]
//   const itemsSlide = [
//     {
//       img: './images/hotels/2.jpg'
//     },
//     {
//       img: './images/hotels/3.jpg'
//     },
//     {
//       img: './images/hotels/6.jpg'
//     },
//     {
//       img: './images/hotels/7.jpg'
//     }
//   ]
//   const itemsRooms = [
//     {
//       img: './images/rooms/5.jpg',
//       name: 'Bedroom 3',
//       detail: '1 sofa bed'
//     },
//     {
//       img: './images/rooms/5.jpg',
//       name: 'Bedroom 3',
//       detail: '1 sofa bed'
//     }
//   ]
//   const booking = [
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Pending'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     },
//     {
//       profile: './images/task-profile1.png',
//       name: 'Jacob Jones',
//       guest: '3',
//       date: '9/23/16',
//       status: 'Paid'
//     }
//   ]
//   const travel = [
//     {
//       profile: './images/hotels/1.jpg',
//       period: '10 march - 18 march',
//       place: 'Kamula Villa',
//       name: 'Ankit Muller',
//       text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Faucibus vestibulum sagittis  sit amet consectet.'
//     },
//     {
//       profile: './images/hotels/3.jpg',
//       period: '10 march - 18 march',
//       place: 'Kamula Villa',
//       name: 'Ankit Muller',
//       text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Faucibus vestibulum sagittis  sit amet consectet.'
//     },
//     {
//       profile: './images/hotels/1.jpg',
//       period: '10 march - 18 march',
//       place: 'Kamula Villa',
//       name: 'Ankit Muller',
//       text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Faucibus vestibulum sagittis  sit amet consectet.'
//     },
//     {
//       profile: './images/hotels/1.jpg',
//       period: '10 march - 18 march',
//       place: 'Kamula Villa',
//       name: 'Ankit Muller',
//       text: 'Lorem ipsum dolor sit amet, consectet adipiscing elit. Faucibus vestibulum sagittis  sit amet consectet.'
//     }
//   ]
//   const [startDate, setStartDate] = useState(new Date())

//   const generateStars = (rating) => {
//     const totalStars = 5
//     const filledStars = Math.min(Math.floor(parseFloat(rating)), totalStars)
//     const stars = []
//     for (let i = 0; i < filledStars; i++) {
//       stars.push(<img alt="" key={i} src="./images/star.svg" />)
//     }
//     for (let i = filledStars; i < totalStars; i++) {
//       stars.push(<img alt="" key={i} src="./images/star-blank.svg" />)
//     }
//     return stars
//   }

//   const [visibleItems1, setVisibleItems1] = useState(6)
//   const [visibleItems2, setVisibleItems2] = useState(3)
//   const [visibleItems3, setVisibleItems3] = useState(1)

//   const showMoreItems1 = () => {
//     setVisibleItems1((prevVisibleItems) => prevVisibleItems + 3)
//   }
//   const showMoreItems2 = () => {
//     setVisibleItems2((prevVisibleItems) => prevVisibleItems + 3)
//   }
//   const showMoreItems3 = () => {
//     setVisibleItems3((prevVisibleItems) => prevVisibleItems + 3)
//   }

//   return (
//     <div className="content-manager">
//       <div className="content-manager-Statistics">
//         <div className="content-manager-Statistics-chart">
//           <div className="statistic-chart-container position-relative">
//             <h4>Local Overview</h4>
//             <div>
//               <Tabs>
//                 <div>
//                   <TabList>
//                     <Tab>Hotels</Tab>
//                     <Tab>Restaurants</Tab>
//                     <Tab>Gym</Tab>
//                   </TabList>
//                   <div className="drop-and-date top-40">
//                     <ReactDatePicker
//                       selected={startDate}
//                       onChange={(date) => setStartDate(date)}
//                       showYearPicker
//                       dateFormat="yyyy"
//                     />
//                   </div>
//                 </div>

//                 <TabPanel>
//                   <OrderChart />
//                 </TabPanel>
//                 <TabPanel>
//                   <ProductChart />
//                 </TabPanel>
//                 <TabPanel>
//                   <CustomerChart />
//                 </TabPanel>
//               </Tabs>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className="offers--hotel common-card-body">
//         <div className="services-container recent-video-component ">
//           <h3 className="m-0 d-flex align-items-center justify-content-between">
//             Hotels
//             {' '}
//             <Link>view all</Link>
//           </h3>
//         </div>
//         <div className="hotel-offer--grid grid-4 mt-30">
//           {items.map((item) => (
//             <div className="hotel-offer-grid--item" key={item.name}>
//               <div
//                 className="hotel-offer--cover height-184"
//                 style={{
//                   background: `url(${item.cover})`,
//                   backgroundRepeat: 'no-repeat',
//                   backgroundSize: 'cover',
//                   backgroundPosition: 'center'
//                 }}
//               >
//                 <div className="rating">
//                   <img alt="" src={RatingStar} />
//                   <span>{item.rating}</span>
//                 </div>
//               </div>
//               <div className="hotel-offer-grid--detail">
//                 <div className="d-flex align-items-center justify-content-between">
//                   <h6>{item.name}</h6>
//                   <p>
//                     {item.offerPrice}
//                     <span> / Night</span>
//                   </p>
//                 </div>
//                 <div className="d-flex align-items-center justify-content-between">
//                   <span className="location d-flex align-items-center col-gap-10">
//                     <img alt="" src={Location} />
//                     <span>{item.location}</span>
//                   </span>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       <div className="d-flex justify-content-between mt-30">
//         <div className="common-card-body local-left-sec d-flex col-gap-20">
//           <div className="common-shadow w-50 d-flex flex-column justify-content-between">
//             <div>
//               <div className="services-container recent-video-component mb-30">
//                 <h3 className="m-0 d-flex align-items-center justify-content-between">
//                   Booking List
//                   {' '}
//                   <Link className="border-0">
//                     <img
//                       alt=""
//                       src={threedot}
//                       style={{ transform: 'rotate(90deg)' }}
//                     />
//                   </Link>
//                 </h3>
//               </div>
//               <div className="booking--list">
//                 {booking.slice(0, visibleItems1).map((item) => (
//                   <div className="booking-list--item" key={item.status}>
//                     <div className="booking-list--profile">
//                       <img alt="" src={item.profile} />
//                       <ul>
//                         <li className="list-profile--name">{item.name}</li>
//                         <li className="number-of-guest">
//                           {item.guest}
//                           {' '}
//                           guests
//                         </li>
//                       </ul>
//                     </div>
//                     <div className="text-end">
//                       <span className="booking-list--date">{item.date}</span>
//                       <p className="booking--status" id={item.status}>
//                         {item.status}
//                       </p>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//             {booking.length > visibleItems1 && (
//               <div className="hotel-btn mt-40">
//                 <button type="button" className="gredient-txt" onClick={showMoreItems1}>
//                   <span>View All</span>
//                 </button>
//               </div>
//             )}
//           </div>
//           <div className="common-shadow w-50 d-flex flex-column justify-content-between">
//             <div>
//               <div className="services-container recent-video-component mb-30">
//                 <h3 className="m-0 d-flex align-items-center justify-content-between">
//                   Previous Travel
//                   {' '}
//                   <Link className="border-0">
//                     <img
//                       alt=""
//                       src={threedot}
//                       style={{ transform: 'rotate(90deg)' }}
//                     />
//                   </Link>
//                 </h3>
//               </div>
//               <div className="travel--detail">
//                 {travel.slice(0, visibleItems2).map((item) => (
//                   <div className="travel-detail--item" key={item}>
//                     <div className="travel--profile">
//                       <img alt="" src={item.profile} />
//                       <ul>
//                         <li className="travel-place">{item.place}</li>
//                         <li className="travel-period">{item.period}</li>
//                       </ul>
//                     </div>
//                     <div className="about-travel">
//                       <span>{item.text}</span>
//                       <p>
//                         by
//                         {item.name}
//                       </p>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//             {travel.length > visibleItems2 && (
//               <div className="hotel-btn">
//                 <button type="button" className="gredient-txt" onClick={showMoreItems2}>
//                   <span>View All</span>
//                 </button>
//               </div>
//             )}
//           </div>
//         </div>
//         <div className="common-card-body local-right-sec recent-video-component">
//           <div className="booking-list--profile">
//             <img alt="" src="./images/task-profile1.png" />
//             <ul>
//               <li className="list-profile--name">Jacob Jones</li>
//               <li className="number-of-guest">@jacob_jones</li>
//             </ul>
//           </div>
//           <div className="devider mb-3- mt-30" />
//           <div className="hotel-swiper-slider">
//             <div className="d-flex justify-content-between align-items-center">
//               <h3 className="m-0">Shikara Hotel</h3>
//               <div className="rating-star-container">
//                 {generateStars(4.5)}
//                 {' '}
//                 4.5 rating
//               </div>
//             </div>
//             <div className="slider-for-reviews slider-local-- position-relative slider-for-local">
//               <Swiper
//                 slidesPerView={1}
//                 navigation
//                 pagination={false}
//                 modules={[Navigation, Pagination]}
//                 className="mySwiper-story review-slider position-static"
//               >
//                 {itemsSlide.map((item) => (
//                   <SwiperSlide key={item}>
//                     <div>
//                       <img alt="" src={item.img} />
//                     </div>
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//             </div>
//             <div className="local-tabs">
//               <Tabs>
//                 <TabList>
//                   <Tab>Overview</Tab>
//                   <Tab>Rooms</Tab>
//                   <Tab>Reviews</Tab>
//                 </TabList>

//                 <TabPanel>
//                   <div className="local-component">
//                     <p>
//                       Lorem ipsum dolor sit amet, consecte adipiscing elit.
//                       Maecenas purus varius ullamcorper consequat, ullamcorper a
//                       nulla enim. Fusce quis turpis pretium amet. Iaculis morbi
//                       diam nunc vestibulum, nec sed. Ac accumsan nunc augue amet
//                       odio et. Adipiscing vestibulum scelerisque integer ut diam
//                       cras. Viverra proin ut arcu aliquam at odio orci, cras.
//                       Tristique aliquam.
//                     </p>
//                     <h3 className="m-0 mt-30">Location</h3>
//                     <div className="map mb-4 mt-20">
//                       <iframe
//                         src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d118147.82113802907!2d70.7387235299145!3d22.273466080337368!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959c98ac71cdf0f%3A0x76dd15cfbe93ad3b!2sRajkot%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1706178917842!5m2!1sen!2sin"
//                         width="100%"
//                         height="197"
//                         allowfullscreen=""
//                         loading="lazy"
//                         referrerPolicy="no-referrer-when-downgrade"
//                         style={{ borderRadius: '15px' }}
//                         title="location"
//                       />
//                     </div>
//                   </div>
//                 </TabPanel>
//                 <TabPanel>
//                   <div className="local-component">
//                     <h3 className="m-0 gredient-txt">Rooms</h3>
//                     {itemsRooms.slice(0, visibleItems3).map((item) => (
//                       <div key={item} className="mt-3">
//                         <img alt="" src={item.img} />
//                         <h3 className="m-0">{item.name}</h3>
//                         <span>{item.detail}</span>
//                       </div>
//                     ))}
//                   </div>
//                   {itemsRooms.length > visibleItems3 && (
//                     <div className="hotel-btn">
//                       <button type="button" className="gredient-txt" onClick={showMoreItems3}>
//                         <span>View All</span>
//                       </button>
//                     </div>
//                   )}
//                 </TabPanel>
//                 <TabPanel>3</TabPanel>
//               </Tabs>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
// export default Hotels

/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ReactDatePicker from 'react-datepicker'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import OrderChart from './chart'
import Dropdown from '../Dropdown/Dropdown'
// import ProfileImg from '../../images/details_profile.svg'
import Redirect from '../../images/redirect.svg'
// import Avatar1 from '../../images/Hotel_Avatar.svg'
// import Avatar2 from '../../images/Hotel_Avtar2.svg'
// import Hotel1 from '../../images/hotelview.svg'
// import Hotel2 from '../../images/hotelView1.svg'
// import Hotel3 from '../../images/hotelView2.svg'
// import Hotel4 from '../../images/hotelView3.svg'
import UpPath from '../../images/up-path.svg'
import DownPath from '../../images/down-trend.svg'
import impression from '../../images/visitoeeye.svg'
import Clicks from '../../images/clicktarget.svg'
import Requests from '../../images/reverseicon.svg'
import StatusImg from '../../images/layericon.svg'
import RoomAdd from './HotelComponent/RoomAdd'
import Discount from './HotelComponent/Discount'
import Remove from '../../images/close-black.svg'
// import FamilyTrends from '../../images/familytrends-logo.svg'
// import Filiyo from '../../images/filiyo-logo.svg'
// import Fyredates from '../../images/fyerdates-logo.svg'
// import Athelete from '../../images/goathlete-logo.svg'
// import Scalo from '../../images/scalco-logo.svg'
// import Petzino from '../../images/petzino-logo.svg'
// import Spacure from '../../images/spacure-logo.svg'
// import LuxuryEleven from '../../images/luxuryelevenlogo.svg'
// import CreatorTelents from '../../images/creatortelents-logo.svg'
// import TheTestClub from '../../images/thetestclub-logo.svg'
// import Testiee from '../../images/testee-logo.svg'
// import AZFit from '../../images/azfit-logo.svg'
import HotelPopup from './addPopup/hotelPopup'
import CommonButton from '../ButtonComponent/ButtonCommon'
import RatingStar from '../../images/rating-start.svg'
import Location from '../../images/Locations.svg'
import {
  allHotel,
  getApplications,
  hotelContactAdd,
  hotelContactDelete,
  hotelContactEdit,
  hotelDelete,
  // hotelLike,
  hotelLocationList,
  HotelPublish,
  imageUploadLocal,
  particularHotelDetails,
  s3ImagePath,
  s3ImagePathLocal,
  typeofRoomList
} from '../../services/Service'
import { compressImage, dataDecrypt } from '../../services/http-services'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import Loader from '../Loader/Loader'
// import Likes from '../../images/like-fill.svg'
// import UnLikes from '../../images/like-empty.svg'
import CommonInput from '../InputComponet/CommonInput'
import { capitalizeFirstLetter } from '../../helpers/Utility'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import local_add_img from '../../images/add_a_photo.svg'
import Plus from '../../images/plus-rounded.svg'
import Pagination from '../Pagination/Pagination'

const Hotels = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const [startDate, setStartDate] = useState(new Date())

  const [isPlatFormModal, setIsPlatFormModal] = useState(false)
  const [isHotel, setIsHotel] = useState(false)
  const [isHotelLocationList, setIsHotelLocationList] = useState([])
  const [isHotelData, setIsHotelData] = useState([])
  const [isHotelId, setIsHotelId] = useState()
  const [inputFields, setInputFields] = useState({
    city_id: '',
    contact_name: '',
    contact_designation: '',
    contact_phone: '',
    contact_profile_pic: '',
    contact_id: '',
    hotel_id: isHotelId
  })
  const [isParticularHotelData, setIsParticularHotelData] = useState([])
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [loading, setLoading] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [ShowConfirmHotelModel, setShowConfirmHotelModel] = useState(false)
  const [delHotelId, setDelHotelId] = useState()
  const [editData, setEditData] = useState({})
  const [isRoomType, setIsRoomType] = useState([])
  const [appData, setAppData] = useState([])
  const [particularHotelDeatils, setParticularHotelDeatils] = useState(false)
  const [personDetails, setPersonDetails] = useState(false)
  const [contactIndex, setContactIndex] = useState(null)
  const [delContactId, setDelContactId] = useState()
  const [isEdit, setIsEdit] = useState(false)
  const [isHotelLoading, setIsHotelLoading] = useState(false)
  const [isParticularLoading, setIsParticularLoading] = useState(false)
  const [isShow, setIsShow] = useState(false)
  const [isShowHotelList, setIsShowHotelList] = useState(true)

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const onClickPublish = () => {
    setIsPlatFormModal(true)
  }
  const handleCancels = () => {
    setIsPlatFormModal(false)
  }

  const clearValues = () => {
    setIsParticularHotelData([])
  }

  const onClickEdit = (data) => {
    setIsHotel(true)
    setEditData({
      hotel_id: data.hotel_id,
      name: data.name,
      description: data.description,
      country_id: data.country_id,
      province_id: data.province_id,
      city_id: data.city_id,
      zip_code: data.zip_code,
      longitude: data.longitude,
      latitude: data.latitude,
      website: data.website,
      amenities: data.amenities,
      min_price: data.min_price,
      max_price: data.max_price,
      logo: data.logo,
      images: data.images,
      contact_name1: data?.contect_details?.[0]?.contact_name ?? '',
      contact_name2: data?.contect_details?.[1]?.contact_name ?? '',
      contact_designation1:
        data?.contect_details?.[0]?.contact_designation ?? '',
      contact_designation2:
        data?.contect_details?.[1]?.contact_designation ?? '',
      contact_phone1: data?.contect_details?.[0]?.contact_phone ?? null,
      contact_phone2: data?.contect_details?.[1]?.contact_phone ?? null,
      contact_profile_pic: [
        data?.contect_details?.[0]?.contact_profile_pic,
        data?.contect_details?.[1]?.contact_profile_pic
      ]
    })
  }

  const clickNewHotelAdd = () => {
    setIsHotel(true)
  }

  const handleCancel = () => {
    setIsHotel(false)
  }
  const handleDeleteCancel = () => {
    setShowConfirmModel(false)
  }

  const editOnClick = (item) => {
    particularHotelData(item.hotel_id, 'isEdit')
  }

  const onChange = (e) => {
    setInputFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onClickContactEdit = (item = {}) => {
    setPersonDetails(true)
    if (Object.keys(item).length > 0) {
      setIsEdit(true)
      setInputFields({
        hotel_id: isHotelId,
        contact_name: item.contact_name,
        contact_designation: item.contact_designation,
        contact_phone: item.contact_phone,
        contact_profile_pic: item.contact_profile_pic,
        contact_id: item.contact_id
      })
    }
  }

  const cancelContact = () => {
    setPersonDetails(false)
  }

  const ShowConfirm = (videoId) => {
    setDelHotelId(videoId)
    setShowConfirmHotelModel(true)
  }

  const ShowContactConfirm = (videoId) => {
    setDelContactId(videoId)
    setShowConfirmModel(true)
  }
  const showAllHotelList = () => {
    setIsShowHotelList(true)
    setIsShow(false)
  }

  const onUploadImage = async (file, fieldName) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'hotel')

        const uploadResponse = await imageUploadLocal(coverFormData)

        if (!uploadResponse.data.success) {
          throw new Error(
            uploadResponse.data.message || `${fieldName} upload failed`
          )
        }
        const decryptedImageData = dataDecrypt(uploadResponse.data.data)
        setInputFields((prev) => {
          return {
            ...prev,
            [fieldName]: decryptedImageData
          }
        })
      }

      reader.readAsDataURL(file)
    }
  }

  const hotelLocationListData = () => {
    const playload = {
      user_id: userData.user_id
    }
    hotelLocationList(playload).then((res) => {
      if (res.data.success) {
        setIsHotelLocationList(dataDecrypt(res.data.data))
      }
    })
  }

  const allHotelData = (id, currentPage) => {
    setIsHotelLoading(true)
    const playload = {
      city_id: id
    }
    allHotel(playload || {}, currentPage).then((res) => {
      if (res.data.success) {
        setIsHotelData(dataDecrypt(res.data.data).data)
        setIsHotelLoading(false)
        setTotalPages(dataDecrypt(res.data.data).last_page)
        setCurrentPage(dataDecrypt(res.data.data).current_page)
      } else {
        toast.error(res?.data?.message)
        setIsHotelLoading(false)
      }
    })
  }

  const particularHotelData = (id, type = null) => {
    setIsParticularLoading(true)
    setIsHotelId(id)
    const playload = {
      hotel_id: id
    }
    particularHotelDetails(playload).then((res) => {
      if (res.data.success) {
        setIsParticularHotelData(dataDecrypt(res.data.data))
        setParticularHotelDeatils(true)
        getApplicationsData()
        setIsParticularLoading(false)
        if (type === 'isEdit') {
          onClickEdit(dataDecrypt(res.data.data)[0])
        }
      }
    })
  }

  const deleteHotel = () => {
    const data = {
      hotel_id: delHotelId
    }
    setLoading(true)
    hotelDelete(data).then((res) => {
      if (res.data.success) {
        setShowConfirmHotelModel(false)
        setLoading(false)
        allHotelData(null, currentPage)
        toast.success(res?.data?.message)
      }
    })
  }

  // const hotelLikeData = (hotel_id) => {
  //   const dataId = localStorage.getItem('app_id')
  //   const parsedData = JSON.parse(dataId)
  //   const platFormData = {
  //     app_id: parsedData?.id,
  //     hotel_id
  //   }
  //   hotelLike(platFormData).then((res) => {
  //     if (res.data.success) {
  //       toast.success(res?.data?.message)
  //     }
  //   })
  // }

  const typeofRoomListData = () => {
    typeofRoomList({}).then((res) => {
      if (res.data.success) {
        setIsRoomType(dataDecrypt(res.data.data))
      }
    })
  }

  const getApplicationsData = async () => {
    getApplications().then((res) => {
      if (res.data.success) {
        setAppData(dataDecrypt(res.data.data))
      }
    })
  }

  const HotelPublishApi = async () => {
    const data = {
      hotel_id: isHotelId,
      app_id: appData.filter(({ checked }) => checked).map((e) => e.id)
    }
    HotelPublish(data).then((res) => {
      if (res.data.success) {
        setIsPlatFormModal(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const hotelContactEditApi = async () => {
    const data = {
      hotel_id: isHotelId,
      contact_name: inputFields.contact_name,
      contact_designation: inputFields.contact_designation,
      contact_phone: inputFields.contact_phone,
      contact_profile_pic: inputFields.contact_profile_pic,
      contact_id: inputFields.contact_id
    }
    if (isEdit) {
      hotelContactEdit(data).then((res) => {
        if (res.data.success) {
          setPersonDetails(false)
          particularHotelData(inputFields.hotel_id)
          toast.success(res?.data?.message)
          setIsEdit(false)
        } else {
          toast.error(res?.data?.message)
          setIsEdit(false)
        }
      })
    } else {
      hotelContactAdd(data).then((res) => {
        if (res.data.success) {
          setPersonDetails(false)
          particularHotelData(isHotelId)
          toast.success(res?.data?.message)
        } else {
          toast.error(res?.data?.message)
        }
      })
    }
  }

  const hotelContactDeleteApi = async () => {
    const data = {
      contact_id: delContactId
    }
    setLoading(true)
    hotelContactDelete(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        setLoading(false)
        particularHotelData(isHotelId)
        toast.success(res?.data?.message)
      }
    })
  }

  const getStoreLink = (website) => {
    if (!website) return undefined
    return website.startsWith('http') ? website : `http://${website}`
  }

  const handlePageChange = (pageNumber) => {
    allHotelData(null, pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    typeofRoomListData()
    hotelLocationListData()
    allHotelData(null, currentPage)
  }, [])

  return (
    <div className="content-manager">
      <div className="offers--hotel p-2 mb-3">
        <div className="location_section">
          <Dropdown
            title="London"
            name="type_id"
            onChanged={(d) => {
              setInputFields({
                ...inputFields,
                city_id: d.id
              })
              allHotelData(d.id, currentPage)
            }}
            Data={isHotelLocationList.map((d) => ({
              title: d.city_name,
              id: d.city_id
            }))}
            customClass="hotels_dropdown w-auto"
          />
          {isShow ? (
            <h1 className="hotel_text" onClick={() => showAllHotelList()}>ALL HOTEL</h1>
          ) : (
            <h1 className="hotel_text">ADD NEW LOCATION +</h1>
          )}
        </div>
      </div>

      {isShowHotelList && (
        <div className="offers--hotel common-card-body">
          <div className="hotel-offer--grid grid-4 mt-30">
            {isHotelData.length === 0 && !isHotelLoading ? (
              <div className="awards-item">
                <div className="add-profile-video-cover add-product-video-cover">
                  <button type="button" onClick={clickNewHotelAdd}>
                    <img src={Plus} alt="add library" />
                  </button>
                </div>
                <div className="product-footer">
                  <p className="add-value" />
                  <span className="add-value" />
                </div>
              </div>
            ) : isHotelLoading ? (
              <div className="awards-item">
                <div className="add-profile-video-cover add-product-video-cover" />
                <div className="product-footer">
                  <p className="add-value">
                    <Skeleton className="add-value" width={202} height={35} />
                  </p>
                  <span className="add-value">
                    <Skeleton className="add-value" width={67} height={35} />
                  </span>
                </div>
              </div>
            ) : (
              isHotelData.map((item, index) => (
                <div className="hotel-offer-grid--item" key={item.name}>
                  <div
                    className="hotel-offer--cover position-relative height-184"
                    onClick={() => {
                      setIsShow(true)
                      setIsShowHotelList(false)
                      particularHotelData(item.hotel_id)
                    }}
                    style={{
                      background: `url(${s3ImagePathLocal + item.images[0]})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  >
                    {/* <div className="video_like_bg">
                    <button
                      type="button"
                      className="video_like"
                      onClick={(e) => {
                        e.stopPropagation()
                        hotelLikeData(item.hotel_id)
                      }}
                    >
                      {item.is_like ? (
                        <img src={Likes} alt="Unlike" />
                      ) : (
                        <img src={UnLikes} alt="Like" />
                      )}
                    </button>
                  </div> */}
                    <div className="rating">
                      <img alt="" src={RatingStar} />
                      <span>4.9</span>
                    </div>
                    <div className="image-buttons z-1">
                      <label className="edit_event_pencil">
                        <button
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation()
                            editOnClick(item)
                          }}
                        />
                      </label>
                      <button
                        type="button"
                        className="p-0"
                        onClick={(e) => {
                          e.stopPropagation()
                          setUpdatingIndex(index)
                          ShowConfirm(item.hotel_id)
                        }}
                      />
                    </div>
                    <ConfirmationModal
                      isVisible={ShowConfirmHotelModel && updatingIndex === index}
                      onConfirm={deleteHotel}
                      onCancel={handleDeleteCancel}
                      message="Do you want to delete this?"
                      isLoading={loading}
                    />
                    {loading && updatingIndex === index && <Loader />}
                  </div>
                  <div className="hotel-offer-grid--detail">
                    <div className="d-flex align-items-center justify-content-between">
                      <h6>{item.name}</h6>
                      <p>
                        {item.min_priceprice}
                        <span> / Night</span>
                      </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="location d-flex align-items-center col-gap-10">
                        <img alt="" src={Location} />
                        <span>{item.address}</span>
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          )}
        </div>
      )}

      {isShow && particularHotelDeatils && (
        <div className="offers--hotel p-2 mb-3">
          {isParticularLoading ? (
            <div className="particular_skeleton">
              <div className="w-50">
                <div className="details_section_profile_header">
                  <div className="name_logo">
                    <Skeleton circle height={50} width={50} />
                    <div>
                      <Skeleton count={1} height={30} width={200} />
                      <Skeleton count={1} height={20} width={300} />
                    </div>
                  </div>
                </div>
                <div className="details_section_button_row d-flex gap-2">
                  <Skeleton count={1} height={40} width={80} />
                  <Skeleton count={1} height={40} width={80} />
                </div>

                <div className="details_section p-3">
                  <Skeleton count={4} height={20} width="100%" />
                  <Skeleton count={1} height={20} width={150} />
                </div>

                <div className="contact-details">
                  <h4>
                    <Skeleton width={100} />
                  </h4>
                  <div className="mt-3 mb-3">
                    <Skeleton count={3} height={50} width="100%" />
                  </div>
                </div>
              </div>
              <div className="w-50">
                <div className="d-flex gap-3 w-100 mt-5">
                  <div className="image-placeholder w-50">
                    <Skeleton height={150} width="100%" />
                  </div>
                  <div className="image-placeholder w-50">
                    <Skeleton height={150} width="100%" />
                  </div>
                </div>
                <div className="d-flex gap-3 w-100">
                  <div className="image-placeholder w-50">
                    <Skeleton height={150} width="100%" />
                  </div>
                  <div className="image-placeholder w-50">
                    <Skeleton height={150} width="100%" />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            isParticularHotelData.map((data, index) => (
              <div key={index}>
                <div className="details_section_profile_header">
                  <div className="name_logo">
                    <img src={s3ImagePathLocal + data.logo} alt="profile" />
                    <div>
                      <div className="d-flex gap-3 align-items-center">
                        <h1 className="mb-0 pe-2">{data.name}</h1>
                        {data.app_data.length > 0 && (
                          <div className="profile-tag tag-local position-relative">
                            <span>Published</span>
                            <div className="published-tooltip-main">
                              <div className="published-tooltip">
                                <div className="tooltip-ico" />
                                {data.app_data.map((d, i) => (
                                  <div className="published-item" key={i}>
                                    <img
                                      src={s3ImagePath + d.icon}
                                      alt="the-test-club"
                                      className="publish_icon"
                                    />
                                    <div
                                      className="unpublished-btn"
                                      type="button"
                                      onClick={HotelPublishApi}
                                    >
                                      Un Publish
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <p>{data.address}</p>
                    </div>
                  </div>
                  <div className="details_section_button_row d-flex gap-2">
                    <button
                      type="button"
                      className="local_type_cancel"
                      onClick={() => onClickEdit(data)}
                    >
                      <span>EDIT</span>
                    </button>
                    <button
                      type="button"
                      className="local_type_next position-relative"
                      onClick={onClickPublish}
                    >
                      <span className="d-flex align-items-center gap-3">
                        PUBLISH
                        <span className="unpublished-drop" type="button">
                          <div className="unpublished-drop-main">
                            <div className="uppublished-drop-btn" type="button">
                              Un Publish
                            </div>
                          </div>
                        </span>
                      </span>
                    </button>
                  </div>
                </div>
                <div className="details_section p-3">
                  <div>
                    <div className="description_link">
                      <p>{data.description}</p>
                      <div className="profile-redirect">
                        <a
                          className="gredient-txt"
                          target="_blank"
                          href={getStoreLink(data.website)}
                          rel="noreferrer"
                        >
                          <img alt="" src={Redirect} />
                          {data.website ? data.website : 'No link'}
                        </a>
                      </div>
                    </div>
                    <div className="contact-details" style={{ width: '560px' }}>
                      <h4>Contact Persons</h4>
                      <div className="mt-3 mb-3">
                        <div className="d-flex row">
                          {data.contect_details.map((item, index) => (
                            <div className="col position-relative" key={index}>
                              <div className="podcast-profile-img">
                                <img
                                  src={
                                    s3ImagePathLocal + item.contact_profile_pic
                                  }
                                  alt=""
                                />
                                <div className="podcast-profile-detail">
                                  <span>{item.contact_designation}</span>
                                  <p className="designation">
                                    {item.contact_phone}
                                  </p>
                                </div>
                              </div>
                              <div className="image-buttons z-1">
                                <label>
                                  <button
                                    type="button"
                                    onClick={(e) => {
                                      e.stopPropagation()
                                      onClickContactEdit(item)
                                    }}
                                    className="d-none"
                                  />
                                </label>
                                <button
                                  type="button"
                                  className="p-0"
                                  onClick={() => {
                                    setContactIndex(index)
                                    ShowContactConfirm(item.contact_id)
                                  }}
                                />
                              </div>
                              <ConfirmationModal
                                isVisible={
                                  ShowConfirmModel && contactIndex === index
                                }
                                onConfirm={hotelContactDeleteApi}
                                onCancel={handleDeleteCancel}
                                message="Do you want to delete this?"
                                isLoading={loading}
                              />
                              {loading && contactIndex === index && <Loader />}
                            </div>
                          ))}
                          {data.contect_details.length === 0 ||
                          data.contect_details.length === 1 ? (
                            <div className="col position-relative">
                              <div
                                className="podcast-profile-img"
                                onClick={() => onClickContactEdit({})}
                              >
                                <button
                                  type="button"
                                  className="add_contact_person"
                                  onClick={() => onClickContactEdit({})}
                                >
                                  <img
                                    className="add_image"
                                    src={Plus}
                                    alt="add library"
                                  />
                                </button>
                                <div className="podcast-profile-detail">
                                  <span>Add Contact Person</span>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div />
                      </div>
                      <div>
                        <div className="hotel-details row">
                          <div className="col">
                            <h5>CheckIn/CheckOut</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">12:00 PM - 10:30 PM</p>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Price Range</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">
                                €
                                {' '}
                                {data.min_price}
                                {' '}
                                - €
                                {' '}
                                {data.max_price}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="hotel-details row">
                          <div className="col">
                            <h5>Room Types</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">
                                {isRoomType.map((data) => data.type).join(', ')}
                              </p>
                            </div>
                          </div>
                          <div className="col">
                            <h5>Bed Options</h5>
                            <div className="podcast-profile-detail">
                              <p className="designation">
                                King, Queen, Twin beds
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="image_hotel">
                      {data.images.map((item, index) => (
                        <img
                          key={index}
                          src={s3ImagePathLocal + item}
                          alt="hotel img"
                        />
                      ))}
                    </div>
                  </div>
                </div>
                {isPlatFormModal && (
                  <div className="add-item-model">
                    <div className="add-item-content local_type_modal">
                      <div className="type_close mb-2">
                        <div className="d-flex align-items-center col-gap-10">
                          PUBLISH
                        </div>
                        <button type="button" onClick={handleCancels}>
                          <img src={Remove} alt="" />
                        </button>
                      </div>
                      <div className="amenities_name">
                        {appData.map((i, index) => (
                          <div
                            className="profile-checkbox platform-size"
                            key={index}
                          >
                            <img src={s3ImagePath + i.icon} alt="" />
                            <div className="custom-check-box">
                              <CommonInput
                                type="checkbox"
                                value={i.id}
                                checked={data.app_data.some(
                                  (app) => app.id === i.id
                                )}
                                onChange={(e) => {
                                  const newCheckboxes = [...appData]
                                  const isChecked = e.target.checked

                                  newCheckboxes[index].checked = isChecked
                                  setAppData(newCheckboxes)

                                  if (isChecked) {
                                    data.app_data.push(i)
                                  } else {
                                    data.app_data = data.app_data.filter(
                                      (app) => app.id !== i.id
                                    )
                                  }
                                }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="local_button_row">
                        <CommonButton
                          label="Cancel"
                          onClick={handleCancels}
                          isPrimary={false}
                          context="Profile"
                        />
                        <CommonButton
                          label="Next"
                          type="button"
                          isPrimary
                          onClick={HotelPublishApi}
                          context="Profile"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          )}
        </div>
      )}

      {isShow && (
        <div className="local-tabs section-local">
          <Tabs>
            <div className="list_overview">
              <TabList>
                <Tab>Overview</Tab>
                <Tab>Rooms</Tab>
                <Tab>Discounts</Tab>
              </TabList>
            </div>

            <TabPanel>
              {' '}
              <div className="local_box">
                <div className="local_manager_section impress-section">
                  <div className="local-manager-item">
                    <div className="icon_local">
                      <img src={impression} alt="visitor" />
                    </div>
                    <div className="local-manager-item-info">
                      <p>VISITORS</p>
                      <span>
                        0
                        <img src={UpPath} alt="" />
                        <span className="positive-trend">0%</span>
                      </span>
                    </div>
                  </div>
                  <div className="local-manager-item">
                    <div className="icon_local">
                      <img src={Clicks} alt="impression" />
                    </div>
                    <div className="local-manager-item-info">
                      <p>CLICKS</p>
                      <span>
                        0
                        <img src={UpPath} alt="" />
                        <span className="positive-trend">0%</span>
                      </span>
                    </div>
                  </div>
                  <div className="local-manager-item">
                    <div className="icon_local">
                      <img src={Requests} alt="Clicks" />
                    </div>
                    <div className="local-manager-item-info">
                      <p>CONVERSION</p>
                      <span>
                        0
                        <img src={UpPath} alt="" />
                        <span className="positive-trend">0%</span>
                      </span>
                    </div>
                  </div>
                  <div className="local-manager-item">
                    <div className="icon_local">
                      <img src={StatusImg} alt="request" />
                    </div>
                    <div className="local-manager-item-info">
                      <p>REVENUE</p>
                      <span>
                        0
                        <img src={DownPath} alt="" />
                        <span className="negetive-trend">0%</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-manager-Statistics p-20">
                <div className="statistic-chart-container position-relative">
                  <h4>Overview</h4>
                  <div className="drop-and-date top-0">
                    <ReactDatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      showYearPicker
                      dateFormat="yyyy"
                    />
                  </div>
                  <OrderChart />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <RoomAdd isHotelId={isHotelId} />
            </TabPanel>
            <TabPanel>
              <Discount isHotelId={isHotelId} />
            </TabPanel>
          </Tabs>
        </div>
      )}

      {personDetails && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close mb-2">
              <div className="d-flex align-items-center col-gap-10">
                Contact Person
              </div>
              <button type="button" onClick={cancelContact}>
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0">
              <h6 className="fw-bolder">Contact Person</h6>
              <div className="logo_btn">
                <div className="image_upload_section">
                  <div className="add_photos mb-4 mt-2">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic')}
                        index={0}
                        forImage="local_photo"
                        className="profile-upload"
                        selectedImg={
                          inputFields.contact_profile_pic
                            ? `${s3ImagePathLocal}${inputFields.contact_profile_pic}`
                            : ''
                        }
                        defaultImg={local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="person_detail_section">
                  <div>
                    <label>Name</label>
                    <CommonInput
                      placeholder="Name"
                      onChange={onChange}
                      name="contact_name"
                      value={capitalizeFirstLetter(inputFields.contact_name)}
                    />
                  </div>
                  <div>
                    <label>Designation</label>
                    <CommonInput
                      placeholder="Designation"
                      onChange={onChange}
                      name="contact_designation"
                      value={capitalizeFirstLetter(
                        inputFields.contact_designation
                      )}
                    />
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <CommonInput
                      placeholder="+4915234213935"
                      name="contact_phone"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={10}
                      onChange={onChange}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 10) {
                          e.preventDefault()
                        }
                      }}
                      value={inputFields.contact_phone}
                    />
                  </div>
                </div>
              </div>
              <div className="local_button_row">
                <CommonButton
                  label="Cancel"
                  onClick={cancelContact}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Next"
                  type="button"
                  isPrimary
                  onClick={hotelContactEditApi}
                  context="Profile"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isHotel && (
        <HotelPopup
          onCancel={handleCancel}
          defaultData={editData}
          apiSuccess={() => {
            allHotelData(null, currentPage)
            clearValues()
            setIsHotel(false)
          }}
          setIsShowHotelList={setIsShowHotelList}
          setIsShow={setIsShow}
        />
      )}
    </div>
  )
}
export default Hotels
