/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import Dropdown from '../../Dropdown/Dropdown'
import local_add_img from '../../../images/add_a_photo.svg'
import Remove from '../../../images/close-black.svg'
// import PersonImage from '../../../images/menimage.svg'
import Back from '../../../images/back-btn.svg'
import CommonImageUpload from '../../ImageCroper/StoreImageCroper'
import CommonInput from '../../InputComponet/CommonInput'
import CommonButton from '../../ButtonComponent/ButtonCommon'
import { capitalizeFirstLetter } from '../../../helpers/Utility'
import {
  cityList,
  countryList,
  hotelAdd,
  hotelAmenitiesList,
  hotelEdit,
  imageUploadLocal,
  provinceList,
  s3ImagePathLocal
} from '../../../services/Service'
import 'leaflet/dist/leaflet.css'
import { compressImage, dataDecrypt } from '../../../services/http-services'
import MapComponent from '../../MapComponent/MapComponent'
import Loader from '../../Loader/Loader'
import plusgredient from '../../../images/plus-rounded.svg'
import plusimg from '../../../images/plusimg.svg'

const hotelPopup = ({
  onCancel,
  defaultData = {},
  apiSuccess = () => {},
  setIsShowHotelList,
  setIsShow
}) => {
  const navigate = useNavigate()
  const [locationDetails, setLocationDetails] = useState(true)
  const [hotelDetails, setHotelDetails] = useState(false)
  const [amenitiesDetails, setAmenitiesDetails] = useState(false)
  const [personDetails, setPersonDetails] = useState(false)
  const [hotelPhotos, setHotelPhotos] = useState(false)
  const [inputFields, setInputFields] = useState({
    name: '',
    description: '',
    city_id: '',
    zip_code: '',
    longitude: '',
    latitude: '',
    website: '',
    amenities: [],
    min_price: '',
    max_price: '',
    logo: '',
    images: [],
    contact_name1: '',
    contact_name2: '',
    contact_designation1: '',
    contact_designation2: '',
    contact_phone1: '',
    contact_phone2: '',
    contact_profile_pic: [],
    hotel_id: '',
    country_id: '',
    province_id: '',
    country: '',
    city_name: '',
    province_name: ''
  })

  const [selectedAmenities, setSelectedAmenities] = useState([])
  // const [showMap, setShowMap] = useState(false)
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })
  const [draggedIndex, setDraggedIndex] = useState(null)

  // const handleAddressClick = () => {
  //   setShowMap(true)
  // }

  const handleCancelStep1 = () => {
    setLocationDetails(false)
    onCancel()
  }

  const nextClickStep1 = () => {
    setLocationDetails(false)
    setHotelDetails(true)
    hotelAmenitiesListData()
  }

  const handleCancelStep2 = () => {
    setLocationDetails(true)
    setHotelDetails(false)
  }

  const nextClickStep2 = () => {
    setHotelDetails(false)
    setAmenitiesDetails(true)
  }

  const handleCancelStep3 = () => {
    setAmenitiesDetails(false)
    setHotelDetails(true)
  }

  const nextClickStep3 = () => {
    setAmenitiesDetails(false)
    setPersonDetails(true)
  }

  const handleCancelStep4 = () => {
    setPersonDetails(false)
    setAmenitiesDetails(true)
  }

  const nextClickStep4 = () => {
    setPersonDetails(false)
    setHotelPhotos(true)
  }

  const handleCancelStep5 = () => {
    setHotelPhotos(false)
    setPersonDetails(true)
  }

  const nextClickStep5 = () => {
    setHotelPhotos(false)
  }

  const handleCancels = () => {
    setLocationDetails(false)
    setHotelDetails(false)
    setAmenitiesDetails(false)
    setPersonDetails(false)
    setHotelPhotos(false)
    onCancel()
  }

  const onChange = (e) => {
    setInputFields((prev) => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const onUploadImage = async (file, fieldName, index) => {
    if (file) {
      const reader = new FileReader()
      reader.onload = async () => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        {
          fieldName === 'contact_profile_pic'
            ? coverFormData.append('type', 'profile_pic')
            : coverFormData.append('type', 'hotel')
        }

        const uploadResponse = await imageUploadLocal(coverFormData)

        if (!uploadResponse.data.success) {
          throw new Error(
            uploadResponse.data.message || `${fieldName} upload failed`
          )
        }

        const decryptedImageData = dataDecrypt(uploadResponse.data.data)

        setInputFields((prev) => {
          const prevData = prev[fieldName]
          if (
            (index !== undefined || index !== null) &&
            Array.isArray(prevData)
          ) {
            prevData[index] = decryptedImageData
          }
          return {
            ...prev,
            [fieldName]: fieldName === 'logo' ? decryptedImageData : prevData
          }
        })
      }

      reader.readAsDataURL(file)
    }
  }

  const isFormValid = () => {
    return (
      inputFields.name &&
      inputFields.city_id &&
      inputFields.zip_code &&
      inputFields.website &&
      inputFields.latitude &&
      inputFields.longitude
    )
  }

  const handleClick = () => {
    document.getElementById('upload_button').click()
  }

  const toggleAmenitySelection = (amenitiesId) => {
    let updatedAmenities

    if (selectedAmenities.includes(amenitiesId)) {
      updatedAmenities = selectedAmenities.filter((id) => id !== amenitiesId)
    } else {
      updatedAmenities = [...selectedAmenities, amenitiesId]
    }

    setSelectedAmenities(updatedAmenities)

    setInputFields({
      ...inputFields,
      amenities: updatedAmenities
    })
  }

  const [isCountryList, setIsCountryList] = useState([])
  const [isProvinceList, setIsProvinceList] = useState([])
  const [isCityListData, setIsCityListData] = useState([])
  const [isHotelAmenitiesListData, setIsHotelAmenitiesListData] = useState([])

  // api call

  const countryListData = () => {
    countryList({}).then((res) => {
      if (res.data.success) {
        setIsCountryList(dataDecrypt(res.data.data))
      }
    })
  }

  const provinceListData = (data) => {
    provinceList(data).then((res) => {
      if (res.data.success) {
        setIsProvinceList(dataDecrypt(res.data.data))
      }
    })
  }

  const cityListData = (data) => {
    cityList(data).then((res) => {
      if (res.data.success) {
        setIsCityListData(dataDecrypt(res.data.data).data)
      }
    })
  }

  const hotelAmenitiesListData = () => {
    hotelAmenitiesList({}).then((res) => {
      if (res.data.success) {
        setIsHotelAmenitiesListData(dataDecrypt(res.data.data))
      }
    })
  }

  const hotelAddApiCall = () => {
    const payload = {
      name: inputFields.name,
      description: inputFields.description,
      city_id: inputFields.city_id,
      zip_code: inputFields.zip_code,
      longitude: inputFields.longitude,
      latitude: inputFields.latitude,
      website: inputFields.website,
      amenities: inputFields.amenities,
      min_price: inputFields.min_price,
      max_price: inputFields.max_price,
      logo: inputFields.logo,
      images: inputFields.images,
      contact_name: [inputFields.contact_name1, inputFields.contact_name2],
      contact_designation: [
        inputFields.contact_designation1,
        inputFields.contact_designation2
      ],
      contact_phone: [inputFields.contact_phone1, inputFields.contact_phone2],
      contact_profile_pic: inputFields.contact_profile_pic,
      hotel_id: inputFields.hotel_id
    }
    if (Object.keys(defaultData).length > 0) {
      hotelEdit(payload).then((res) => {
        if (res.data.success) {
          toast.success(res?.data?.message)
          apiSuccess()
          setHotelPhotos(false)
          setIsShowHotelList(true)
          setIsShow(false)
        } else {
          toast.error(res?.data?.message)
        }
      })
    } else {
      hotelAdd(payload).then((res) => {
        if (res.data.success) {
          toast.success(res?.data?.message)
          apiSuccess()
          setHotelPhotos(false)
          navigate('/hotels')
        } else {
          toast.error(res?.data?.message)
        }
      })
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...inputFields.images]
    updatedImages.splice(index, 1)
    setInputFields((prev) => ({
      ...prev,
      images: updatedImages
    }))
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setInputFields((prev) => {
        const copy = [...prev.images]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        return {
          ...prev,
          images: copy
        }
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {inputFields.images.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImagePathLocal + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => {
                    onUploadImage(file, 'images', index)
                  }}
                  selectedImg=""
                  defaultImg={plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [inputFields.images, handleDragStart])

  const getCountryName = (country_id) => {
    const categoryObj = isCountryList.find(
      (cat) => cat.country_id === country_id
    )
    return categoryObj ? categoryObj.country_name : 'Select Country'
  }
  const getProvinceName = (province_id) => {
    const categoryObj = isProvinceList.find(
      (cat) => cat.province_id === province_id
    )
    return categoryObj ? categoryObj.province_name : 'Select Province'
  }
  const getCityName = (city_id) => {
    const categoryObj = isCityListData.find((cat) => cat.city_id === city_id)
    return categoryObj ? categoryObj.city_name : 'Select City'
  }

  useEffect(() => {
    provinceListData({
      country_id: inputFields.country_id
    })
  }, [inputFields.country_id])

  useEffect(() => {
    cityListData({
      province_id: inputFields.province_id
    })
  }, [inputFields.province_id])

  useEffect(() => {
    countryListData()
  }, [])

  useEffect(() => {
    if (Object.keys(defaultData).length > 0) {
      setInputFields(defaultData)
      setSelectedAmenities(defaultData.amenities.map((d) => d.amenities_id))
    }
  }, [defaultData])

  return (
    <div className="content-manager">
      {locationDetails && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close align-items-center mb-2">
              {/* {step === 1 && <div>LOCATION DETAILS</div>} */}
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Back}
                  className="back_btn"
                  alt="back"
                  onClick={handleCancels}
                />
                LOCATION DETAILS
              </div>
              <button type="button" onClick={handleCancels}>
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0">
              <div className="input-grid">
                <div>
                  <label>Hotel Name</label>
                  <CommonInput
                    placeholder="Enter Name"
                    name="name"
                    onChange={onChange}
                    allowTextOnly
                    maxLength={30}
                    value={capitalizeFirstLetter(inputFields.name)}
                  />
                </div>
                <div>
                  <label>Country</label>
                  <Dropdown
                    title={getCountryName(inputFields.country_id)}
                    name="type_id"
                    onChanged={(d) => {
                      setInputFields((prev) => ({
                        ...prev,
                        country_id: d.id,
                        country: d.title
                      }))
                    }}
                    Data={isCountryList.map((d) => ({
                      title: d.country_name,
                      id: d.country_id
                    }))}
                    customClass={
                      inputFields.country_id
                        ? 'selected_class_local'
                        : 'local-dropdown'
                    }
                  />
                </div>
              </div>
              <div className="input-grid mb-3">
                <div>
                  <label>Province List</label>
                  <Dropdown
                    title={getProvinceName(inputFields.province_id)}
                    name="type_id"
                    onChanged={(d) => {
                      setInputFields((prev) => ({
                        ...prev,
                        province_id: d.id,
                        province_name: d.title
                      }))
                    }}
                    Data={isProvinceList.map((d) => ({
                      title: d.province_name,
                      id: d.province_id
                    }))}
                    customClass={
                      inputFields.province_id
                        ? 'selected_class_local'
                        : 'local-dropdown'
                    }
                  />
                </div>
                <div>
                  <label>City List</label>
                  <Dropdown
                    title={getCityName(inputFields.city_id)}
                    name="type_id"
                    onChanged={(d) => {
                      setInputFields({
                        ...inputFields,
                        city_id: d.id,
                        city_name: d.title
                      })
                    }}
                    Data={isCityListData.map((d) => ({
                      title: d.city_name,
                      id: d.city_id
                    }))}
                    customClass={
                      inputFields.city_id
                        ? 'selected_class_local'
                        : 'local-dropdown'
                    }
                  />
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>Zip Code</label>
                  <CommonInput
                    placeholder="Enter here"
                    name="zip_code"
                    type="number"
                    className="mb-0"
                    onChange={onChange}
                    pattern="/^-?\d+\.?\d*$/"
                    maxLength={6}
                    onKeyPress={(e) => {
                      if (e.target.value.length === 6) {
                        e.preventDefault()
                      }
                    }}
                    value={inputFields.zip_code}
                  />
                </div>
                <div>
                  <label>Website Link</label>
                  <CommonInput
                    placeholder="Enter Website Link"
                    name="website"
                    onChange={onChange}
                    value={inputFields.website}
                  />
                </div>
              </div>
              <div className="mt-3 mb-3">
                {/* <div>
                  <label>Address</label>
                  <CommonInput
                    placeholder="Enter address"
                    name="address"
                    onChange={onChange}
                    // onClick={handleAddressClick}
                    // value={inputFields.longitude ; inputFields.latitude}
                    readOnly
                  />
                </div> */}
                <label>Please Select Location In Map</label>
                <MapComponent
                  inputFields={inputFields}
                  setInputFields={setInputFields}
                />
              </div>
              <div className="local_button_row">
                <CommonButton
                  label="Cancel"
                  onClick={handleCancelStep1}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Next"
                  type="button"
                  isPrimary
                  onClick={nextClickStep1}
                  context="Profile"
                  disabled={!isFormValid()}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {hotelDetails && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close mb-2">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Back}
                  className="back_btn"
                  alt="back"
                  onClick={handleCancelStep2}
                />
                HOTEL DETAILS
              </div>
              <button type="button" onClick={handleCancels}>
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0">
              <div className="logo_btn">
                <div className="add_logo">
                  <CommonImageUpload
                    id="upload_button"
                    name="local_upload_logo"
                    handleImageChange={(file) => onUploadImage(file, 'logo')}
                    index={0}
                    forImage="local_logo"
                    className="profile-upload"
                    selectedImg={
                      inputFields.logo
                        ? `${s3ImagePathLocal}${inputFields.logo}`
                        : ''
                    }
                    defaultImg={local_add_img}
                  />
                </div>
                <div className="upload_btn_logo">
                  <CommonButton
                    label="UPLOAD FILE"
                    context="local"
                    className="local-secondary-button"
                    onClick={handleClick}
                  />
                  <p className="image_validation">
                    At least 400x400 px recommended.
                    <br />
                    JPEG or PNG is allowed
                  </p>
                </div>
              </div>
              <div className="input-grid">
                <div>
                  <label>Minimum Price</label>
                  <CommonInput
                    placeholder="Min.price"
                    name="min_price"
                    pattern="/^-?\d+\.?\d*$/"
                    maxLength={4}
                    onKeyPress={(e) => {
                      if (e.target.value.length === 4) {
                        e.preventDefault()
                      }
                    }}
                    type="number"
                    onChange={onChange}
                    value={inputFields.min_price}
                  />
                </div>
                <div>
                  <label>Maximum Price</label>
                  <CommonInput
                    placeholder="Max.price"
                    name="max_price"
                    type="number"
                    pattern="/^-?\d+\.?\d*$/"
                    maxLength={4}
                    onKeyPress={(e) => {
                      if (e.target.value.length === 4) {
                        e.preventDefault()
                      }
                    }}
                    onChange={onChange}
                    value={inputFields.max_price}
                  />
                </div>
              </div>
              <div className="">
                <div>
                  <label>Description</label>
                  <textarea
                    placeholder="Briefly describe about your hotel"
                    name="description"
                    onChange={onChange}
                    value={capitalizeFirstLetter(inputFields.description)}
                  />
                </div>
              </div>
              <div className="local_button_row">
                <CommonButton
                  label="Skip"
                  onClick={nextClickStep2}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Next"
                  type="button"
                  isPrimary
                  onClick={nextClickStep2}
                  context="Profile"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {amenitiesDetails && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close mb-2">
              {/* {step === 3 && <div>Amenities</div>} */}
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Back}
                  className="back_btn"
                  alt="back"
                  onClick={handleCancelStep3}
                />
                Amenities
              </div>
              <button type="button" onClick={handleCancels}>
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="tool-option drop-search-3-0 mb-2">
              <CommonInput
                className="w-100 search-input"
                placeholder="Search..."
              />
            </div>
            <div className="amenities_name">
              {isHotelAmenitiesListData.map((amenity) => (
                <div
                  key={amenity.amenities_id}
                  className={`type_amenities ${selectedAmenities.includes(amenity.amenities_id) ? 'selected' : ''}`}
                  onClick={() => toggleAmenitySelection(amenity.amenities_id)}
                >
                  <img
                    className="hotel_amenities"
                    src={s3ImagePathLocal + amenity.icon}
                    alt={amenity.name}
                  />
                  <h6>{amenity.name}</h6>
                </div>
              ))}
            </div>
            <div className="local_button_row">
              <CommonButton
                label="Skip"
                onClick={nextClickStep3}
                isPrimary={false}
                context="Profile"
              />
              <CommonButton
                label="Next"
                type="button"
                isPrimary
                onClick={nextClickStep3}
                context="Profile"
              />
            </div>
          </div>
        </div>
      )}

      {personDetails && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close mb-2">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Back}
                  className="back_btn"
                  alt="back"
                  onClick={handleCancelStep4}
                />
                Contact Person
              </div>
              <button type="button" onClick={handleCancels}>
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="item-details-input pt-0">
              <h6 className="fw-bolder">Contact Person</h6>
              <div className="logo_btn">
                <div className="image_upload_section">
                  <div className="add_photos mb-4 mt-2">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 0)}
                        index={0}
                        forImage="local_photo"
                        className="profile-upload"
                        selectedImg={
                          inputFields.contact_profile_pic[0]
                            ? `${s3ImagePathLocal}${inputFields.contact_profile_pic[0]}`
                            : ''
                        }
                        defaultImg={local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="person_detail_section">
                  <div>
                    <label>Name</label>
                    <CommonInput
                      placeholder="Name"
                      onChange={onChange}
                      name="contact_name1"
                      maxLength={20}
                      allowTextOnly
                      value={capitalizeFirstLetter(inputFields.contact_name1)}
                    />
                  </div>
                  <div>
                    <label>Designation</label>
                    <CommonInput
                      placeholder="Designation"
                      onChange={onChange}
                      name="contact_designation1"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(
                        inputFields.contact_designation1
                      )}
                    />
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <CommonInput
                      placeholder="+4915234213935"
                      name="contact_phone1"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={10}
                      onChange={onChange}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 10) {
                          e.preventDefault()
                        }
                      }}
                      value={inputFields.contact_phone1}
                    />
                  </div>
                </div>
              </div>
              <div className="logo_btn">
                <div className="image_upload_section">
                  <div className="add_photos mb-4 mt-2">
                    <div className="add_logo_text text-center align-self-center">
                      <CommonImageUpload
                        name="local_upload_logo"
                        handleImageChange={(file) => onUploadImage(file, 'contact_profile_pic', 1)}
                        index={1}
                        forImage="local_photo"
                        className="profile-upload"
                        selectedImg={
                          inputFields.contact_profile_pic[1]
                            ? `${s3ImagePathLocal}${inputFields.contact_profile_pic[1]}`
                            : ''
                        }
                        defaultImg={local_add_img}
                      />
                    </div>
                  </div>
                </div>
                <div className="person_detail_section">
                  <div>
                    <label>Name</label>
                    <CommonInput
                      placeholder="Name"
                      onChange={onChange}
                      name="contact_name2"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(inputFields.contact_name2)}
                    />
                  </div>
                  <div>
                    <label>Designation</label>
                    <CommonInput
                      placeholder="Designation"
                      onChange={onChange}
                      name="contact_designation2"
                      allowTextOnly
                      maxLength={20}
                      value={capitalizeFirstLetter(
                        inputFields.contact_designation2
                      )}
                    />
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <CommonInput
                      placeholder="+4915234213935"
                      name="contact_phone2"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      maxLength={10}
                      onChange={onChange}
                      onKeyPress={(e) => {
                        if (e.target.value.length === 10) {
                          e.preventDefault()
                        }
                      }}
                      value={inputFields.contact_phone2}
                    />
                  </div>
                </div>
              </div>
              <div className="local_button_row">
                <CommonButton
                  label="Skip"
                  onClick={nextClickStep4}
                  isPrimary={false}
                  context="Profile"
                />
                <CommonButton
                  label="Next"
                  type="button"
                  isPrimary
                  onClick={nextClickStep4}
                  context="Profile"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {hotelPhotos && (
        <div className="add-item-model">
          <div className="add-item-content local_type_modal">
            <div className="type_close mb-2">
              <div className="d-flex align-items-center col-gap-10">
                <img
                  src={Back}
                  className="back_btn"
                  alt="back"
                  onClick={handleCancelStep5}
                />
                HOTEL PHOTOS
              </div>
              <button type="button" onClick={handleCancels}>
                <img src={Remove} alt="" />
              </button>
            </div>
            <div className="hotel_image_upload_section">
              <div className="input-image-show">
                {renderImg}
                {inputFields.images.length < 4 && (
                  <div className="input-img-label">
                    <CommonImageUpload
                      handleImageChange={(file) => onUploadImage(file, 'images', inputFields.images.length)}
                      buttonText="Add Image"
                      selectedImg=""
                      defaultImg={plusgredient}
                      className="plsimg"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="local_button_row">
              <CommonButton
                label="Skip"
                onClick={nextClickStep5}
                isPrimary={false}
                context="Profile"
              />
              <CommonButton
                label="Next"
                type="button"
                isPrimary
                onClick={hotelAddApiCall}
                context="Profile"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default hotelPopup
