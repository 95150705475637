/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import './RecentPodcast.css'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Plus from '../../images/plus-gredient.svg'
import Dropdown from '../Dropdown/Dropdown'
import RatingStar from '../../images/rating-start.svg'
import UploadImg from '../../images/upload.svg'
import CloseIco from '../../images/CloseIco.svg'
import AddIco from '../../images/AddIco.svg'
import {
  addPodcastData,
  deletePodcastData,
  editPodcastData,
  getHostList,
  getPodcastsData,
  imageUploadCreator,
  podcastLike,
  s3ImagePath,
  s3ImagePathCreator
} from '../../services/Service'
import { compressImage, dataDecrypt } from '../../services/http-services'
import PlatformTag from '../ContentManager/PlatformTag'
import Loader from '../Loader/Loader'
import PodcastPauseIco from '../../images/PodcastPauseIco.svg'
import PodcastPlayIco from '../../images/PodcastPlayIco.svg'
import Likes from '../../images/like-fill.svg'
import UnLikes from '../../images/like-empty.svg'
import {
  debounce,
  formatDurationPodcast,
  timeDifference
} from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const PodcastComponent = ({ showCount }) => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const navigate = useNavigate()
  const { id } = useParams()
  const audioRef = useRef(null)
  const audioRefs = useRef({})

  const [podcast, setPodcast] = useState([])
  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [ShowPodcastCoverModel, setShowPodcastCoverModel] = useState(false)
  const [ShowPodcastPreviewModel, setShowPodcastPreviewModel] = useState(false)
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    minutes: '',
    attachment: '',
    cover_attachment: '',
    app_id: [],
    host_id: 0,
    search: ''
  })
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedAudioName, setSelectedAudioName] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [hostName, setHostName] = useState([])
  const [Platformtags, setPlatformTags] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delPodcastId, setDelPodcastId] = useState()
  const [loading, setLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [previewModel, setPreviewModel] = useState(false)
  const [plays, setPlays] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [initialPlatformTags, setInitialPlatformTags] = useState([])
  const [initialDescription, setInitialDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [isPlaying, setIsPlaying] = useState({})
  const [localStorageValue, setLocalStorageValue] = useState(() => {
    return JSON.parse(localStorage.getItem('app_id')) || null
  })

  const AddPodcast = () => {
    setShowPodcastModel(true)
  }
  const AddPodcastCover = () => {
    setShowPodcastCoverModel(true)
    setShowPodcastModel(false)
  }
  const AddPodcastPreview = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(true)
  }

  const HandleClose = () => {
    clearValue()
    setPreviewModel(false)
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setIsEditing(false)
  }
  const handleCancel = () => {
    clearValue()
    setShowConfirmModel(false)
  }
  const HandleCancelCover = () => {
    setShowPodcastModel(true)
    setShowPodcastCoverModel(false)
  }
  const HandleCancelPreview = () => {
    setShowPodcastPreviewModel(false)
    setShowPodcastCoverModel(true)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }
  const isAppsSelected = inputFields.app_id?.length > 0

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedFileObj('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setSelectedAudioName('')
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      minutes: '',
      attachment: '',
      cover_attachment: '',
      app_id: [],
      host_id: 0
    })
  }

  const addPodcastApiCall = () => {
    addPodcastData(inputFields).then((res) => {
      if (res.data.success) {
        setShowPodcastPreviewModel(false)
        clearValue()
        toast.success(res?.data?.message)
        getPodcast()
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const handlePublishClick = async () => {
    const durationInSeconds = Math.trunc(audioRef.current.duration)
    const formattedDuration = formatDurationPodcast(durationInSeconds)

    inputFields.minutes = formattedDuration

    setLoading(true)

    try {
      if (selectedFileObj) {
        const imageFile = await compressImage(selectedFileObj)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'podcast')

        const coverUploadResponse = await imageUploadCreator(coverFormData)
        if (!coverUploadResponse.data.success) {
          throw new Error(
            coverUploadResponse.data.message || 'Cover image upload failed'
          )
        }
        const decryptedCoverData = dataDecrypt(coverUploadResponse.data.data)
        inputFields.cover_attachment = decryptedCoverData
      }

      if (!isEditing || selectedVideoObj) {
        const videoFormData = new FormData()
        videoFormData.append('file', selectedVideoObj)
        videoFormData.append('type', 'podcast')

        const videoUploadResponse = await imageUploadCreator(videoFormData)
        if (!videoUploadResponse.data.success) {
          throw new Error(
            videoUploadResponse.data.message || 'Video upload failed'
          )
        }
        const decryptedVideoData = dataDecrypt(videoUploadResponse.data.data)
        inputFields.attachment = decryptedVideoData
      }

      if (isEditing) {
        await editPodcastApiCall()
      } else {
        await addPodcastApiCall()
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  // const handleFileChange = (event) => {
  //   const fileInput = event.target
  //   const file = fileInput.files[0]
  //   if (file) {
  //     setSelectedFileObj(file)
  //     const reader = new FileReader()

  //     reader.onload = (e) => {
  //       setSelectedFilePath(e.target.result)
  //     }

  //     reader.readAsDataURL(file)
  //   }
  // }

  const handleFileChange = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
    }
    reader.readAsDataURL(file)
  }

  const handleVideoChange = (event) => {
    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      setSelectedVideoObj(selectedFile)
      setSelectedAudioName(selectedFile.name)
      const videoURL = URL.createObjectURL(selectedFile)
      setSelectedVideo(videoURL)
      console.log('===============', selectedFile)
    }
  }

  const podcastHostApiCall = () => {
    getHostList({}).then((res) => {
      if (res.data.success) {
        setHostName(dataDecrypt(res.data.data))
      }
    })
  }

  const ShowConfirm = (videoId) => {
    setDelPodcastId(videoId)
    setShowConfirmModel(true)
  }

  const deletePodcast = () => {
    const data = {
      podcast_id: delPodcastId
    }
    setLoading(true)
    deletePodcastData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        getPodcast()
        clearValue()
        setLoading(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const editPodcastApiCall = () => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      host_id: inputFields.host_id,
      app_id: Platformtags.map((d) => d.id),
      podcast_id: inputFields.podcast_id
    }
    editPodcastData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        setShowPodcastPreviewModel(false)
        setPreviewModel(false)
        clearValue()
        getPodcast()
        setIsEditing(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const onClickEditPodcast = (videoData) => {
    setShowPodcastModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePathCreator + videoData.attachment)
    setSelectedFilePath(s3ImagePathCreator + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
    setInitialValues({
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id,
      search: ''
    })
    setPlatformTags(videoData.app_data)
  }

  const handleItemClick = (videoData) => {
    setPreviewModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePathCreator + videoData.attachment)
    setSelectedFilePath(s3ImagePathCreator + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      minutes: videoData.minutes,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
    setPlatformTags(videoData.app_data)
    setInitialPlatformTags(videoData.app_data)
    setInitialDescription(videoData.description)
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = hostName.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.host : 'Select Host'
  }

  const validateForm = () => {
    const { title, host_id, description } = inputFields
    if (title && host_id && description && selectedVideo) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const getPodcast = (search) => {
    setIsLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      search
    }
    getPodcastsData(platFormData).then((res) => {
      if (res.data.success) {
        setPodcast(dataDecrypt(res.data.data).data)
        setIsLoading(false)
      }
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getPodcast(inputVal), 500),
    []
  )

  const videoLikeData = (podcast_id) => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id,
      podcast_id
    }
    podcastLike(platFormData).then((res) => {
      if (res.data.success) {
        getPodcast()
        toast.success(res?.data?.message)
      }
    })
  }

  // Podcasts pagination

  const podcastPerPage = 9
  const indexOfLastVideo = currentPage * podcastPerPage
  const indexOfFirstVideo = indexOfLastVideo - podcastPerPage
  const podcasts = podcast.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = podcast.length
  const totalPages = Math.ceil(totalVideos / podcastPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // on hover play pause

  useEffect(() => {
    return () => {
      Object.keys(audioRefs.current).forEach((index) => {
        const audio = audioRefs.current[index]
        if (audio) {
          audio.pause()
          audio.src = ''
        }
      })
    }
  }, [])

  const handleMouseEnter = (index, attachment) => {
    setHoveredIndex(index)

    if (!audioRefs.current[index]) {
      const newAudio = new Audio(attachment)
      newAudio.onerror = (e) => {
        console.error('Audio playback error:', e)
      }
      audioRefs.current[index] = newAudio
    }

    audioRefs.current[index]
      .play()
      .then(() => {
        setIsPlaying((prev) => ({ ...prev, [index]: true }))
      })
      .catch((error) => {
        console.error('Error playing audio:', error)
      })
  }

  const handleMouseLeave = (index) => {
    setHoveredIndex(null)

    if (audioRefs.current[index]) {
      audioRefs.current[index].pause()
      audioRefs.current[index].currentTime = 0
      setIsPlaying((prev) => ({ ...prev, [index]: false }))
    }
  }

  const togglePlayPause = (index) => {
    const audio = audioRefs.current[index]

    if (audio) {
      if (audio.paused) {
        audio
          .play()
          .then(() => {
            setIsPlaying((prev) => ({ ...prev, [index]: true }))
          })
          .catch((error) => {
            console.error('Error playing audio:', error)
          })
      } else {
        audio.pause()
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    }
  }
  const playVolumeIcon = (index) => (
    <img
      src={isPlaying[index] ? PodcastPauseIco : PodcastPlayIco}
      alt={isPlaying[index] ? 'Pause' : 'Play'}
      onClick={(e) => {
        e.stopPropagation()
        togglePlayPause(index)
      }}
      className="volume-icon"
    />
  )

  const togglePlayPauses = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play()
        setPlays(true)
      } else {
        audioRef.current.pause()
        setPlays(false)
      }
    }
  }

  useEffect(() => {
    podcastHostApiCall()
    const handleClickOutside = (event) => {
      if (!loading) {
        if (
          componentRef.current &&
          !componentRef.current.contains(event.target)
        ) {
          // setShowPodcastCoverModel(false)
          setShowPodcastModel(false)
          setShowPodcastPreviewModel(false)
          setPreviewModel(false)
          clearValue()
          setIsEditing(false)
        }
      }
    }
    if (
      !loading &&
      (ShowPodcastModel ||
        // ShowPodcastCoverModel ||
        ShowPodcastPreviewModel ||
        previewModel ||
        ShowConfirmModel)
    ) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(JSON.parse(localStorage.getItem('app_id')) || null)
    }
    window.addEventListener('storage', handleStorageChange)
    return () => window.removeEventListener('storage', handleStorageChange)
  }, [])

  useEffect(() => {
    if (localStorageValue !== null) {
      getPodcast()
    }
  }, [localStorageValue])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields, selectedVideo])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing, selectedVideo])
  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowPodcastModel(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className="discussions-wrapper-header common-contnt-wrapper">
        {showCount && (
          <div className="tag_link">
            {/* <div className="video_button"> */}
            <h2>Podcast</h2>

            <div className="add-video-rows">
              <CommonButton
                label="Add New Podcast"
                imgSrc={AddIco}
                onClick={AddPodcast}
                isPrimary
                className="add_new_content"
              />
            </div>
            {/* </div> */}
          </div>
        )}
      </div>
      <div
        className={
          showCount !== 3 &&
          'recent-video-component recent-podcast common-card-body add-podcaest-video'
        }
      >
        {showCount !== 3 && (
          <h3>
            Podcasts
            <div className="items-filter">
              <div className="position-relative input-position">
                <CommonInput
                  placeholder="Search Podcast"
                  onChange={(e) => {
                    handleSearch(e.target.value)
                  }}
                />
              </div>
            </div>
          </h3>
        )}
        {showCount !== 3 && (
          <div className="drop-row mb-30">
            <div className="drop-sub-row">
              {showCount !== 3 && (
                <Dropdown
                  title="Categories"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
              {showCount !== 3 && (
                <Dropdown
                  title="Ratings"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
              {showCount !== 3 && (
                <Dropdown
                  title="Duration"
                  Data={[
                    { title: 'Scaloo' },
                    { title: 'Spacure' },
                    { title: 'PETZINO' },
                    { title: 'A-Z FIT' },
                    { title: 'TESTIEE' }
                  ]}
                  customClass="content-filter disabledDropdown"
                />
              )}
            </div>
          </div>
        )}
        {!showCount && (
          <div className="add-video-rows main_btn">
            <CommonButton
              label="Add New Podcast"
              imgSrc={AddIco}
              onClick={AddPodcast}
              isPrimary
              className="add_new_content"
            />
          </div>
        )}
        <div className="Podcasts-item">
          {podcasts.length === 0 && !isLoading ? (
            <div className="Podcasts-item-detail">
              <div className="podacast-item">
                <div className="podcast-cover">
                  <button type="button" onClick={AddPodcast}>
                    <img src={Plus} alt="add library" />
                  </button>
                </div>
                <div className="video-details podcast-detail">
                  <div className="d-flex justify-content-between">
                    <h6>
                      <img src="" alt="" />
                      <span />
                    </h6>
                  </div>
                  <p className="podcast-name" />
                  <span className="podcast-upload-time" />
                  <div className="other-info" />
                </div>
              </div>
            </div>
          ) : isLoading ? (
            <div className="Podcasts-item-detail">
              <div className="podacast-item">
                <div className="podcast-cover">
                  <div className="play play_button_skeleton">
                    <Skeleton circle width={25} height={25} />
                  </div>
                </div>
                <div className="video-details podcast-detail">
                  <div className="d-flex justify-content-between">
                    <h6>
                      <img src="" alt="" />
                      <span />
                    </h6>
                  </div>
                  <p className="podcast-name">
                    <Skeleton className="podcast-name" width={332} />
                  </p>
                  <span className="podcast-upload-time">
                    <Skeleton className="podcast-upload-time" width={89} />
                  </span>
                  <div className="other-info mt-0">
                    <Skeleton className="other-info mt-0" width={332} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            podcasts.map(
              (item, index) => index < (showCount || totalVideos) && (
              <div
                className="awards-item-podcast position-relative"
                key={index}
                onMouseEnter={() => handleMouseEnter(
                        index,
                        `${s3ImagePathCreator}${item.attachment}`
                      )}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div
                  className="recent-podcast-item"
                  key={item.name}
                  onClick={() => handleItemClick(item)}
                  style={{
                        backgroundImage: `url(${
                          hoveredIndex === index
                            ? `${s3ImagePathCreator}${item.cover_attachment}`
                            : `${s3ImagePathCreator}${item.cover_attachment}`
                        })`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                >
                  <div className="video_like_bg">
                    <button
                      type="button"
                      className="video_like"
                      onClick={(e) => {
                            e.stopPropagation()
                            videoLikeData(item.podcast_id)
                          }}
                    >
                      {item.is_like ? (
                        <img src={Likes} alt="Unlike" />
                          ) : (
                            <img src={UnLikes} alt="Like" />
                          )}
                    </button>
                  </div>
                  <span className="minutes-badge">{item.minutes}</span>
                  {playVolumeIcon(index)}
                </div>
                <div className="d-flex justify-content-between user_image">
                  <h6 className="mb-0">
                    <img src={s3ImagePath + item.profile_icon} alt="" />
                    <span className="ml-2">{item.user_name}</span>
                  </h6>
                  <div className="rating">
                    <img src={RatingStar} alt="" />
                    <span>4.9</span>
                  </div>
                </div>

                <div className="podcast-item-detail">
                  <h6>{item.title}</h6>
                  <p className="timer">{timeDifference(item.created_at)}</p>
                  <p>{item.description}</p>
                </div>
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      onClick={() => onClickEditPodcast(item)}
                      className="d-none"
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0"
                    onClick={() => {
                          setUpdatingIndex(index)
                          ShowConfirm(item.podcast_id)
                        }}
                  />
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel && updatingIndex === index}
                  onConfirm={deletePodcast}
                  onCancel={handleCancel}
                  message="Do you want to delete this?"
                  isLoading={loading}
                />
                {loading && updatingIndex === index && <Loader />}
              </div>
                )
            )
          )}
          {previewModel && (
            <div className="add-item-model preview-model-podcast">
              <div className="add-item-content" ref={componentRef}>
                <div className="d-flex justify-content-between header">
                  <h3>Upload Files</h3>
                  <button
                    type="button"
                    className="border-0 bg-none p-0"
                    onClick={HandleClose}
                  >
                    <span>
                      <img className="close-btns" src={CloseIco} alt="" />
                    </span>
                  </button>
                </div>
                <div className="scroll-bars">
                  <div className="podcast-overview">
                    <div className="podcast-cover-img">
                      <div className="audio-container">
                        <img
                          src={selectedFilePath}
                          alt="Poster"
                          className="audio-poster"
                        />
                      </div>

                      <div className="podcast-profile">
                        <div className="podcast-profile-img">
                          <img
                            src={s3ImagePath + userData.profile_image}
                            alt=""
                          />
                        </div>
                        <div className="podcast-profile-detail">
                          <span>{userData.user_name}</span>
                          <p className="designation">Designation</p>
                        </div>
                      </div>
                    </div>
                    <div className="podcast-detail">
                      <h3>{inputFields.title}</h3>
                      <p>The Demo Podcast</p>
                      <img
                        src={plays ? PodcastPauseIco : PodcastPlayIco}
                        onClick={togglePlayPauses}
                        style={{ cursor: 'pointer' }}
                        alt="Play/Pause"
                      />
                      <audio
                        ref={audioRef}
                        width="100%"
                        height="200"
                        className="audio-element"
                      >
                        <source src={selectedVideo} type="audio/mp3" />
                      </audio>
                    </div>
                  </div>
                  <div className="podcast-discription">
                    <h5>Decription</h5>
                    <textarea
                      className="preview_text"
                      onChange={(e) => setInputFields({
                          ...inputFields,
                          description: e.target.value
                        })}
                      value={inputFields.description}
                    >
                      <p>{inputFields.description}</p>
                    </textarea>
                  </div>
                  <PlatformTag
                    setPlatformTags={setPlatformTags}
                    Platformtags={Platformtags}
                    onItemSelect={(value) => getSelectedAppId(value)}
                  />
                  <div className="item-details-input pt-0">
                    <div className="button-row pb-2">
                      <CommonButton
                        label="Cancel"
                        type="button"
                        onClick={HandleClose}
                        isPrimary={false}
                        disabled={loading}
                        context="Profile"
                      />
                      <CommonButton
                        label={isAppsSelected ? 'Publish' : 'Upload'}
                        loading={loading}
                        type="button"
                        onClick={handlePublishClick}
                        className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                        isPrimary
                        disabled={
                          loading ||
                          (_.isEqual(initialPlatformTags, Platformtags) &&
                            initialDescription === inputFields.description)
                        }
                        context="Profile"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {totalPages > 1 && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
        {ShowPodcastModel && (
          <div className="add-item-model">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header">
                <h3>Upload Files</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={HandleClose}
                >
                  <span>
                    <img className="close-btns" src={CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars">
                <div className="item-details-input pt-0">
                  <div className="upload-input upload-input-with-border">
                    <label htmlFor="upload-video">
                      {/* <input
                        onChange={handleVideoChange}
                        id="upload-video"
                        type="file"
                        accept="audio/*"
                        className="d-none"
                      /> */}
                      <CommonInput
                        type="file"
                        accept="audio/*"
                        id="upload-video"
                        onChange={handleVideoChange}
                        className="d-none"
                      />
                      {selectedVideo && (
                        <div>
                          <p>{selectedAudioName}</p>
                          <button
                            type="button"
                            className={`edit-button-model content-meneger-edit ${
                              isEditing ? 'hidden' : ''
                            }`}
                            onClick={() => {
                              setIsEditing(true)
                              document.getElementById('upload-video').click()
                            }}
                          />
                        </div>
                      )}
                      {!selectedVideo && (
                        <div className="upload-div">
                          <div className="upload-img">
                            <img src={UploadImg} alt="" />
                          </div>
                          <h4>Upload a New Podcast</h4>
                          <p className="drag-drop-text">
                            Drag and drop your file or click the button below to
                            upload
                          </p>
                          <CommonButton
                            label="Upload Podcast"
                            htmlFor="upload-video"
                            type="button"
                            className="upload_btn_width"
                            onClick={() => document.getElementById('upload-video').click()}
                          />
                        </div>
                      )}
                    </label>
                    {selectedVideo && (
                      <div className="">
                        <audio
                          key={selectedVideo}
                          ref={audioRef}
                          width="100%"
                          height="394"
                          controls
                          controlsList="nodownload noplaybackrate"
                        >
                          <source src={selectedVideo} type="audio/mp3" />
                        </audio>
                      </div>
                    )}
                  </div>
                  <div className="input-grid">
                    <div>
                      <label>Title of the Podcast</label>
                      <CommonInput
                        name="title"
                        placeholder="Title"
                        onChange={handleChange}
                        value={inputFields.title}
                      />
                    </div>
                    <div>
                      <label>Select Host</label>
                      <Dropdown
                        title={getCategoryName(inputFields.host_id)}
                        onChanged={(d) => {
                          setInputFields({
                            ...inputFields,
                            host_id: d.id
                          })
                        }}
                        Data={hostName.map((d) => ({
                          title: d.host,
                          id: d.id
                        }))}
                        name="host_id"
                        customClass={
                          inputFields.host_id
                            ? 'selected-class'
                            : 'giveaway-dropdown'
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <label>Decription</label>
                    <CommonInput
                      name="description"
                      placeholder="Type here"
                      onChange={handleChange}
                      value={inputFields.description}
                      isTextArea
                    />
                  </div>
                  <div className="button-row pb-2">
                    <CommonButton
                      label="Cancel"
                      onClick={HandleClose}
                      isPrimary={false}
                      context="Profile"
                    />
                    <CommonButton
                      label="Next"
                      type="button"
                      isPrimary
                      disabled={!isFormComplete}
                      onClick={AddPodcastCover}
                      context="Profile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {ShowPodcastCoverModel && (
          <div className="add-item-model">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header">
                <h3>Upload Cover</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={HandleClose}
                >
                  <span>
                    <img className="close-btns" src={CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars mt-2">
                <div>
                  <div className="upload-input">
                    <CommonImageUpload
                      handleImageChange={handleFileChange}
                      index={0}
                      buttonText="Upload Cover"
                      selectedImg={selectedFilePath}
                      defaultImg=""
                      forImage
                      forName="Podcast"
                    />
                  </div>
                </div>
                <div className="item-details-input">
                  <div className="button-row-shots">
                    <CommonButton
                      label="Back"
                      onClick={HandleCancelCover}
                      isPrimary={false}
                      context="Profile"
                    />
                    <CommonButton
                      label="Preview"
                      type="button"
                      isPrimary
                      disabled={!selectedFilePath}
                      onClick={AddPodcastPreview}
                      context="Profile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {ShowPodcastPreviewModel && (
          <div className="add-item-model preview-model-podcast">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header">
                <h3>Upload Files</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  disabled={loading}
                  onClick={HandleClose}
                >
                  <span>
                    <img className="close-btns" src={CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars">
                <div className="podcast-overview">
                  <div className="podcast-cover-img">
                    {/* <div
                  className="podcast-img"
                  style={{
                    background: 'url("./images/profile.png")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                ></div> */}
                    <div className="audio-container">
                      <img
                        src={selectedFilePath}
                        alt="Poster"
                        className="audio-poster"
                      />
                    </div>

                    <div className="podcast-profile">
                      <div className="podcast-profile-img">
                        <img
                          src={s3ImagePath + userData.profile_image}
                          alt=""
                        />
                      </div>
                      <div className="podcast-profile-detail">
                        <span>{userData.user_name}</span>
                        <p className="designation">Designation</p>
                      </div>
                    </div>
                  </div>
                  <div className="podcast-detail">
                    <h3>{inputFields.title}</h3>
                    <p>The Demo Podcast</p>
                    <img
                      src={plays ? PodcastPauseIco : PodcastPlayIco}
                      onClick={togglePlayPauses}
                      style={{ cursor: 'pointer' }}
                      alt="Play/Pause"
                    />
                    <audio
                      ref={audioRef}
                      width="100%"
                      height="200"
                      className="audio-element"
                    >
                      <source src={selectedVideo} type="audio/mp3" />
                    </audio>
                  </div>
                </div>
                <div className="podcast-discription">
                  <h5>Decription</h5>
                  <p>{inputFields.description}</p>
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
                <div className="item-details-input pt-0">
                  <div className="button-row pb-2">
                    <CommonButton
                      label="Back"
                      type="button"
                      onClick={HandleCancelPreview}
                      isPrimary={false}
                      disabled={loading}
                      context="Profile"
                    />
                    <CommonButton
                      label={isAppsSelected ? 'Publish' : 'Upload'}
                      loading={loading}
                      type="button"
                      onClick={handlePublishClick}
                      className={`primary-btn-model-store ${loading ? 'loading' : ''}`}
                      isPrimary
                      disabled={loading}
                      context="Profile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {podcasts.length >= 2 && showCount && (
        <div
          className="view_all text-end mt-4"
          onClick={() => navigate(`/profile-builder/${id}`, {
              state: { defaultIndex: 4 }
            })}
        >
          See all
        </div>
      )}
    </>
  )
}
export default PodcastComponent
