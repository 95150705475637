import './App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React from 'react'
import LandingPage from './components/pages/LandingPage'
import LoginPage from './components/pages/LoginPage'
import RegisterPage from './components/pages/RegisterPage'
import ForgetPasswordPage from './components/pages/ForgetPasswordPage'
import ResetPasswordPage from './components/pages/ResetPasswordPage'
import AppIcons from './components/pages/AppIcons'
import UserInformation from './components/pages/UserInformation'
import ReLogin from './components/pages/ReLogin'
import Profile from './components/profile/CreateProfile'
import ProfileMain from './components/profile/Profile'
import Layout from './components/Layout/Layout'
import Profiles from './components/profile/profiles'
import Dashboard from './components/Dashboard/Dashboard'
import Settings from './components/Settings/Settings'
import ContentManager from './components/ContentManager/ContentManager'
import DetailsComponent from './components/ContentManager/DetailsComponent'
import CreatorProfile from './components/CreatorProfile/CreatorProfile'
import ExpertProfile from './components/ExpertProfile/ExpertProfile'
import LocalProfile from './components/LocalProfile/LocalProfile'
import Hotel from './components/Hotel/Hotel'
import HotelDetail from './components/Hotel/HotelDetail'
import Events from './components/Events/Events'
import EventDetail from './components/Events/EventDetail'
import Restaurants from './components/Restaurants/Restaurants'
import MenuAll from './components/Restaurants/MenuAll'
import RestaurantDetail from './components/Restaurants/RestaurantDetail'
import StoreProfile from './components/StoreProfile/StoreProfile'
import StoreDetail from './components/StoreProfile/StoreDetail'
import EducationSchoolUnivercity from './components/EducationSchoolUnivercity/EducationSchoolUnivercity'
import JobDetail from './components/EducationSchoolUnivercity/JobDetail'
import Agency from './components/Agency/Agency'
import Foundation from './components/Foundation/Foundation'
import Store from './components/Store/Store'
import Orders from './components/Store/Orders'
import Clients from './components/Store/Client'
import Rating from './components/Store/Rating'
import Products from './components/Store/Products'
import Locals from './components/Local/Local'
import Hotels from './components/Local/Hotels'
import Restaurant from './components/Local/Restaurant'
import Event from './components/Local/Events'
import Ads from './components/Ads/Ads'
import ServiceCenter from './components/ServiceCenter/ServiceCenter'
import Support from './components/ServiceCenter/Support'
import Workspace from './components/Workspace/Workspace'
import ProfileBuilder from './components/CreatorProfile/ProfileBuilder'
import InstallAddons from './components/CreatorProfile/installAddons'
import LayoutBuilder from './components/LayoutBuilder/Layout'
import CommunityBuilder from './components/CreatorProfile/CommunityBuilder'
import StoreBuilder from './components/CreatorProfile/StoreBuilder'
import LocalGastronomy from './components/ProfileBuilderBusinessUser/LocalGastronomy'
import LocalHotels from './components/ProfileBuilderBusinessUser/LocalHotels'
import LocalEvents from './components/ProfileBuilderBusinessUser/LocalEvents'
import Admin from './components/Admin/Admin'
import AuthGuard from './guards/AuthGuards'
import RouteGuards from './guards/RouteGuards'
// import ProfileLayout from './components/LayoutBuilder/ProfileLayout'
import OtpVerification from './components/pages/OtpVerification'

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            exact
            path="/"
            element={<RouteGuards component={<LandingPage />} />}
          />
          {/* <Route path="/login" element={<LoginPage />} /> */}
          <Route
            path="/login"
            element={<RouteGuards component={<LoginPage />} />}
          />
          <Route
            path="/otp-verification"
            element={<RouteGuards component={<OtpVerification />} />}
          />
          <Route
            path="/register"
            element={<RouteGuards component={<RegisterPage />} />}
          />
          <Route
            path="/forget-password"
            element={<RouteGuards component={<ForgetPasswordPage />} />}
          />
          <Route
            path="/reset-password"
            element={<RouteGuards component={<ResetPasswordPage />} />}
          />
          <Route
            path="/app-icons"
            element={<AuthGuard component={<AppIcons />} />}
          />
          <Route
            path="/user-information"
            element={<RouteGuards component={<UserInformation />} />}
          />
          <Route
            path="/re-login"
            element={<RouteGuards component={<ReLogin />} />}
          />
          {/* <Route path="/" element={<AuthGuard component={<ProfileLayout />} />}>

            </Route> */}
          <Route path="/" element={<AuthGuard component={<LayoutBuilder />} />}>
            <Route
              path="install-addons/:id"
              element={<AuthGuard component={<InstallAddons />} />}
            />
            <Route
              path="profile-builder/:id"
              element={<AuthGuard component={<ProfileBuilder />} />}
            />
            <Route
              path="community-builder/:id"
              element={<AuthGuard component={<CommunityBuilder />} />}
            />
            <Route
              path="store-builder/:id"
              element={<AuthGuard component={<StoreBuilder />} />}
            />
            {/* <Route
              path="store-builder/:id"
              element={<AuthGuard component={<StoreBuilder />} />}
            /> */}
            <Route
              path="local-gastronomy"
              element={<AuthGuard component={<LocalGastronomy />} />}
            />
            <Route
              path="local-hotels"
              element={<AuthGuard component={<LocalHotels />} />}
            />
            <Route
              path="local-events"
              element={<AuthGuard component={<LocalEvents />} />}
            />
          </Route>
          <Route path="/" element={<AuthGuard component={<Layout />} />}>
            <Route
              path="create-profile"
              element={<AuthGuard component={<Profile />} />}
            />
            <Route
              path="profile"
              element={<AuthGuard component={<ProfileMain />} />}
            />
            <Route
              path="profiles"
              element={<AuthGuard component={<Profiles />} />}
            />
            <Route
              path="dashboard"
              element={<AuthGuard component={<Dashboard />} />}
            />
            <Route
              path="settings"
              element={<AuthGuard component={<Settings />} />}
            />
            <Route
              path="content-manager"
              element={<AuthGuard component={<ContentManager />} />}
            />
            <Route
              path="add-component"
              element={<AuthGuard component={<DetailsComponent />} />}
            />
            <Route
              path="creator-profile"
              element={<AuthGuard component={<CreatorProfile />} />}
            />
            <Route
              path="expert-profile"
              element={<AuthGuard component={<ExpertProfile />} />}
            />
            <Route
              path="local-profile"
              element={<AuthGuard component={<LocalProfile />} />}
            />
            <Route path="hotel" element={<AuthGuard component={<Hotel />} />} />
            <Route
              path="hotel-detail"
              element={<AuthGuard component={<HotelDetail />} />}
            />
            <Route
              path="events"
              element={<AuthGuard component={<Events />} />}
            />
            <Route
              path="event-detail"
              element={<AuthGuard component={<EventDetail />} />}
            />
            <Route
              path="restaurants"
              element={<AuthGuard component={<Restaurants />} />}
            />
            <Route
              path="menu"
              element={<AuthGuard component={<MenuAll />} />}
            />
            <Route
              path="restaurant-detail"
              element={<AuthGuard component={<RestaurantDetail />} />}
            />
            <Route
              path="store-profile"
              element={<AuthGuard component={<StoreProfile />} />}
            />
            <Route
              path="store-detail"
              element={<AuthGuard component={<StoreDetail />} />}
            />
            <Route
              path="education-school-univercity"
              element={<AuthGuard component={<EducationSchoolUnivercity />} />}
            />
            <Route
              path="job-detail"
              element={<AuthGuard component={<JobDetail />} />}
            />
            <Route
              path="agency"
              element={<AuthGuard component={<Agency />} />}
            />
            <Route
              path="foundation"
              element={<AuthGuard component={<Foundation />} />}
            />
            <Route path="store" element={<AuthGuard component={<Store />} />} />
            <Route
              path="orders"
              element={<AuthGuard component={<Orders />} />}
            />
            <Route
              path="clients"
              element={<AuthGuard component={<Clients />} />}
            />
            <Route
              path="rating"
              element={<AuthGuard component={<Rating />} />}
            />
            <Route
              path="products"
              element={<AuthGuard component={<Products />} />}
            />
            <Route path="local" element={<AuthGuard component={<Locals />} />} />
            <Route
              path="hotels"
              element={<AuthGuard component={<Hotels />} />}
            />
            <Route
              path="restaurant"
              element={<AuthGuard component={<Restaurant />} />}
            />
            <Route path="event" element={<AuthGuard component={<Event />} />} />
            <Route path="ads" element={<AuthGuard component={<Ads />} />} />
            <Route
              path="service-center"
              element={<AuthGuard component={<ServiceCenter />} />}
            />
            <Route
              path="support"
              element={<AuthGuard component={<Support />} />}
            />
            <Route
              path="workspace"
              element={<AuthGuard component={<Workspace />} />}
            />
            <Route path="admin" element={<AuthGuard component={<Admin />} />} />
          </Route>
        </Routes>
      </div>
    </Router>
  )
}

export default App
