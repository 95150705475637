import React, { useEffect, useState } from 'react'
import './sidebar.css'
import { NavLink, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import GbnLogo from '../../images/GbnLogo.svg'
import Home from '../../images/home.svg'
import Profile from '../../images/profile.svg'
import Workspace from '../../images/workspace.svg'
import ContentManager from '../../images/contentmanager.svg'
import MyStore from '../../images/mystore.svg'
import Local from '../../images/local.svg'
import CloudStore from '../../images/cloudstore.svg'
import ADS from '../../images/ads.svg'
import ServiceCenter from '../../images/servicecenter.svg'
import Settings from '../../images/settings.svg'
import Logout from '../../images/logout.svg'
import Affiliate from '../../images/Affiliate.svg'
import RightIco from '../../images/RightIco.svg'


const Sidebar = () => {
  // const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()

  const isSideMenuDisable =
    !(localStorage.getItem('userData') &&
    JSON.parse(localStorage.getItem('userData')).user_type_id)
  const location = useLocation()
  console.log(location.pathname !== '/create-profile')

  // isSideMenuDisable ? navigate('/create-profile') : null
  if (isSideMenuDisable) {
    // navigate('/create-profile')
  }

  const [isOpen, setIsOpen] = useState(false)
  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const LogoutClick = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('userData')
    localStorage.removeItem('app_id')
    toast.success('User logged out successfully')
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 500)
  }, [])

  return (
    <div className="custom-sidebar">
      {isLoading ? (
        <>
          <Skeleton className="rounded-5 mb-3" height={54} width={193} />
          <ul className="main-navigation mt-4">
            <Skeleton className="rounded-5 mb-3" height={54} width={193} count={9} />
          </ul>
        </>
    ) : (
      <>
        <div className="logo">
          <img src={GbnLogo} alt="" />
        </div>
        <ul className="main-navigation">
          <li className={isSideMenuDisable ? 'disableSideMenu' : ''}>
            <NavLink to="/dashboard">
              <div>
                <div className="image-block">
                  <img src={Home} alt="" />
                </div>
                Home
              </div>
            </NavLink>
          </li>
          <li className={isSideMenuDisable ? 'disableSideMenu' : ''}>
            <NavLink to="/profiles">
              <div>
                <div className="image-block">
                  <img src={Profile} alt="" />
                </div>
                Profile
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/workspace">
              <div>
                <div className="image-block">
                  <img src={Workspace} alt="" />
                </div>
                Workspace
              </div>
            </NavLink>
          </li>
          <div className="saprator" />
          <li className={isSideMenuDisable ? 'disableSideMenu' : ''}>
            <NavLink to="/content-manager">
              <div>
                <div className="image-block">
                  <img src={ContentManager} alt="" />
                </div>
                Content Manager
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/store">
              <div>
                <div className="image-block">
                  <img src={MyStore} alt="" />
                </div>
                My Store
              </div>
            </NavLink>
          </li>
          <li className={isSideMenuDisable ? 'disableSideMenu' : ''}>
            <div className="d-flex align-items-center justify-content-between">
              <NavLink to="/local" className="w-100">
                <div className="position-relative">
                  <div className="image-block">
                    <img src={Local} alt="" />
                  </div>
                  Local
                  <div
                    onClick={toggleMenu}
                    className="cursor-pointer image-block position-absolute"
                    type="button"
                  >
                    <img src={RightIco} alt="" />
                  </div>
                </div>
              </NavLink>
            </div>
            {isOpen && (
            <ul className="custom-menu-options ms-4">
              <li>
                <NavLink to="/hotels">
                  <div>Hotels</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/restaurant">
                  <div>Restaurant</div>
                </NavLink>
              </li>
              <li>
                <NavLink to="/event">
                  <div>Events</div>
                </NavLink>
              </li>
            </ul>
          )}
          </li>
          <li className="disableSideMenu">
            <NavLink to="cloud-store">
              <div>
                <div className="image-block">
                  <img src={CloudStore} alt="" />
                </div>
                Cloud Store
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/ads">
              <div>
                <div className="image-block">
                  <img src={ADS} alt="" />
                </div>
                ADS
              </div>
            </NavLink>
          </li>
          <div className="saprator" />
          <li className="disableSideMenu">
            <NavLink to="/affiliate">
              <div>
                <div className="image-block">
                  <img src={Affiliate} alt="" />
                </div>
                Affiliate
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/service-center">
              <div>
                <div className="image-block">
                  <img src={ServiceCenter} alt="" />
                </div>
                Service Center
              </div>
            </NavLink>
          </li>
          <li className="disableSideMenu">
            <NavLink to="/settings">
              <div>
                <div className="image-block">
                  <img src={Settings} alt="" />
                </div>
                Settings
              </div>
            </NavLink>
          </li>
          <li className={isSideMenuDisable ? 'disableSideMenu' : ''}>
            <NavLink to="login" onClick={LogoutClick}>
              <div>
                <div className="image-block">
                  <img src={Logout} alt="" />
                </div>
                Logout
              </div>
            </NavLink>
          </li>
        </ul>
      </>
    ) }

    </div>
  )
}
export default Sidebar
