/* eslint-disable no-undef */
import { postData, ImagePostData, getData, uploadFileData, compressImage } from './http-services'
import EndPoints from './EndPoints'

const urlAuth = process.env.REACT_APP_URL_AUTH
const urlImageUpload = process.env.REACT_APP_URL_IMAGE_UPLOAD
const urlProfile = process.env.REACT_APP_URL_PROFILE
const urlStore = process.env.REACT_APP_URL_STORE
const urlCreator = process.env.REACT_APP_URL_CREATOR
const urlContentManager = process.env.REACT_APP_URL_CONTENT_MANAGER
const urlLocal = process.env.REACT_APP_URL_LOCAL
const urlImageUploadLocal = process.env.REACT_APP_URL_IMAGE_UPLOAD_LOCAL

export const s3ImagePath = process.env.REACT_APP_S3_IMAGE_PATH
export const s3ImagePathCreator = process.env.REACT_APP_S3_IMAGE_PATH_CREATOR
export const s3ImagePathLocal = process.env.REACT_APP_S3_IMAGE_PATH_LOCAL
export const locationSuggestion = process.env.REACT_APP_LOCATION_SUGGESTION

export const RegisterService = (data) => {
  return postData(`${urlAuth}${EndPoints.register}`, data)
}
export const OtpVerifications = (data) => {
  return postData(`${urlAuth}${EndPoints.otpVerification}`, data)
}

export const LoginService = (data) => {
  return postData(`${urlAuth}${EndPoints.login}`, data)
}
export const ResendOtp = (data) => {
  return postData(`${urlAuth}${EndPoints.resendOtp}`, data)
}

export const ForgotPassword = (data) => {
  return postData(`${urlAuth}${EndPoints.forgotPassword}`, data)
}

export const ResetPassword = (data) => {
  return postData(`${urlAuth}${EndPoints.resetPassword}`, data)
}

export const UserProfile = (data) => {
  return getData(`${urlProfile}${EndPoints.userProfile}`, data)
}
export const userUpdateProfile = (data) => {
  return postData(`${urlProfile}${EndPoints.userUpdateProfile}`, data)
}
export const createProfile = (data) => {
  return postData(`${urlProfile}${EndPoints.createProfile}`, data)
}

export const editAddonsData = (data) => {
  return postData(`${urlProfile}${EndPoints.editAddons}`, data)
}

// export const imageUpload = (data) => {
//   return ImagePostData(urlImageUpload, data);
// };

export const imageUpload = async (data) => {
  const compressedImage = await compressImage(data)
  return ImagePostData(urlImageUpload, compressedImage)
}

export const getUserTypes = () => {
  return getData(`${urlProfile}${EndPoints.userTypes}`)
}

export const getApplications = () => {
  return getData(`${urlProfile}${EndPoints.applications}`)
}

export const getAddOns = () => {
  return getData(`${urlProfile}${EndPoints.addons}`)
}
export const getOrganizationTypes = () => {
  return getData(`${urlProfile}${EndPoints.getOrganization}`)
}
export const getPlatform = (data) => {
  return getData(`${urlProfile}${EndPoints.applications}`, data)
}

export const getAppAddons = () => {
  return getData(`${urlProfile}${EndPoints.applicationsAddons}`)
}

export const profileDetails = (data) => {
  return postData(`${urlStore}${EndPoints.storeProfileDetails}`, data)
}

export const getBanner = (data) => {
  return postData(`${urlStore}${EndPoints.getBanner}`, data)
}

export const addEditBanner = (data) => {
  return postData(`${urlStore}${EndPoints.editBanner}`, data)
}

export const deleteBanner = (data) => {
  return postData(`${urlStore}${EndPoints.deleteBanner}`, data)
}
export const AddProductLibrary = (data) => {
  return postData(`${urlStore}${EndPoints.addProductLibrary}`, data)
}
export const getCategory = (data) => {
  return postData(`${urlStore}${EndPoints.category}`, data)
}
export const subCategoryAll = (data) => {
  return postData(`${urlStore}${EndPoints.subCategory}`, data)
}

export const showProductLibrary = (data, search) => {
  return postData(`${urlStore}${EndPoints.getProductLibrary}?search=${search}`, data)
}
export const deleteProductLibrary = (data) => {
  return postData(
    `${urlStore}${EndPoints.deleteProductLibrary}`,
    data
  )
}
export const editProductLibrary = (data) => {
  return postData(`${urlStore}${EndPoints.editProductLibrary}`, data)
}

export const addTrendingProduct = (data) => {
  return postData(
    `${urlStore}${EndPoints.addTrendingProduct}`,
    data
  )
}
export const getTrendingProduct = (data) => {
  return postData(`${urlStore}${EndPoints.getTrendingProduct}`, data)
}
export const removeTrendingProduct = (data) => {
  return postData(
    `${urlStore}${EndPoints.deleteTrendingProduct}`,
    data
  )
}

export const addGeneralProduct = (data) => {
  return postData(`${urlStore}${EndPoints.addGeneralProduct}`, data)
}
export const getGeneralProduct = (data, search) => {
  return postData(`${urlStore}${EndPoints.getGeneralProduct}?search=${search}`, data)
}
export const removeGeneralProduct = (data) => {
  return postData(
    `${urlStore}${EndPoints.deleteGeneralProduct}`,
    data
  )
}
export const editStoreProfile = (data) => {
  return postData(
    `${urlStore}${EndPoints.editStoreProfile}`,
    data
  )
}
export const productDetails = (data) => {
  return postData(
    `${urlStore}${EndPoints.productDetail}`,
    data
  )
}

export const imageUploadCreator = (data) => {
  return uploadFileData(`${urlCreator}${EndPoints.uploadImage}`, data)
}

export const creatorProfileDetails = (data) => {
  return postData(`${urlCreator}${EndPoints.creatorProfileDetails}`, data)
}

export const editCreatorProfile = (data) => {
  return postData(
    `${urlCreator}${EndPoints.editCreatorProfile}`,
    data
  )
}

export const getFeed = (data, page) => {
  return postData(`${urlCreator}${EndPoints.getFeed}?page=${page}`, data)
}
export const likeFeed = (data) => {
  return postData(`${urlCreator}${EndPoints.likeFeed}`, data)
}
export const saveFeed = (data) => {
  return postData(`${urlCreator}${EndPoints.saveFeed}`, data)
}
export const addPost = (data) => {
  return postData(`${urlCreator}${EndPoints.addPost}`, data)
}
export const editPost = (data) => {
  return postData(`${urlCreator}${EndPoints.editPost}`, data)
}
export const deletePost = (data) => {
  return postData(`${urlCreator}${EndPoints.deletePost}`, data)
}
export const getPost = (data) => {
  return postData(`${urlCreator}${EndPoints.getPost}`, data)
}
export const getStories = (data) => {
  return postData(`${urlCreator}${EndPoints.getStories}`, data)
}
export const deleteStories = (data) => {
  return postData(`${urlCreator}${EndPoints.deleteStories}`, data)
}
export const particularVideoGet = (data) => {
  return postData(`${urlContentManager}${EndPoints.particularVideo}`, data)
}
export const getVideosLibrary = (data) => {
  return postData(`${urlContentManager}${EndPoints.getVideoLibrary}`, data)
}
export const addVideoData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addVideo}`, data)
}
export const deleteVideoData = (data) => {
  return postData(`${urlContentManager}${EndPoints.deleteVideo}`, data)
}
export const editVideoData = (data) => {
  return postData(`${urlContentManager}${EndPoints.editVideo}`, data)
}
export const getPodcastLibrary = (data) => {
  return postData(`${urlContentManager}${EndPoints.getPodcastLibrary}`, data)
}
export const addPodcastData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addPodcast}`, data)
}
export const getHostList = (data) => {
  return postData(`${urlContentManager}${EndPoints.hostList}`, data)
}
export const deletePodcastData = (data) => {
  return postData(`${urlContentManager}${EndPoints.deletePodcast}`, data)
}
export const editPodcastData = (data) => {
  return postData(`${urlContentManager}${EndPoints.editPodcast}`, data)
}
export const getShotLibrary = (data) => {
  return postData(`${urlContentManager}${EndPoints.getShotLibrary}`, data)
}
export const addShotData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addShot}`, data)
}
export const deleteShotData = (data) => {
  return postData(`${urlContentManager}${EndPoints.deleteShot}`, data)
}
export const editShotData = (data) => {
  return postData(`${urlContentManager}${EndPoints.editShot}`, data)
}
export const getVideosData = (data) => {
  return postData(`${urlContentManager}${EndPoints.getVideos}`, data)
}
export const getShotsData = (data) => {
  return postData(`${urlContentManager}${EndPoints.getShots}`, data)
}
export const getPodcastsData = (data) => {
  return postData(`${urlContentManager}${EndPoints.getPodcasts}`, data)
}
export const videoUnpublishData = (data) => {
  return postData(`${urlContentManager}${EndPoints.videoUnpublish}`, data)
}
export const podcastUnpublishData = (data) => {
  return postData(`${urlContentManager}${EndPoints.podcastUnpublish}`, data)
}
export const shotUnpublishData = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotUnpublish}`, data)
}

export const getBrandList = (data) => {
  return postData(`${urlContentManager}${EndPoints.getBrand}`, data)
}
export const getTypeList = (data) => {
  return postData(`${urlContentManager}${EndPoints.getType}`, data)
}
export const addGiveawayData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addGiveaway}`, data)
}
export const getGiveawaysLibrary = (data) => {
  return postData(`${urlContentManager}${EndPoints.getGiveawayLibrary}`, data)
}
export const editGiveawayData = (data) => {
  return postData(`${urlContentManager}${EndPoints.editGiveaway}`, data)
}
export const deleteGiveawayData = (data) => {
  return postData(`${urlContentManager}${EndPoints.deleteGiveaway}`, data)
}
export const getGiveawaysData = (data) => {
  return postData(`${urlContentManager}${EndPoints.getGiveaways}`, data)
}
export const giveawayUnpublishData = (data) => {
  return postData(`${urlContentManager}${EndPoints.giveawayUnpublish}`, data)
}
export const giveawayParticipate = (data) => {
  return postData(`${urlContentManager}${EndPoints.giveawayParticipate}`, data)
}
export const giveawayParticipateListData = (data) => {
  return postData(`${urlContentManager}${EndPoints.giveawayParticipateList}`, data)
}

export const addAwardData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addAward}`, data)
}
export const getAwardsLibrary = (data) => {
  return postData(`${urlContentManager}${EndPoints.getAwardsLibrary}`, data)
}
export const editAwardData = (data) => {
  return postData(`${urlContentManager}${EndPoints.editAward}`, data)
}
export const deleteAwardData = (data) => {
  return postData(`${urlContentManager}${EndPoints.deleteAward}`, data)
}
export const getAwardsData = (data) => {
  return postData(`${urlContentManager}${EndPoints.getAwards}`, data)
}
export const awardsUnpublishData = (data) => {
  return postData(`${urlContentManager}${EndPoints.awardsUnpublish}`, data)
}

export const addPollData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addPoll}`, data)
}
export const editPollData = (data) => {
  return postData(`${urlContentManager}${EndPoints.editPoll}`, data)
}
export const deletePollData = (data) => {
  return postData(`${urlContentManager}${EndPoints.deletePoll}`, data)
}
export const getPollsLibrary = (data) => {
  return postData(`${urlContentManager}${EndPoints.getPollsLibrary}`, data)
}
export const getPollsData = (data) => {
  return postData(`${urlContentManager}${EndPoints.getPolls}`, data)
}
export const pollsUnpublishData = (data) => {
  return postData(`${urlContentManager}${EndPoints.pollsUnpublish}`, data)
}

export const getCategoryData = (data) => {
  return postData(`${urlCreator}${EndPoints.getCategory}`, data)
}
export const getSubCategoryData = (data) => {
  return postData(`${urlCreator}${EndPoints.getSubCategory}`, data)
}

export const addVoteData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addVote}`, data)
}
export const voterListData = (data) => {
  return postData(`${urlContentManager}${EndPoints.voterList}`, data)
}


export const addDiscussionData = (data) => {
  return postData(`${urlContentManager}${EndPoints.addDiscussion}`, data)
}
export const editDiscussionData = (data) => {
  return postData(`${urlContentManager}${EndPoints.editDiscussion}`, data)
}
export const deleteDiscussionData = (data) => {
  return postData(`${urlContentManager}${EndPoints.deleteDiscussion}`, data)
}
export const getDiscussionLibrary = (data) => {
  return postData(`${urlContentManager}${EndPoints.getDiscussionLibrary}`, data)
}
export const getDiscussionData = (data) => {
  return postData(`${urlContentManager}${EndPoints.getDiscussion}`, data)
}
export const discussionUnpublishData = (data) => {
  return postData(`${urlContentManager}${EndPoints.discussionUnpublish}`, data)
}
export const discussionReplyData = (data) => {
  return postData(`${urlContentManager}${EndPoints.discussionReply}`, data)
}
export const discussionAddReplyData = (data) => {
  return postData(`${urlContentManager}${EndPoints.discussionAddReply}`, data)
}
export const discussionEditReplyData = (data) => {
  return postData(`${urlContentManager}${EndPoints.discussionEditReply}`, data)
}
export const discussionDeleteReplyData = (data) => {
  return postData(`${urlContentManager}${EndPoints.discussionDeleteReply}`, data)
}
export const discussionLikeData = (data) => {
  return postData(`${urlContentManager}${EndPoints.discussionReplyLike}`, data)
}


export const videoViewCountData = (data) => {
  return postData(`${urlContentManager}${EndPoints.videoViewCount}`, data)
}
export const shotViewCountData = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotViewCount}`, data)
}

export const shotCommentData = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotComment}`, data)
}
export const shotCommentAddData = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotCommentAdd}`, data)
}
export const shotCommentEditData = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotCommentEdit}`, data)
}
export const shotCommentDeleteData = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotCommentDelete}`, data)
}
export const shotCommentLikeData = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotCommentLike}`, data)
}
export const shotSave = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotSave}`, data)
}

export const shotLike = (data) => {
  return postData(`${urlContentManager}${EndPoints.shotLike}`, data)
}
export const giveawayLike = (data) => {
  return postData(`${urlContentManager}${EndPoints.giveawayLike}`, data)
}
export const pollLike = (data) => {
  return postData(`${urlContentManager}${EndPoints.pollLike}`, data)
}
export const discussionLike = (data) => {
  return postData(`${urlContentManager}${EndPoints.discussionLike}`, data)
}
export const videoLike = (data) => {
  return postData(`${urlContentManager}${EndPoints.videoLike}`, data)
}
export const podcastLike = (data) => {
  return postData(`${urlContentManager}${EndPoints.podcastLike}`, data)
}


export const videoCommentData = (data) => {
  return postData(`${urlContentManager}${EndPoints.videoComment}`, data)
}
export const videoCommentAddData = (data) => {
  return postData(`${urlContentManager}${EndPoints.videoCommentAdd}`, data)
}
export const videoCommentEditData = (data) => {
  return postData(`${urlContentManager}${EndPoints.videoCommentEdit}`, data)
}
export const videoCommentDeleteData = (data) => {
  return postData(`${urlContentManager}${EndPoints.videoCommentDelete}`, data)
}
export const videoCommentLikeData = (data) => {
  return postData(`${urlContentManager}${EndPoints.videoCommentLike}`, data)
}


export const feedCommentData = (data) => {
  return postData(`${urlCreator}${EndPoints.feedComment}`, data)
}
export const feedCommentAddData = (data) => {
  return postData(`${urlCreator}${EndPoints.feedCommentAdd}`, data)
}
export const feedCommentEditData = (data) => {
  return postData(`${urlCreator}${EndPoints.feedCommentEdit}`, data)
}
export const feedCommentDeleteData = (data) => {
  return postData(`${urlCreator}${EndPoints.feedCommentDelete}`, data)
}
export const feedCommentLikeData = (data) => {
  return postData(`${urlCreator}${EndPoints.feedCommentLike}`, data)
}
export const feedCommentReplyData = (data) => {
  return postData(`${urlCreator}${EndPoints.feedCommentReply}`, data)
}


export const getReportReasons = (data) => {
  return postData(`${urlCreator}${EndPoints.getReportReasons}`, data)
}
export const feedReportData = (data) => {
  return postData(`${urlCreator}${EndPoints.feedReport}`, data)
}

export const followerListData = (data) => {
  return postData(`${urlCreator}${EndPoints.followerList}`, data)
}
export const followCreator = (data) => {
  return postData(`${urlCreator}${EndPoints.followCreator}`, data)
}
export const creatorList = (data) => {
  return postData(`${urlCreator}${EndPoints.creatorList}`, data)
}

// local section

export const imageUploadLocal = (data) => {
  return uploadFileData(`${urlImageUploadLocal}`, data)
}

export const countryList = () => {
  return getData(`${urlLocal}${EndPoints.countryList}`)
}
export const provinceList = (data) => {
  return postData(`${urlLocal}${EndPoints.provinceList}`, data)
}
export const cityList = (data) => {
  return postData(`${urlLocal}${EndPoints.cityList}`, data)
}


export const hotelAmenitiesList = (data) => {
  return postData(`${urlLocal}${EndPoints.hotelAmenities}`, data)
}
export const hotelLocationList = (data) => {
  return postData(`${urlLocal}${EndPoints.hotelLocation}`, data)
}


export const HotelPublish = (data) => {
  return postData(`${urlLocal}${EndPoints.publishHotel}`, data)
}

export const allHotel = (data, page) => {
  return postData(`${urlLocal}${EndPoints.getHotel}?page=${page}`, data)
}
export const hotelAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.addHotel}`, data)
}
export const hotelEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.editHotel}`, data)
}
export const hotelDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.deleteHotel}`, data)
}
export const particularHotelDetails = (data) => {
  return postData(`${urlLocal}${EndPoints.particularHotel}`, data)
}
export const hotelLike = (data) => {
  return postData(`${urlLocal}${EndPoints.hotelLike}`, data)
}

export const hotelContactAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.hotelContactAdd}`, data)
}
export const hotelContactEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.hotelContactEdit}`, data)
}
export const hotelContactDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.hotelContactDelete}`, data)
}


export const typeofRoomList = (data) => {
  return postData(`${urlLocal}${EndPoints.typeofRoom}`, data)
}
export const hotelRoomGet = (data, page) => {
  return postData(`${urlLocal}${EndPoints.getRoom}?page=${page}`, data)
}
export const roomAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.addRoom}`, data)
}
export const roomEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.editRoom}`, data)
}
export const roomDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.deleteRoom}`, data)
}
export const particularRoomDetails = (data) => {
  return postData(`${urlLocal}${EndPoints.particularRoom}`, data)
}


export const hotelDiscountGet = (data, page) => {
  return postData(`${urlLocal}${EndPoints.getDiscount}?page=${page}`, data)
}
export const DiscountAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.addDiscount}`, data)
}
export const DiscountEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.editDiscount}`, data)
}
export const DiscountDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.deleteDiscount}`, data)
}
export const particularDiscountDetails = (data) => {
  return postData(`${urlLocal}${EndPoints.particularDiscount}`, data)
}


// restaurant

export const restaurantLocationList = (data) => {
  return postData(`${urlLocal}${EndPoints.restaurantLocation}`, data)
}

export const RestaurantPublish = (data) => {
  return postData(`${urlLocal}${EndPoints.publishRestaurant}`, data)
}

export const allRestaurant = (data, page) => {
  return postData(`${urlLocal}${EndPoints.getRestaurant}?page=${page}`, data)
}
export const restaurantAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.addRestaurant}`, data)
}
export const restaurantEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.editRestaurant}`, data)
}
export const restaurantDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.deleteRestaurant}`, data)
}
export const particularRestaurantDetails = (data) => {
  return postData(`${urlLocal}${EndPoints.particularRestaurant}`, data)
}
export const restaurantLike = (data) => {
  return postData(`${urlLocal}${EndPoints.restaurantLike}`, data)
}

export const restaurantContactAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.restaurantContactAdd}`, data)
}
export const restaurantContactEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.restaurantContactEdit}`, data)
}
export const restaurantContactDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.restaurantContactDelete}`, data)
}


export const typeofMealList = (data) => {
  return postData(`${urlLocal}${EndPoints.typeofMeal}`, data)
}
export const cuisinesList = (data) => {
  return postData(`${urlLocal}${EndPoints.menuCuisines}`, data)
}
export const RestaurantMenuGet = (data, page) => {
  return postData(`${urlLocal}${EndPoints.getMenu}?page=${page}`, data)
}
export const menuAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.addMenu}`, data)
}
export const menuEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.editMenu}`, data)
}
export const menuDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.deleteMenu}`, data)
}
export const particularMenuDetails = (data) => {
  return postData(`${urlLocal}${EndPoints.particularMenu}`, data)
}

export const resDiscountGet = (data, page) => {
  return postData(`${urlLocal}${EndPoints.resDiscountGet}?page=${page}`, data)
}
export const resDiscountAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.resDiscountAdd}`, data)
}
export const resDiscountEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.resDiscountEdit}`, data)
}
export const resDiscountDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.resDiscountDelete}`, data)
}
export const resParticularDiscountDetails = (data) => {
  return postData(`${urlLocal}${EndPoints.resDiscountParticular}`, data)
}

// event

export const organizerLocationList = (data) => {
  return postData(`${urlLocal}${EndPoints.organizerLocation}`, data)
}
export const OrganizerPublish = (data) => {
  return postData(`${urlLocal}${EndPoints.publishOrganizer}`, data)
}
export const allOrganizer = (data, page) => {
  return postData(`${urlLocal}${EndPoints.getOrganizer}?page=${page}`, data)
}
export const organizerAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.addOrganizer}`, data)
}
export const organizerEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.editOrganizer}`, data)
}
export const organizerDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.deleteOrganizer}`, data)
}
export const particularOrganizerDetails = (data) => {
  return postData(`${urlLocal}${EndPoints.particularOrganizer}`, data)
}

export const organizerContactAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.organizerContactAdd}`, data)
}
export const organizerContactEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.organizerContactEdit}`, data)
}
export const organizerContactDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.organizerContactDelete}`, data)
}


export const performerTypeList = (data) => {
  return postData(`${urlLocal}${EndPoints.performerType}`, data)
}
export const industryTypeList = (data) => {
  return postData(`${urlLocal}${EndPoints.industryType}`, data)
}
export const eventGet = (data, page) => {
  return postData(`${urlLocal}${EndPoints.getEvent}?page=${page}`, data)
}
export const eventAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.addEvent}`, data)
}
export const eventEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.editEvent}`, data)
}
export const eventDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.deleteEvent}`, data)
}
export const ParticularEventDetails = (data) => {
  return postData(`${urlLocal}${EndPoints.particularEvent}`, data)
}

export const eventPerformerAdd = (data) => {
  return postData(`${urlLocal}${EndPoints.eventPerformerAdd}`, data)
}
export const eventPerformerEdit = (data) => {
  return postData(`${urlLocal}${EndPoints.eventPerformerEdit}`, data)
}
export const eventPerformerDelete = (data) => {
  return postData(`${urlLocal}${EndPoints.eventPerformerDelete}`, data)
}
