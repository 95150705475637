/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import SideBarPage from './SidebarPage'
import { LoginService, RegisterService } from '../../services/Service'
import eyeoff from '../../images/eye-off.svg'
import eyeon from '../../images/eye-on.svg'
import '../../App.css'

import { dataDecrypt } from '../../services/http-services'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const SignUpPage = () => {
  const navigate = useNavigate()

  const [showPassword1, setShowPassword1] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [inputFields, setInputFields] = useState({
    user_name: '',
    email: '',
    mobile_no: '',
    password: '',
    confirm_password: '',
    app_id: 2
  })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1)
  }

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2)
  }

  const isEmailValid = (value) => {
    const reg = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w+)+$/
    return !value.trim() || !reg.test(value.trim())
  }

  const validateValues = (inputValues) => {
    const errors = {}
    if (!inputValues.user_name.match(/^[ A-Za-z0-9_@./#&+-]*$/)) {
      errors.user_name = 'Enter Valid user name'
    }
    if (
      !inputValues.email.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      errors.email = 'Enter Valid email'
    }
    const regexMobile = /^\d{10}$/
    if (!inputValues.mobile_no.match(regexMobile)) {
      errors.mobile_no = 'Number should be 10 digit'
    }
    const regexPass =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\|[\]{};:/?.>])[A-Za-z\d!@#$%^&*()\-_=+\|[\]{};:/?.>]{8,}$/
    if (!inputValues.password.match(regexPass)) {
      errors.password =
        'Minimum eight characters, at least one letter, one number and one special character'
    }
    if (inputValues.password !== inputValues.confirm_password) {
      errors.confirm_password = 'Password miss match'
    }

    return errors
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setInputFields((prevFields) => {
      const updatedFields = { ...prevFields, [name]: value }

      const updatedErrors = { ...errors }

      if (name === 'password') {
        if (
          updatedFields.password.match(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\|[\]{};:/?.>])[A-Za-z\d!@#$%^&*()\-_=+\|[\]{};:/?.>]{8,}$/
          )
        ) {
          delete updatedErrors.password
        }
        if (updatedFields.password === updatedFields.confirm_password) {
          delete updatedErrors.confirm_password
        }
      }

      if (name === 'confirm_password') {
        if (updatedFields.password === updatedFields.confirm_password) {
          delete updatedErrors.confirm_password
        } else {
          updatedErrors.confirm_password = 'Password mismatch'
        }
      }

      setErrors(updatedErrors)
      return updatedFields
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const validationErrors = validateValues(inputFields)
    setErrors(validationErrors)
    if (Object.keys(validationErrors).length === 0) {
      setSubmitting(true)
    }
  }

  const loginApi = () => {
    const data = {
      email: inputFields.email,
      password: inputFields.password,
      app_id: 2,
      is_gbn: false
    }
    LoginService(data).then((res) => {
      if (res.data.success) {
        const data = dataDecrypt(res.data.data)
        console.log(data, 'dataLogin After Register')
        localStorage.setItem(
          'loginData',
          JSON.stringify(dataDecrypt(res.data.data))
        )
        navigate('/otp-verification')
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  useEffect(() => {
    if (submitting) {
      setLoading(true)
      RegisterService(inputFields)
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data, 'registerPage')
            localStorage.setItem('token', res.data.token)
            console.log('token', res.data.token)
            localStorage.setItem(
              'userData',
              JSON.stringify(dataDecrypt(res.data.data))
            )
            loginApi()
            navigate('/otp-verification')
            setLoading(false)
          } else {
            toast.error(res?.data?.message)
            setLoading(false)
          }
          setSubmitting(false)
        })
        .catch(() => {
          toast.error('An error occurred. Please try again.')
          setSubmitting(false)
        })
    }
  }, [submitting, inputFields, navigate])

  useEffect(() => {
    setIsFormValid(
      inputFields.user_name.length > 0 &&
        inputFields.email.length > 0 &&
        inputFields.mobile_no.length > 0 &&
        inputFields.password.length > 0 &&
        inputFields.confirm_password.length > 0
    )
  }, [inputFields])

  return (
    <div className=" auth-register">
      <div className="bg-overlay row overlay-height">
        <div className="col-md-6 bg-primary-gredient">
          <SideBarPage />
        </div>
        <div className="col-md-6 h-100 overflow-y-auto">
          <div className="inline-padding">
            <h2>WELCOME TO APP WORLD ONE BUSINESS!</h2>
            <p className="sub-txt">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictumst
              sagittis vitae lobortis a ultrices porttitor. Hendrerit massa,
              erat bibendum elementum tempor. Consequat egestas massa rhoncus
              odio scelerisque amet.
              {' '}
            </p>
            <form onSubmit={handleSubmit}>
              <p>
                <label className="clip-text">APP WORLD ONE Username *</label>
                <div className="user-input">
                  <CommonInput
                    name="user_name"
                    required
                    placeholder="User Name"
                    value={inputFields.user_name}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      if (e.key === ' ') {
                        e.preventDefault()
                      }
                    }}
                  />
                </div>
                {errors.user_name ? (
                  <p className="error-text">{errors.user_name}</p>
                ) : null}
              </p>
              <p>
                <label className="clip-text">Email *</label>
                <div className="email-input">
                  <CommonInput
                    type="email"
                    name="email"
                    placeholder="e.g. abc123_xyz@gmail.com"
                    value={inputFields.email}
                    onChange={(e) => {
                      const updatedErrors = { ...errors }

                      if (!isEmailValid(e.target.value.split(' ').join(''))) {
                        setInputFields({ ...inputFields, email: '' })
                      }
                      if (isEmailValid(e.target.value.split(' ').join(''))) {
                        updatedErrors.email = 'Enter a valid email'
                      } else {
                        delete updatedErrors.email
                      }
                      setInputFields({
                        ...inputFields,
                        email: e.target.value.split(' ').join('')
                      })
                      setErrors(updatedErrors)
                    }}
                    required
                  />
                </div>
                {errors.email ? (
                  <p className="error-text">{errors.email}</p>
                ) : null}
              </p>
              <p>
                <label className="clip-text">Phone Number *</label>
                <div className="number-input">
                  <CommonInput
                    name="mobile_no"
                    placeholder="e.g. 2154-457-548"
                    required
                    value={inputFields.mobile_no}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setInputFields({
                          ...inputFields,
                          mobile_no: e.target.value?.replace(/[^0-9]/g, '')
                        })
                      }
                      if (e.target.value.length == 10) {
                        setErrors({
                          ...errors,
                          mobile_no: ''
                        })
                      }
                    }}
                  />
                </div>
                {errors.mobile_no ? (
                  <p className="error-text">{errors.mobile_no}</p>
                ) : null}
              </p>
              <p>
                <label className="clip-text">Password *</label>
                <div className="password-input">
                  <CommonInput
                    type={showPassword1 ? 'text' : 'password'}
                    name="password"
                    placeholder="*********************"
                    required
                    value={inputFields.password}
                    onChange={(e) => {
                      const updatedErrors = { ...errors }
                      const passwordValue = e.target.value
                      if (
                        !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+\|[\]{};:/?.>])[A-Za-z\d!@#$%^&*()\-_=+\|[\]{};:/?.>]{8,}$/.test(
                          passwordValue
                        )
                      ) {
                        updatedErrors.password =
                          'Minimum eight characters, at least one letter and also capital, one number and one special character'
                      } else {
                        delete updatedErrors.password
                      }
                      setInputFields({
                        ...inputFields,
                        password: passwordValue
                      })
                      setErrors(updatedErrors)
                    }}
                  />
                  <button onClick={togglePasswordVisibility1} type="button">
                    {showPassword1 ? (
                      <img alt="" src={eyeon} />
                    ) : (
                      <img alt="" src={eyeoff} />
                    )}
                  </button>
                </div>
                {errors.password ? (
                  <p className="error-text">{errors.password}</p>
                ) : null}
              </p>
              <p>
                <label className="clip-text">Re-enter Password *</label>
                <div className="password-input">
                  <CommonInput
                    type={showPassword2 ? 'text' : 'password'}
                    required
                    name="confirm_password"
                    placeholder="*********************"
                    value={inputFields.confirm_password}
                    onChange={handleChange}
                  />
                  <button onClick={togglePasswordVisibility2} type="button">
                    {showPassword2 ? (
                      <img alt="" src={eyeon} />
                    ) : (
                      <img alt="" src={eyeoff} />
                    )}
                  </button>
                </div>
                {errors.confirm_password ? (
                  <p className="error-text">{errors.confirm_password}</p>
                ) : null}
              </p>
              <p>
                {/* <Link to="/user-information"> */}
                <CommonButton
                  id="sub_btn"
                  label="Create Account"
                  loading={loading}
                  type="submit"
                  className="primary-button m-auto custom-mt"
                  disabled={!isFormValid || loading}
                  context="primary"
                  isPrimary
                />
                {/* </Link> */}
              </p>
            </form>
            <footer>
              <p>
                Already have a business Account?
                {' '}
                <Link to="/login">Log in</Link>
              </p>
            </footer>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SignUpPage
