/* eslint-disable no-nested-ternary */
import React from 'react'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import UploadImg from '../../images/upload.svg'
import CommonButton from '../ButtonComponent/ButtonCommon'

const CommonImageUpload = ({
  handleImageChange,
  index,
  buttonText,
  forImage,
  forName,
  className,
  accept = '.jpg,.jpeg,.png',
  selectedImg,
  defaultImg,
  defaultImgCamera,
  name,
  ref,
  id
}) => {
  return (
    <ImgCrop showGrid rotationSlider aspectSlider showReset>
      <Upload
        id={id}
        ref={ref}
        name={name}
        accept={accept}
        showUploadList={false}
        beforeUpload={(file) => {
          handleImageChange(file, index)
          return false
        }}
      >
        <label htmlFor={`input-img-${index}`} className={className}>
          {selectedImg ? (
            forImage === 'profile' ? (
              <>
                <img className="Profile_img" src={selectedImg} alt="Profile" />
                <div className="input-profile-img-label cursor-pointer">
                  <img src={defaultImgCamera} alt="Upload Icon" />
                </div>
              </>
            ) : forImage === 'create-profile' ? (
              <>
                <img className="Profile_img" src={selectedImg} alt="Profile" />
                <div className="input-profile-img-label cursor-pointer">
                  <img src={defaultImgCamera} alt="Upload Icon" />
                </div>
              </>
            ) : forImage === 'local_logo' ? (
              <>
                <img
                  className="logo_show_img"
                  src={selectedImg}
                  alt="Preview"
                />
                <div className="edit_logo_local z-1">
                  <label htmlFor="upload-file">
                    <button type="button" className="d-none" />
                  </label>
                </div>
              </>
            ) : forImage === 'local_photo' ? (
              <>
                <img
                  className="logo_show_imgs"
                  src={selectedImg}
                  alt="Preview"
                />
                <div className="edit_logo_local z-1">
                  <label htmlFor="upload-file">
                    <button type="button" className="d-none" />
                  </label>
                </div>
              </>
            ) : (
              <div className="text-center image_view">
                <img src={selectedImg} alt="Uploaded" />
                <button
                  type="button"
                  className="edit-button-model content-meneger-edit"
                />
              </div>
            )
          ) : forName ? (
            <div className="upload-div mb-3">
              <div className="upload-img">
                <img src={UploadImg} alt="Upload Icon" />
              </div>
              {forImage === 'video' ? (
                <h4>Upload Video Cover</h4>
              ) : forImage === 'image' ? (
                <h4>Upload Photo</h4>
              ) : forImage === 'Hotel' ? (
                <h4>UPLOAD PICTURE(S)</h4>
              ) : (
                <h4>Upload Podcast Cover</h4>
              )}
              <p className="drag-drop-text">
                Drag and drop your file or click the button below to upload
              </p>
              <CommonButton
                label={buttonText}
                htmlFor={`input-img-${index}`}
                type="button"
                className="upload_btn_width"
              />
              {/* <button type="button" htmlFor={`input-img-${index}`}>
                <span>{buttonText}</span>
              </button> */}
            </div>
          ) : forImage === 'profile' ? (
            <div className="cropper-user">
              <img src={defaultImg} alt="uploading" />
              <div className="input-profile-img-label cursor-pointer">
                <img src={defaultImgCamera} alt="Upload Icon" />
              </div>
            </div>
          ) : forImage === 'create-profile' ? (
            <div className="cropper-user">
              <img className="default" src={defaultImg} alt="uploading" />
              <div className="input-profile-img-label cursor-pointer">
                <img src={defaultImgCamera} alt="" />
              </div>
            </div>
          ) : forImage === 'local_logo' ? (
            <div className="cropper-user">
              <img src={defaultImg} alt="" />
              <h6>Add Logo</h6>
            </div>
          ) : forImage === 'local_photo' ? (
            <div className="cropper-user">
              <img src={defaultImg} alt="" />
              <h6>Add Photo</h6>
            </div>
          ) : (
            <label htmlFor="input-img" className={className}>
              <img src={defaultImg} alt="uploading" />
              <p className="m-0">{buttonText}</p>
            </label>
          )}
        </label>
      </Upload>

    </ImgCrop>
  )
}

export default CommonImageUpload
