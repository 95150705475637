/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import Dropdown from '../Dropdown/Dropdown'
import Play from '../../images/play.svg'
// import RatingStar from "../../images/rating-start.svg";
import UploadImg from '../../images/upload.svg'
import AddIco from '../../images/AddIco.svg'
import ClockFillIco from '../../images/ClockFillIco.svg'
import PodcastPlayIco from '../../images/PodcastPlayIco.svg'
import PodcastPauseIco from '../../images/PodcastPauseIco.svg'
import { compressImage, dataDecrypt } from '../../services/http-services'
import {
  addPodcastData,
  deletePodcastData,
  editPodcastData,
  getHostList,
  getPodcastLibrary,
  imageUploadCreator,
  s3ImagePath,
  s3ImagePathCreator
} from '../../services/Service'
import PlatformTag from './PlatformTag'
import Plus from '../../images/plus-gredient.svg'
import Loader from '../Loader/Loader'
import CloseIco from '../../images/CloseIco.svg'
import {
  capitalizeFirstLetter,
  debounce,
  formatDurationPodcast,
  timeDifference
} from '../../helpers/Utility'
import Pagination from '../Pagination/Pagination'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const PodcastDetails = () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  const audioRefs = useRef({})
  const componentRef = useRef()
  const audioRef = useRef(null)
  const [ShowPodcastModel, setShowPodcastModel] = useState(false)
  const [ShowPodcastCoverModel, setShowPodcastCoverModel] = useState(false)
  const [ShowPodcastPreviewModel, setShowPodcastPreviewModel] = useState(false)
  const [podcastData, setPodcastData] = useState([])
  const [inputFields, setInputFields] = useState({
    title: '',
    description: '',
    minutes: '',
    attachment: '',
    cover_attachment: '',
    app_id: [],
    host_id: 0,
    search: ''
  })
  const [selectedFilePath, setSelectedFilePath] = useState('')
  const [selectedAudioName, setSelectedAudioName] = useState('')
  const [selectedFileObj, setSelectedFileObj] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState('')
  const [selectedVideoObj, setSelectedVideoObj] = useState('')
  const [hostName, setHostName] = useState([])
  const [Platformtags, setPlatformTags] = useState([])
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [delPodcastId, setDelPodcastId] = useState()
  const [loading, setLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [previewModel, setPreviewModel] = useState(false)
  const [plays, setPlays] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [initialPlatformTags, setInitialPlatformTags] = useState([])
  const [initialDescription, setInitialDescription] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hoveredIndex, setHoveredIndex] = useState(null)
  const [isPlaying, setIsPlaying] = useState({})

  const AddPodcast = () => {
    setShowPodcastModel(true)
  }
  const AddPodcastCover = () => {
    setShowPodcastCoverModel(true)
    setShowPodcastModel(false)
  }
  const AddPodcastPreview = () => {
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(true)
  }

  const HandleClose = () => {
    clearValue()
    setPreviewModel(false)
    setShowPodcastCoverModel(false)
    setShowPodcastModel(false)
    setShowPodcastPreviewModel(false)
    setIsEditing(false)
  }
  const handleCancel = () => {
    clearValue()
    setShowConfirmModel(false)
  }
  const HandleCancelCover = () => {
    setShowPodcastModel(true)
    setShowPodcastCoverModel(false)
  }
  const HandleCancelPreview = () => {
    setShowPodcastPreviewModel(false)
    setShowPodcastCoverModel(true)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }
  const isAppsSelected = inputFields.app_id?.length > 0

  const clearValue = () => {
    setSelectedFilePath('')
    setSelectedFileObj('')
    setSelectedVideo('')
    setSelectedVideoObj('')
    setSelectedAudioName('')
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      title: '',
      description: '',
      minutes: '',
      attachment: '',
      cover_attachment: '',
      app_id: [],
      host_id: 0
    })
  }

  const ShowConfirm = (videoId) => {
    setDelPodcastId(videoId)
    setShowConfirmModel(true)
  }

  const togglePlayPauses = () => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play()
        setPlays(true)
      } else {
        audioRef.current.pause()
        setPlays(false)
      }
    }
  }

  const getCategoryName = (categoryId) => {
    const categoryObj = hostName.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.host : 'Select Host'
  }

  const getPodcastLibraryData = (search) => {
    setIsLoading(true)
    const data = {
      search
    }
    getPodcastLibrary(data).then((res) => {
      if (res.data.success) {
        setPodcastData(dataDecrypt(res.data.data).data)
        setIsLoading(false)
      }
    })
  }

  const handleSearch = useCallback(
    debounce((inputVal) => getPodcastLibraryData(inputVal), 500),
    []
  )

  const addPodcastApiCall = () => {
    addPodcastData(inputFields).then((res) => {
      if (res.data.success) {
        setShowPodcastPreviewModel(false)
        clearValue()
        toast.success(res?.data?.message)
        getPodcastLibraryData()
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const handlePublishClick = async () => {
    const durationInSeconds = Math.trunc(audioRef.current.duration)
    const formattedDuration = formatDurationPodcast(durationInSeconds)

    inputFields.minutes = formattedDuration

    setLoading(true)

    try {
      if (selectedFileObj) {
        const imageFile = await compressImage(selectedFileObj)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'podcast')

        const coverUploadResponse = await imageUploadCreator(coverFormData)
        if (!coverUploadResponse.data.success) {
          throw new Error(
            coverUploadResponse.data.message || 'Cover image upload failed'
          )
        }
        const decryptedCoverData = dataDecrypt(coverUploadResponse.data.data)
        inputFields.cover_attachment = decryptedCoverData
      }

      if (!isEditing || selectedVideoObj) {
        const videoFormData = new FormData()
        videoFormData.append('file', selectedVideoObj)
        videoFormData.append('type', 'podcast')

        const videoUploadResponse = await imageUploadCreator(videoFormData)
        if (!videoUploadResponse.data.success) {
          throw new Error(
            videoUploadResponse.data.message || 'Video upload failed'
          )
        }
        const decryptedVideoData = dataDecrypt(videoUploadResponse.data.data)
        inputFields.attachment = decryptedVideoData
      }

      if (isEditing) {
        await editPodcastApiCall()
      } else {
        await addPodcastApiCall()
      }

      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message || 'An error occurred')
    }
  }

  // const handleFileChange = (event) => {
  //   const fileInput = event.target
  //   const file = fileInput.files[0]
  //   if (file) {
  //     setSelectedFileObj(file)
  //     const reader = new FileReader()

  //     reader.onload = (e) => {
  //       setSelectedFilePath(e.target.result)
  //     }
  //     reader.readAsDataURL(file)
  //   }
  // }

  const handleFileChange = (file) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      setSelectedFilePath(e.target.result)
      setSelectedFileObj(file)
    }
    reader.readAsDataURL(file)
  }

  const handleVideoChange = (event) => {
    const fileInput = event.target
    const selectedFile = fileInput.files[0]

    if (selectedFile) {
      setSelectedVideoObj(selectedFile)
      setSelectedAudioName(selectedFile.name)
      const videoURL = URL.createObjectURL(selectedFile)
      setSelectedVideo(videoURL)
      console.log('===============', selectedFile)
    }
  }

  const podcastHostApiCall = () => {
    getHostList({}).then((res) => {
      if (res.data.success) {
        setHostName(dataDecrypt(res.data.data))
      }
    })
  }

  const deletePodcast = () => {
    const data = {
      podcast_id: delPodcastId
    }
    setLoading(true)
    deletePodcastData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        getPodcastLibraryData()
        setLoading(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const editPodcastApiCall = () => {
    const data = {
      title: inputFields.title,
      description: inputFields.description,
      attachment: inputFields.attachment,
      cover_attachment: inputFields.cover_attachment,
      host_id: inputFields.host_id,
      app_id: Platformtags.map((d) => d.id),
      podcast_id: inputFields.podcast_id
    }
    editPodcastData(data).then((res) => {
      if (res.data.success) {
        setShowConfirmModel(false)
        setShowPodcastPreviewModel(false)
        setPreviewModel(false)
        clearValue()
        getPodcastLibraryData()
        setIsEditing(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const onClickEditPodcast = (videoData) => {
    setShowPodcastModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePathCreator + videoData.attachment)
    setSelectedFilePath(s3ImagePathCreator + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
    setInitialValues({
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id,
      search: '',
      minutes: ''
    })
    setPlatformTags(videoData.app_data)
  }

  const handleItemClick = (videoData) => {
    setPreviewModel(true)
    setIsEditing(true)
    setSelectedVideo(s3ImagePathCreator + videoData.attachment)
    setSelectedFilePath(s3ImagePathCreator + videoData.cover_attachment)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      description: videoData.description,
      duration: videoData.duration,
      attachment: videoData.attachment,
      cover_attachment: videoData.cover_attachment,
      app_id: videoData.app_data,
      host_id: videoData.host_id,
      podcast_id: videoData.podcast_id
    })
    setPlatformTags(videoData.app_data)
    setInitialPlatformTags(videoData.app_data)
    setInitialDescription(videoData.description)
  }

  const validateForm = () => {
    const { title, host_id, description } = inputFields
    if (title && host_id && description && selectedVideo) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  // podcasts pagination

  const podcastPerPage = 11
  const indexOfLastVideo = currentPage * podcastPerPage
  const indexOfFirstVideo = indexOfLastVideo - podcastPerPage
  const podcastDataAll = podcastData.slice(indexOfFirstVideo, indexOfLastVideo)
  const totalVideos = podcastData.length
  const totalPages = Math.ceil(totalVideos / podcastPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  // hover play pause

  useEffect(() => {
    return () => {
      Object.keys(audioRefs.current).forEach((index) => {
        const audio = audioRefs.current[index]
        if (audio) {
          audio.pause()
          audio.src = ''
        }
      })
    }
  }, [])

  const handleMouseEnter = (index, attachment) => {
    setHoveredIndex(index)

    if (!audioRefs.current[index]) {
      const newAudio = new Audio(attachment)
      newAudio.onerror = (e) => {
        console.error('Audio playback error:', e)
      }
      audioRefs.current[index] = newAudio
    }

    audioRefs.current[index]
      .play()
      .then(() => {
        setIsPlaying((prev) => ({ ...prev, [index]: true }))
      })
      .catch((error) => {
        console.error('Error playing audio:', error)
      })
  }

  const handleMouseLeave = (index) => {
    setHoveredIndex(null)

    if (audioRefs.current[index]) {
      audioRefs.current[index].pause()
      audioRefs.current[index].currentTime = 0
      setIsPlaying((prev) => ({ ...prev, [index]: false }))
    }
  }

  const togglePlayPause = (index) => {
    const audio = audioRefs.current[index]

    if (audio) {
      if (audio.paused) {
        audio
          .play()
          .then(() => {
            setIsPlaying((prev) => ({ ...prev, [index]: true }))
          })
          .catch((error) => {
            console.error('Error playing audio:', error)
          })
      } else {
        audio.pause()
        setIsPlaying((prev) => ({ ...prev, [index]: false }))
      }
    }
  }

  const playVolumeIcon = (index) => (
    <img
      src={isPlaying[index] ? PodcastPauseIco : PodcastPlayIco}
      alt={isPlaying[index] ? 'Pause' : 'Play'}
      onClick={(e) => {
        e.stopPropagation()
        togglePlayPause(index)
      }}
      className="volume-icon"
    />
  )

  useEffect(() => {
    getPodcastLibraryData()
    podcastHostApiCall()
    const handleClickOutside = (event) => {
      if (!loading) {
        if (
          componentRef.current &&
          !componentRef.current.contains(event.target)
        ) {
          // setShowPodcastCoverModel(false)
          setShowPodcastModel(false)
          setShowPodcastPreviewModel(false)
          setPreviewModel(false)
          clearValue()
          setIsEditing(false)
        }
      }
    }
    if (
      !loading &&
      (ShowPodcastModel ||
        // ShowPodcastCoverModel ||
        ShowPodcastPreviewModel ||
        previewModel ||
        ShowConfirmModel)
    ) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields, selectedVideo])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing, selectedVideo])

  return (
    <div className="podcast-details-manage">
      <div className="add-video-row">
        {/* <Link onClick={AddPodcast}>
          <img src={AddIco} alt="" />
          Add New Podcast
        </Link> */}
        <CommonButton
          label="Add New Podcast"
          imgSrc={AddIco}
          onClick={AddPodcast}
          isPrimary
          className="add_new_content"
        />
        <div className="items-filter">
          <div className="position-relative input-position">
            <CommonInput
              placeholder="Search Podcast"
              onChange={(e) => {
                handleSearch(e.target.value)
              }}
            />
          </div>
          <Dropdown
            title="Filter"
            Data={[
              { title: 'Scaloo' },
              { title: 'Spacure' },
              { title: 'PETZINO' },
              { title: 'A-Z FIT' },
              { title: 'TESTIEE' }
            ]}
            customClass="content-filter disabledDropdown"
          />
        </div>
      </div>
      <div className="Podcasts-item-details">
        {podcastDataAll.length === 0 && !isLoading ? (
          <div className="awards-item-video">
            <div className="add-profile-video-cover">
              <button type="button" onClick={AddPodcast}>
                <img src={Plus} alt="add library" />
              </button>
            </div>
            <div className="add-profile-video-detail">
              <p />
              <div className="d-flex align-items-center justify-content-between">
                <span />
                <span />
              </div>
            </div>
          </div>
        ) : isLoading ? (
          <div className="Podcasts-item-detail">
            <div className="podacast-items position-relative">
              <div className="podcast-cover">
                <div className="play">
                  <Skeleton circle width={20} height={20} />
                </div>
              </div>
              <div className="video-details podcast-detail">
                <div className="d-flex justify-content-between user_images">
                  <h6>
                    <Skeleton width={20} height={20} />
                    <span className="skel-podcast">
                      <Skeleton width={100} />
                    </span>
                  </h6>
                </div>
                <p className="podcast-skeleton-height">
                  <Skeleton width={150} />
                </p>
                <p className="podcast-skeleton-height">
                  <Skeleton width={150} />
                </p>
              </div>
            </div>
          </div>
        ) : (
          podcastDataAll.map((item, index) => (
            <div className="Podcasts-item-detail" key={item.name}>
              <div
                className="podacast-items position-relative"
                key={s3ImagePathCreator + item.cover_attachment}
                onMouseEnter={() => handleMouseEnter(
                    index,
                    `${s3ImagePathCreator}${item.attachment}`
                  )}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <div
                  className="podcast-cover"
                  onClick={() => handleItemClick(item)}
                  style={{
                    backgroundImage: `url(${
                      hoveredIndex === index
                        ? `${s3ImagePathCreator}${item.cover_attachment}`
                        : `${s3ImagePathCreator}${item.cover_attachment}`
                    })`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                  }}
                >
                  {item.app_data.length > 0 && (
                    <span
                      className={`platform-badge ${
                        item.app_data.length === 2 ? 'platform-badge-alt' : ''
                      }`}
                    >
                      {item.app_data.map((data, i) => (
                        <div
                          key={i}
                          className={`badge-cover ${
                            item.app_data.length === 2 ? 'badge-cover-alt' : ''
                          }`}
                          style={{
                            backgroundImage: `url(${s3ImagePath + data.icon})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '90%'
                          }}
                        />
                      ))}
                    </span>
                  )}
                  <div className="play">
                    <img src={Play} alt="" />
                  </div>
                  {playVolumeIcon(index)}
                </div>
                <div className="video-details podcast-detail">
                  <div className="d-flex justify-content-between user_images">
                    <h6>
                      <img src={s3ImagePath + userData.profile_image} alt="" />
                      <span>{userData.user_name}</span>
                    </h6>
                    {/* <div className="rating">
                    <img src={RatingStar} />
                    <span>4.9</span>
                  </div> */}
                  </div>
                  <p>{item.title}</p>
                  <div className="other-info">
                    <span>
                      <img className="d-block" src={ClockFillIco} alt="" />
                      {timeDifference(item.created_at)}
                    </span>
                  </div>
                </div>
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      onClick={() => onClickEditPodcast(item)}
                      className="d-none"
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0"
                    onClick={() => {
                      setUpdatingIndex(index)
                      ShowConfirm(item.podcast_id)
                    }}
                  />
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel && updatingIndex === index}
                  onConfirm={deletePodcast}
                  onCancel={handleCancel}
                  message="Do you want to delete this?"
                  isLoading={loading}
                />
                {loading && updatingIndex === index && <Loader />}
              </div>
            </div>
          ))
        )}

        {previewModel && (
          <div className="add-item-model preview-model-podcast">
            <div className="add-item-content" ref={componentRef}>
              <div className="d-flex justify-content-between header">
                <h3>Upload Files</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={HandleClose}
                >
                  <span>
                    <img className="close-btns" src={CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars">
                <div className="podcast-overview">
                  <div className="podcast-cover-img">
                    <div className="audio-container">
                      <img
                        src={selectedFilePath}
                        alt="Poster"
                        className="audio-poster"
                      />
                    </div>

                    <div className="podcast-profile">
                      <div className="podcast-profile-img">
                        <img
                          src={s3ImagePath + userData.profile_image}
                          alt=""
                        />
                      </div>
                      <div className="podcast-profile-detail">
                        <span>{userData.user_name}</span>
                        <p className="designation">Designation</p>
                      </div>
                    </div>
                  </div>
                  <div className="podcast-detail">
                    <h3>{inputFields.title}</h3>
                    <p>The Demo Podcast</p>
                    <img
                      src={plays ? PodcastPauseIco : PodcastPlayIco}
                      onClick={togglePlayPauses}
                      style={{ cursor: 'pointer' }}
                      alt="Play/Pause"
                    />
                    <audio
                      ref={audioRef}
                      width="100%"
                      height="200"
                      className="audio-element"
                    >
                      <source src={selectedVideo} type="audio/mp3" />
                    </audio>
                  </div>
                </div>
                <div className="podcast-discription">
                  <h5>Decription</h5>
                  <textarea
                    className="preview_text"
                    onChange={(e) => setInputFields({
                        ...inputFields,
                        description: e.target.value
                      })}
                    value={inputFields.description}
                  >
                    <p>{inputFields.description}</p>
                  </textarea>
                </div>
                <PlatformTag
                  setPlatformTags={setPlatformTags}
                  Platformtags={Platformtags}
                  onItemSelect={(value) => getSelectedAppId(value)}
                />
                <div className="item-details-input pt-0">
                  <div className="button-row">
                    <CommonButton
                      label="Cancel"
                      type="button"
                      onClick={HandleClose}
                      isPrimary={false}
                      disabled={loading}
                      context="Profile"
                    />
                    <CommonButton
                      label={isAppsSelected ? 'Publish' : 'Upload'}
                      loading={loading}
                      type="button"
                      onClick={handlePublishClick}
                      className={`primary-btn-model ${loading ? 'loading' : ''}`}
                      isPrimary
                      context="Profile"
                      disabled={
                        loading ||
                        (_.isEqual(initialPlatformTags, Platformtags) &&
                          initialDescription === inputFields.description)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      )}
      {ShowPodcastModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Upload Files</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleClose}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <div className="item-details-input pt-0">
                <div className="upload-input upload-input-with-border">
                  <label htmlFor="upload-video">
                    <CommonInput
                      type="file"
                      accept="audio/*"
                      id="upload-video"
                      onChange={handleVideoChange}
                      className="d-none"
                    />
                    {selectedVideo && (
                      <div>
                        <p>{selectedAudioName}</p>
                        <button
                          type="button"
                          className={`edit-button-model content-meneger-edit ${
                            isEditing ? 'hidden' : ''
                          }`}
                          onClick={() => {
                            setIsEditing(true)
                            document.getElementById('upload-video').click()
                          }}
                        />
                      </div>
                    )}
                    {!selectedVideo && (
                      <div className="upload-div">
                        <div className="upload-img">
                          <img src={UploadImg} alt="" />
                        </div>
                        <h4>Upload a New Podcast</h4>
                        <p className="drag-drop-text">
                          Drag and drop your file or click the button below to
                          upload
                        </p>
                        <CommonButton
                          label="Upload Podcast"
                          htmlFor="upload-video"
                          type="button"
                          className="upload_btn_width"
                          onClick={() => document.getElementById('upload-video').click()}
                        />
                      </div>
                    )}
                  </label>
                  {selectedVideo && (
                    <div className="">
                      <audio
                        key={selectedVideo}
                        ref={audioRef}
                        width="100%"
                        height="394"
                        controls
                        controlsList="nodownload noplaybackrate"
                      >
                        <source src={selectedVideo} type="audio/mp3" />
                      </audio>
                    </div>
                  )}
                </div>
                <div className="input-grid">
                  <div>
                    <label>Title of the Podcast</label>
                    <CommonInput
                      name="title"
                      placeholder="Title"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.title)}
                    />
                  </div>
                  <div>
                    <label>Select Host</label>
                    <Dropdown
                      title={getCategoryName(inputFields.host_id)}
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          host_id: d.id
                        })
                      }}
                      Data={hostName.map((d) => ({
                        title: d.host,
                        id: d.id
                      }))}
                      name="host_id"
                      customClass={
                        inputFields.host_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                </div>
                <div>
                  <label>Decription</label>
                  <textarea
                    placeholder="Type here"
                    name="description"
                    onChange={handleChange}
                    value={inputFields.description}
                  />
                </div>
                <div className="button-row">
                  <CommonButton
                    label="Cancel"
                    type="button"
                    onClick={HandleClose}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Next"
                    type="button"
                    isPrimary
                    disabled={!isFormComplete}
                    onClick={AddPodcastCover}
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPodcastCoverModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Upload Cover</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={HandleClose}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <div>
                <div className="upload-input">
                  <CommonImageUpload
                    handleImageChange={handleFileChange}
                    index={0}
                    buttonText="Upload Cover"
                    selectedImg={selectedFilePath}
                    defaultImg=""
                    forImage
                    forName="Podcast"
                  />
                </div>
              </div>
              <div className="item-details-input">
                <div className="button-row">
                  <CommonButton
                    label="Back"
                    type="button"
                    onClick={HandleCancelCover}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Preview"
                    type="button"
                    isPrimary
                    context="Profile"
                    disabled={!selectedFilePath}
                    onClick={AddPodcastPreview}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {ShowPodcastPreviewModel && (
        <div className="add-item-model preview-model-podcast">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Upload Files</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                disabled={loading}
                onClick={HandleClose}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <div className="podcast-overview">
                <div className="podcast-cover-img">
                  <div className="audio-container">
                    <img
                      src={selectedFilePath}
                      alt="Poster"
                      className="audio-poster"
                    />
                  </div>

                  <div className="podcast-profile">
                    <div className="podcast-profile-img">
                      <img src={s3ImagePath + userData.profile_image} alt="" />
                    </div>
                    <div className="podcast-profile-detail">
                      <span>{userData.user_name}</span>
                      <p className="designation">Designation</p>
                    </div>
                  </div>
                </div>
                <div className="podcast-detail">
                  <h3>{inputFields.title}</h3>
                  <p>The Demo Podcast</p>
                  <img
                    src={plays ? PodcastPauseIco : PodcastPlayIco}
                    onClick={togglePlayPauses}
                    style={{ cursor: 'pointer' }}
                    alt="Play/Pause"
                  />
                  <audio
                    ref={audioRef}
                    width="100%"
                    height="200"
                    className="audio-element"
                  >
                    <source src={selectedVideo} type="audio/mp3" />
                  </audio>
                </div>
              </div>
              <div className="podcast-discription">
                <h5>Decription</h5>
                <p>{inputFields.description}</p>
              </div>
              <PlatformTag
                setPlatformTags={setPlatformTags}
                Platformtags={Platformtags}
                onItemSelect={(value) => getSelectedAppId(value)}
              />
              <div className="item-details-input pt-0">
                <div className="button-row">
                  <CommonButton
                    label="Back"
                    type="button"
                    onClick={HandleCancelPreview}
                    isPrimary={false}
                    context="Profile"
                    disabled={loading}
                  />
                  <CommonButton
                    label={isAppsSelected ? 'Publish' : 'Upload'}
                    loading={loading}
                    type="button"
                    onClick={handlePublishClick}
                    className={`primary-btn-model ${loading ? 'loading' : ''}`}
                    isPrimary
                    disabled={loading}
                    context="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default PodcastDetails
