/* eslint-disable no-nested-ternary */
/* eslint-disable no-useless-escape */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { TimePicker } from 'antd'
import dayjs from 'dayjs'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import AddIco from '../../images/AddIco.svg'
import Remove from '../../images/remove.svg'
import plusimg from '../../images/plusimg.svg'
import Dropdown from '../Dropdown/Dropdown'
import plusgredient from '../../images/plus-rounded.svg'
import GroupDiscussion from '../../images/Group-discussion.svg'
import {
  addGiveawayData,
  deleteGiveawayData,
  editGiveawayData,
  getBrandList,
  getGiveawaysLibrary,
  getTypeList,
  giveawayParticipateListData,
  imageUploadCreator,
  s3ImagePath,
  s3ImagePathCreator
} from '../../services/Service'
import { compressImage, dataDecrypt } from '../../services/http-services'
import PlatformTag from './PlatformTag'
import Loader from '../Loader/Loader'
import CloseIco from '../../images/CloseIco.svg'
import FillStar from '../../images/fill-star.svg'
import EmptyStar from '../../images/EmptyStar.svg'
import Clock from '../../images/clock-blue.svg'
import Dyson from '../../images/dyson.png'
import PlusTag from '../../images/Plus-tag.svg'
import Pagination from '../Pagination/Pagination'
import { capitalizeFirstLetter, formatTimeLeft } from '../../helpers/Utility'
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const Giveaway = () => {
  const componentRef = useRef()
  const timePickerRef = useRef(null)

  const [selectedImg, setSelectedImg] = useState(0)
  const [ShowCommunityModel, setShowCommunityModel] = useState(false)
  const [ShowCommunityGiveawayCover, setShowCommunityGiveawayCover] =
    useState(false)
  const [ShowGiveawayProduct, setShowGiveawayProduct] = useState(false)
  const [selectedImages, setSelectedImages] = useState([])
  const [Platformtags, setPlatformTags] = useState([])
  const [inputFields, setInputFields] = useState({
    app_id: [],
    file: [],
    title: '',
    time_left: '',
    brand_id: 0,
    type_id: 0,
    email: '',
    phone: '',
    address: '',
    tag_friends: [],
    description: ''
  })
  const [loading, setLoading] = useState(false)
  const [isFormComplete, setIsFormComplete] = useState(false)
  const [draggedIndex, setDraggedIndex] = useState(null)
  const [brandData, setBrandData] = useState([])
  const [typeData, setTypeData] = useState([])
  const [giveawayProduct, setGiveawayProduct] = useState([])
  const [delGiveawayId, setDelGiveawayId] = useState()
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [isEditing, setIsEditing] = useState(false)
  const [giveawayList, setGiveawayList] = useState([])
  const [errors, setErrors] = useState({})
  const [tags, setTags] = useState([])
  const [editingIndex, setEditingIndex] = useState(null)
  const [showAll, setShowAll] = useState(false)
  const [ShowProfileModel, setShowProfileModel] = useState(false)
  const [initialValues, setInitialValues] = useState({})
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [isImageLoading, setIsImageLoading] = useState({
    index: null,
    loading: false
  })

  const clearValue = () => {
    setSelectedImages([])
    setSelectedImg([])
    setPlatformTags([])
    setInputFields({
      ...inputFields,
      app_id: [],
      file: [],
      title: '',
      time_left: '',
      brand_id: 0,
      type_id: 0,
      email: '',
      phone: '',
      address: '',
      tag_friends: [],
      description: ''
    })
    setTags([])
  }

  const handleChange = (e) => {
    const { name, value } = e.target

    if (name === 'phone') {
      const phoneValue = value.replace(/\D/g, '')
      setInputFields({
        ...inputFields,
        [name]: phoneValue
      })

      if (validatePhone(phoneValue)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: ''
        }))
      }
    } else {
      setInputFields({
        ...inputFields,
        [name]: value
      })
    }

    if (name === 'email') {
      if (validateEmail(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          email: ''
        }))
      }
    }
  }

  const onChange = (time, timeString) => {
    setInputFields((prevState) => ({
      ...prevState,
      time_left: timeString
    }))
  }

  const openTimePicker = () => {
    setOpen(true)
  }

  const getSelectedAppId = (app) => {
    setInputFields({ ...inputFields, app_id: app.map((a) => a.id) })
  }

  const handleImageChange = async (file, indexToReplace) => {
    const e = {
      target: {
        files: [file]
      }
    }
    const totalImagesAfterReplacement =
      indexToReplace !== undefined
        ? selectedImages.length
        : selectedImages.length + e.target.files.length

    if (totalImagesAfterReplacement > 5) {
      toast.error('You can only upload up to 5 images.')
      return
    }
    const updatedImages = [...selectedImages]
    const updatedFiles = [...inputFields.file]

    try {
      const uploadPromises = Array.from(e.target.files).map(async (file) => {
        const imageFile = await compressImage(file)
        const coverFormData = new FormData()
        coverFormData.append('file', imageFile)
        coverFormData.append('type', 'giveaway')

        const coverUploadResponse = await imageUploadCreator(coverFormData)
        if (!coverUploadResponse.data.success) {
          throw new Error(
            coverUploadResponse.data.message || 'Image upload failed'
          )
        }

        const decryptedCoverData = dataDecrypt(coverUploadResponse.data.data)

        if (indexToReplace !== undefined && indexToReplace !== null) {
          updatedImages[indexToReplace] = decryptedCoverData
          updatedFiles[indexToReplace] = decryptedCoverData
        } else {
          updatedImages.push(decryptedCoverData)
          updatedFiles.push(decryptedCoverData)
        }
      })

      await Promise.all(uploadPromises)
      setSelectedImages(updatedImages)
      setInputFields({ ...inputFields, file: updatedFiles })
    } catch (error) {
      console.error('Error uploading image:', error)
    }
  }

  const removeImage = (index) => {
    const updatedImages = [...selectedImages]
    updatedImages.splice(index, 1)
    setSelectedImages(updatedImages)

    const updatedFiles = [...inputFields.file]
    updatedFiles.splice(index, 1)
    setInputFields({ ...inputFields, file: updatedFiles })
  }

  // const replaceImage = (index) => {
  //   const input = document.createElement('input')
  //   input.type = 'file'

  //   const handleChange = (e) => {
  //     handleImageChange(e, index)
  //     input.removeEventListener('change', handleChange)
  //   }

  //   if (input) {
  //     input.value = ''
  //     input.removeEventListener('change', handleChange)
  //     input.click()
  //     input.addEventListener('change', handleChange, { once: true })
  //     input.remove()
  //   }
  // }

  const ShowCommunityGiveaway = () => {
    setShowCommunityModel(true)
  }

  const HandleGiveawayCover = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(true)
  }

  const handleCancel = () => {
    setShowConfirmModel(false)
  }
  const ShowConfirm = (giveawayId) => {
    setDelGiveawayId(giveawayId)
    setShowConfirmModel(true)
  }

  const handleClose = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(false)
    setIsEditing(false)
    clearValue()
  }
  const GiveawayCoverCancel = () => {
    setShowCommunityGiveawayCover(false)
    setShowCommunityModel(true)
  }
  const ProductCancel = () => {
    setShowGiveawayProduct(false)
    setShowCommunityGiveawayCover(true)
  }

  const HandleGiveawayProduct = () => {
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(true)
  }

  const HandleDiscussion = () => {
    if (isEditing) {
      editGiveawayDataApi()
    } else {
      addGiveawayDataSubmit()
    }
    setShowCommunityModel(false)
    setShowCommunityGiveawayCover(false)
    setShowGiveawayProduct(false)
    setIsEditing(false)
  }

  const handleNextClick = () => {
    const emailValid = validateEmail(inputFields.email)
    const phoneValid = validatePhone(inputFields.phone)
    if (!emailValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Enter a valid email'
      }))
    }
    if (!phoneValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: 'Number should be 10 digits'
      }))
    }
    if (emailValid && phoneValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: '',
        phone: ''
      }))
      HandleGiveawayCover()
    }
  }

  const updateInputFieldsTags = (tagsArray) => {
    setInputFields((prevInputFields) => ({
      ...prevInputFields,
      tag_friends: tagsArray
    }))
  }

  const handleAddTag = () => {
    if (tags.length < 4) {
      const newTag = `tag ${tags.length + 1}`
      setTags([...tags, newTag])
      updateInputFieldsTags([...tags, newTag])
    } else {
      alert('Maximum 4 tags allowed.')
    }
  }

  const handleRemoveTag = (tagIndex) => {
    const updatedTags = [...tags]
    updatedTags.splice(tagIndex, 1)
    setTags(updatedTags)
    updateInputFieldsTags(updatedTags)
  }

  const handleEditTag = (tagIndex) => {
    setEditingIndex(tagIndex)
  }

  const handleTagChange = (e, tagIndex) => {
    const updatedTags = [...tags]
    updatedTags[tagIndex] = e.target.value
    setTags(updatedTags)
    updateInputFieldsTags(updatedTags)
  }

  const handleTagBlur = () => {
    setEditingIndex(null)
  }

  const validateForm = () => {
    const {
      title,
      time_left,
      brand_id,
      type_id,
      email,
      phone,
      address,
      tag_friends,
      description
    } = inputFields

    const isTagFriendsValid =
      Array.isArray(tag_friends) && tag_friends.length > 0

    if (
      title &&
      time_left &&
      brand_id &&
      type_id &&
      email &&
      phone &&
      address &&
      isTagFriendsValid &&
      description
    ) {
      setIsFormComplete(true)
    } else {
      setIsFormComplete(false)
    }
  }

  const validateEmail = (email) => {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    )
  }

  const validatePhone = (phone) => {
    const regexMobile = /^\d{10}$/
    return regexMobile.test(phone)
  }

  const isAppsSelected = inputFields.app_id?.length > 0

  const getBrandListApiCall = () => {
    getBrandList({}).then((res) => {
      if (res.data.success) {
        setBrandData(dataDecrypt(res.data.data))
      }
    })
  }

  const getTypeListApiCall = () => {
    getTypeList({}).then((res) => {
      if (res.data.success) {
        setTypeData(dataDecrypt(res.data.data))
      }
    })
  }

  const getGiveawayLibraryData = () => {
    setIsLoading(true)
    getGiveawaysLibrary({}).then((res) => {
      if (res.data.success) {
        setGiveawayProduct(dataDecrypt(res.data.data).data)
        const { data } = dataDecrypt(res.data.data)
        if (data.length !== 0) {
          // data.forEach((giveaway) => {
          //   giveawayParticipate(giveaway.giveaway_id)
          // })
          giveawayParticipate(data[0].giveaway_id)
        }
        setIsLoading(false)
      }
    })
  }

  const addGiveawayDataSubmit = () => {
    setLoading(true)
    addGiveawayData(inputFields).then((res) => {
      if (res.data.success) {
        setShowGiveawayProduct(false)
        getGiveawayLibraryData()
        setIsEditing(false)
        setLoading(false)
        clearValue()
        toast.success(res?.data?.message)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const editGiveawayDataApi = () => {
    const data = {
      title: inputFields.title,
      time_left: inputFields.time_left,
      brand_id: inputFields.brand_id,
      type_id: inputFields.type_id,
      email: inputFields.email,
      phone: inputFields.phone,
      address: inputFields.address,
      tag_friends: inputFields.tag_friends,
      description: inputFields.description,
      file: inputFields.file,
      app_id: Platformtags.map((d) => d.id),
      giveaway_id: inputFields.giveaway_id
    }
    editGiveawayData(data).then((res) => {
      if (res.data.success) {
        setIsEditing(false)
        clearValue()
        setLoading(false)
        toast.success(res?.data?.message)
        getGiveawayLibraryData()
      }
    })
  }

  const deleteGiveaway = () => {
    const data = {
      giveaway_id: delGiveawayId
    }
    setLoading(true)
    deleteGiveawayData(data).then((res) => {
      if (res.data.success) {
        getGiveawayLibraryData()
        setShowConfirmModel(false)
        setLoading(false)
        toast.success(res?.data?.message)
      }
    })
  }

  const getBrandName = (categoryId) => {
    const categoryObj = brandData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.brand : 'Select Brand'
  }
  const getTypeName = (categoryId) => {
    const categoryObj = typeData.find((cat) => cat.id === categoryId)
    return categoryObj ? categoryObj.type : 'Select Type'
  }

  const giveawayParticipate = (giveaway_id) => {
    const platFormData = {
      giveaway_id
    }
    giveawayParticipateListData(platFormData).then((res) => {
      if (res.data.success) {
        setGiveawayList(dataDecrypt(res.data.data))
      }
    })
  }

  const close = () => {
    setShowProfileModel(false)
  }

  const handleDragStart = (index) => {
    setDraggedIndex(index)
  }

  const handleDrop = (index) => {
    if (index !== draggedIndex) {
      setSelectedImages((state) => {
        const copy = [...state]
        const b = copy[draggedIndex]
        copy[draggedIndex] = copy[index]
        copy[index] = b
        inputFields.file = copy
        return copy
      })

      setDraggedIndex(null)
    }
  }

  const handleLoad = (index) => {
    setIsImageLoading({
      index,
      loading: true
    })
    setTimeout(() => {
      setIsImageLoading({
        index: null,
        loading: false
      })
    }, 500)
  }

  const renderImg = useMemo(() => {
    return (
      <>
        {selectedImages.map((image, index) => {
          return (
            <div
              className={`image position-relative ${
                index === 0 ? 'first-image' : ''
              }`}
              key={index}
              // style={{
              //   backgroundImage: `url(${
              //     typeof image === 'string'
              //       ? s3ImagePathCreator + image
              //       : URL.createObjectURL(image)
              //   })`,
              //   backgroundSize: 'cover',
              //   backgroundPosition: 'center'
              // }}
              alt={`image-${index}`}
              draggable
              onDragStart={() => {
                handleDragStart(index)
              }}
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={(event) => event.preventDefault()}
              onDrop={(event) => {
                event.preventDefault()
                handleDrop(index)
              }}
            >
              {isImageLoading.index === index && isImageLoading.loading && (
                <Loader />
              )}
              <img
                className={`image position-relative ${
                  index === 0 ? 'first-image' : ''
                }`}
                src={
                  typeof image === 'string'
                    ? s3ImagePathCreator + image
                    : URL.createObjectURL(image)
                }
                alt={`image-${index}`}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                onLoad={() => handleLoad(index)}
              />
              <button type="button" onClick={() => removeImage(index)}>
                <img src={Remove} alt="" />
              </button>
              <button type="button" className="plus-img">
                <CommonImageUpload
                  handleImageChange={(file) => { handleImageChange(file, index) }}
                  selectedImg=""
                  defaultImg={plusimg}
                  className="plsimg"
                />
              </button>
            </div>
          )
        })}
      </>
    )
  }, [selectedImages, handleDragStart])

  const onClickEditGiveaway = (videoData) => {
    setShowCommunityModel(true)
    setIsEditing(true)
    setInputFields({
      ...inputFields,
      title: videoData.title,
      time_left: videoData.time_left,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
    setInitialValues({
      title: videoData.title,
      time_left: videoData.time_left,
      brand_id: videoData.brand_id,
      type_id: videoData.type_id,
      email: videoData.email,
      phone: videoData.phone,
      address: videoData.address,
      tag_friends: videoData.tag_friends,
      description: videoData.description,
      file: videoData.images,
      app_id: videoData.app_data,
      giveaway_id: videoData.giveaway_id
    })
    setPlatformTags(videoData.app_data)
    setSelectedImages(videoData.images)
    setSelectedImg(0)
    setTags(videoData.tag_friends)
  }

  const showList = (giveaway_id) => {
    giveawayParticipate(giveaway_id)
    setShowProfileModel(true)
  }

  const handleViewAll = () => {
    setShowAll(!showAll)
  }
  const displayedGiveaways = showAll
    ? giveawayProduct
    : giveawayProduct.slice(0, 3)

  // giveaway participate list pagination

  const videosPerPage = 5
  const indexOfLastVideo = currentPage * videosPerPage
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage
  const giveawayListData = giveawayList.slice(
    indexOfFirstVideo,
    indexOfLastVideo
  )
  const totalVideos = giveawayList.length
  const totalPages = Math.ceil(totalVideos / videosPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    getGiveawayLibraryData()
    getBrandListApiCall()
    getTypeListApiCall()
    // const handleClickOutside = (event) => {
    //   if (
    //     componentRef.current &&
    //     !componentRef.current.contains(event.target)
    //   ) {
    //     setShowCommunityGiveawayCover(false)
    //     setShowGiveawayProduct(false)
    //   }
    // }
    // document.addEventListener('mousedown', handleClickOutside)
    // return () => {
    //   document.removeEventListener('mousedown', handleClickOutside)
    // }
  }, [])

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages > 0 ? totalPages : 1)
    }
  }, [totalPages, currentPage])

  useEffect(() => {
    if (!isEditing) {
      validateForm()
    }
  }, [inputFields])

  useEffect(() => {
    if (isEditing) {
      setIsFormComplete(!_.isEqual(inputFields, initialValues))
    }
  }, [inputFields, initialValues, isEditing])

  return (
    <div className="content-manager-awards-wrapper common-card-body community-margin">
      <div className="poll-wrapper-header common-contnt-wrapper">
        <h2>Giveaways</h2>
        <Link onClick={ShowCommunityGiveaway}>
          <img src={AddIco} alt="" />
          New Giveaways
        </Link>
      </div>

      {displayedGiveaways.length === 0 && !isLoading ? (
        <div className="add-giveways-item mb-30">
          <div className="add-giveways-cover">
            <button
              type="button"
              className="add-item-btn"
              onClick={ShowCommunityGiveaway}
            >
              <img src={plusgredient} alt="add giveaway" />
            </button>
          </div>
          <div className="add-giveways-details">
            <h4 className="add-value"> </h4>
            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex align-items-center">
                <li className="add-value">List Item 1</li>
                <li className="add-value">List Item 2</li>
              </ul>
              <span className="add-value">Span Content</span>
            </div>
            <p className="add-value">Paragraph Content</p>
            <h5 className="add-value"> </h5>
          </div>
        </div>
      ) : isLoading ? (
        <div className="add-giveways-item mb-30">
          <div className="add-giveways-cover">
            <button type="button" className="add-item-btn">
              <img src={plusgredient} alt="add giveaway" />
            </button>
          </div>
          <div className="add-giveways-details">
            <h4 className="add-value">
              <Skeleton className="add-value" width={155} height={29} />
            </h4>
            <div className="d-flex align-items-center justify-content-between">
              <ul className="d-flex align-items-center">
                <li className="add-value">
                  <Skeleton className="add-value" width={110} height={29} />
                </li>
                <li className="add-value">
                  <Skeleton className="add-value" width={110} height={29} />
                </li>
              </ul>
              <span className="add-value">
                <Skeleton className="add-value" width={121} height={29} />
              </span>
            </div>
            <p className="add-value">
              <Skeleton className="add-value" width={580} height={110} />
            </p>
            <h5 className="add-value">
              <Skeleton className="add-value" width={149} height={48} />
            </h5>
          </div>
        </div>
      ) : (
        <div className="add-giveways-container">
          {displayedGiveaways.map((item, index) => {
            const timeLeftText = formatTimeLeft(item.time_left)
            const isTimeUp = timeLeftText === 'Time is up'
            return (
              <div
                key={index}
                className="added-giveways-item position-relative"
              >
                <div className="giveways-item-cover position-relative">
                  {item.app_data.length > 0 && (
                    <span
                      className={`platform-badge ${
                        item.app_data.length === 2 ? 'platform-badge-alt' : ''
                      }`}
                    >
                      {item.app_data.map((data, i) => (
                        <div
                          key={i}
                          className={`badge-cover ${
                            item.app_data.length === 2 ? 'badge-cover-alt' : ''
                          }`}
                          style={{
                            backgroundImage: `url(${s3ImagePath + data.icon})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '90%'
                          }}
                        />
                      ))}
                    </span>
                  )}
                  <img src={s3ImagePathCreator + item.images[0]} alt="" />
                </div>
                <div className="giveways-item-details">
                  <h4>{item.title}</h4>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="giveways-profile-detail">
                      <img className="profile-img-giveway" src={Dyson} alt="" />
                      <span className="giveway-profile-name">
                        {item.name}
                        Jacob Jones
                      </span>
                      <div className="vertical-devider" />
                      <div className="rating-block-container">
                        <img src={FillStar} alt="" />
                        <img src={FillStar} alt="" />
                        <img src={FillStar} alt="" />
                        <img src={FillStar} alt="" />
                        <img src={EmptyStar} alt="" />
                      </div>
                      <span>
                        {item.rating}
                        {' '}
                        4.5 rating
                      </span>
                    </div>
                    <div className="giveway-time-left">
                      <img src={Clock} alt="" />
                      <span>{formatTimeLeft(item.time_left)}</span>
                    </div>
                  </div>
                  <p>{item.description}</p>
                  <div className="d-flex align-items-center gap-2">
                    <button
                      type="button"
                      className="primary-btn"
                      disabled={isTimeUp}
                    >
                      <span>Enter to Win</span>
                    </button>
                    <div className="discussion-group ml-1">
                      {item?.total_participates > 0 &&
                        giveawayList
                          .slice(0, 3)
                          .map((list, index) => (
                            <img
                              key={index}
                              src={s3ImagePath + list.icon}
                              alt={list.user_name}
                            />
                          ))}
                      <img
                        src={GroupDiscussion}
                        alt="Group Discussion"
                        onClick={() => {
                          if (item.total_participates > 0) {
                            showList(item?.giveaway_id)
                          }
                        }}
                      />
                    </div>
                    {ShowProfileModel && (
                      <div className="add-item-model edit-profile">
                        <div className="add-item-content" ref={componentRef}>
                          <div className="d-flex justify-content-between header">
                            <h3>Giveaway Participate List</h3>
                            <button
                              type="button"
                              className="border-0 bg-none p-0"
                              onClick={close}
                            >
                              <span>
                                <img
                                  className="close-btns"
                                  src={CloseIco}
                                  alt=""
                                />
                              </span>
                            </button>
                          </div>

                          <div className="scroll-bars">
                            <div className="item-details-input pt-0">
                              <div className="list_data">
                                {giveawayListData.map((list, index) => {
                                  const calculatedIndex =
                                    (currentPage - 1) * videosPerPage +
                                    index +
                                    1
                                  return (
                                    <div
                                      className="d-flex justify-content-start list_table"
                                      key={index}
                                    >
                                      <span className="number_index">
                                        {calculatedIndex}
                                      </span>
                                      <img
                                        src={s3ImagePath + list.icon}
                                        className="profile-pic"
                                        alt="profile"
                                      />
                                      <span className="user-name">
                                        {list.user_name}
                                      </span>
                                      <span className="time-date">
                                        {list.created_at}
                                      </span>
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                          {totalPages > 1 && (
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={handlePageChange}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="image-buttons z-1">
                  <label>
                    <button
                      type="button"
                      onClick={() => onClickEditGiveaway(item)}
                      className="d-none"
                    />
                  </label>
                  <button
                    type="button"
                    className="p-0"
                    onClick={() => {
                      setUpdatingIndex(index)
                      ShowConfirm(item.giveaway_id)
                    }}
                  />
                </div>
                <ConfirmationModal
                  isVisible={ShowConfirmModel && updatingIndex === index}
                  onConfirm={deleteGiveaway}
                  onCancel={handleCancel}
                  message="Do you want to delete this?"
                  isLoading={loading}
                />
                {loading && updatingIndex === index && <Loader />}
              </div>
            )
          })}
        </div>
      )}
      {ShowCommunityModel && (
        <div className="add-item-model">
          <div className="add-item-content" ref={componentRef}>
            <div className="d-flex justify-content-between header">
              <h3>Enter Giveaway details</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleClose}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <div className="item-details-input pt-0">
                <div className="input-grid">
                  <div>
                    <label>Title of the Giveaway</label>
                    <CommonInput
                      name="title"
                      placeholder="Title"
                      onChange={handleChange}
                      maxLength={25}
                      value={capitalizeFirstLetter(inputFields.title)}
                    />
                  </div>
                  <div>
                    <label>Time Left</label>
                    <div
                      onClick={openTimePicker}
                      className="cooking-time-filed position-relative"
                    >
                      <TimePicker
                        ref={timePickerRef}
                        className="ant-design"
                        name="time_left"
                        onChange={onChange}
                        open={open}
                        onOpenChange={setOpen}
                        showNow={false}
                        showOk={false}
                        suffixIcon={null}
                        placeholder="Please Select"
                        value={
                          inputFields.time_left
                            ? dayjs(inputFields.time_left, 'HH:mm')
                            : null
                        }
                        format="HH:mm"
                      />
                    </div>
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>Brand</label>

                    <Dropdown
                      title={getBrandName(inputFields.brand_id)}
                      onChanged={(d) => {
                        getTypeListApiCall()
                        setInputFields({
                          ...inputFields,
                          brand_id: d.id,
                          type_id: null
                        })
                      }}
                      Data={brandData.map((d) => ({
                        id: d.id,
                        title: d.brand
                      }))}
                      name="brand_id"
                      placeholder="Select Brands"
                      customClass={
                        inputFields.brand_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                  <div>
                    <label>Type</label>
                    <Dropdown
                      title={getTypeName(inputFields.type_id)}
                      onChanged={(d) => {
                        setInputFields({
                          ...inputFields,
                          type_id: d.id
                        })
                      }}
                      Data={typeData.map((d) => ({
                        id: d.id,
                        title: d.type
                      }))}
                      name="type_id"
                      customClass={
                        inputFields.type_id
                          ? 'selected-class'
                          : 'giveaway-dropdown'
                      }
                    />
                  </div>
                </div>
                <div className="input-grid">
                  <div>
                    <label>User Email</label>
                    <CommonInput
                      name="email"
                      placeholder="Enter Email"
                      onChange={handleChange}
                      value={inputFields.email}
                      className="mb-0"
                    />
                    {errors.email && (
                      <span className="error-text-email">{errors.email}</span>
                    )}
                  </div>
                  <div>
                    <label>Phone Number</label>
                    <CommonInput
                      name="phone"
                      type="text"
                      placeholder="Enter here"
                      onChange={handleChange}
                      maxLength="10"
                      pattern="\d{10}"
                      className="mb-0"
                      value={inputFields.phone}
                    />
                    {errors.phone && (
                      <span className="error-text-email">{errors.phone}</span>
                    )}
                  </div>
                </div>
                <div className=" mt-3">
                  <div>
                    <label>Address</label>
                    <CommonInput
                      name="address"
                      placeholder="Enter address"
                      onChange={handleChange}
                      value={capitalizeFirstLetter(inputFields.address)}
                    />
                  </div>
                </div>

                <label>Tag Friends</label>
                <div className="add-tag mb-3">
                  <span>Add Tags</span>
                  <div className="d-flex flex-wrap column-gap-20">
                    <ul>
                      {tags.map((tag, index) => (
                        <li
                          key={index}
                          className={`tag-item ${
                            editingIndex === index ? 'editing' : ''
                          }`}
                        >
                          {editingIndex === index ? (
                            <div className="tag-edit-container">
                              <CommonInput
                                value={tag}
                                onChange={(e) => handleTagChange(e, index)}
                                onBlur={handleTagBlur}
                                ref={(input) => input && input.focus()}
                                className="edit-input"
                              />
                            </div>
                          ) : (
                            <>
                              {tag}
                              <button
                                type="button"
                                className="remove-tag"
                                onClick={() => handleRemoveTag(index)}
                              >
                                <img src={Remove} alt="Remove" />
                              </button>
                              <button
                                type="button"
                                className="edit-tag"
                                onClick={() => handleEditTag(index)}
                              />
                            </>
                          )}
                        </li>
                      ))}
                    </ul>
                    {tags.length < 4 && (
                      <button
                        type="button"
                        className="add-tag-btns"
                        onClick={handleAddTag}
                      >
                        <img src={PlusTag} alt="Add Tag" />
                        Add Tag
                      </button>
                    )}
                  </div>
                </div>
                <div>
                  <label>Decription</label>
                  <CommonInput
                    placeholder="Type here"
                    name="description"
                    onChange={handleChange}
                    maxLength={250}
                    value={capitalizeFirstLetter(inputFields.description)}
                    isTextArea
                  />
                </div>
                <div className="button-row">
                  <CommonButton
                    label="Cancel"
                    type="button"
                    onClick={handleClose}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Next"
                    type="button"
                    isPrimary
                    context="Profile"
                    disabled={!isFormComplete}
                    onClick={handleNextClick}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowCommunityGiveawayCover && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex justify-content-between flex-column"
            ref={componentRef}
          >
            <div>
              <div className="d-flex justify-content-between header">
                <h3>Upload Images</h3>
                <button
                  type="button"
                  className="border-0 bg-none p-0"
                  onClick={handleClose}
                >
                  <span>
                    <img className="close-btns" src={CloseIco} alt="" />
                  </span>
                </button>
              </div>
              <div className="scroll-bars pb-0">
                <div className="input-image-show">
                  {renderImg}
                  <div className="input-img-label">
                    <CommonImageUpload
                      handleImageChange={handleImageChange}
                      buttonText="Add Image"
                      selectedImg=""
                      defaultImg={plusgredient}
                      className="plsimg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="scroll-bars button_scroll_bar">
              <div className="item-details-input">
                <div className="button-row">
                  <CommonButton
                    label="Back"
                    type="button"
                    onClick={GiveawayCoverCancel}
                    isPrimary={false}
                    context="Profile"
                  />
                  <CommonButton
                    label="Next"
                    type="button"
                    isPrimary
                    context="Profile"
                    disabled={selectedImages.length === 0}
                    onClick={HandleGiveawayProduct}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowGiveawayProduct && (
        <div className="add-item-model">
          <div
            className="add-item-content d-flex flex-column"
            ref={componentRef}
          >
            <div className="d-flex justify-content-between header">
              <h3>Giveaway details</h3>
              <button
                type="button"
                className="border-0 bg-none p-0"
                onClick={handleClose}
              >
                <span>
                  <img className="close-btns" src={CloseIco} alt="" />
                </span>
              </button>
            </div>
            <div className="scroll-bars">
              <div className="row">
                <div className="col-md-7">
                  <div className="product-img-preview">
                    <div className="product-img-table">
                      {selectedImages.map((image, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setSelectedImg(index)
                          }}
                          className={`product-img-item ${
                            selectedImg === index && 'product-img-blur'
                          }`}
                          style={{
                            backgroundImage: `url(${
                              typeof image === 'string'
                                ? s3ImagePathCreator + image
                                : URL.createObjectURL(image)
                            })`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                          }}
                        />
                      ))}
                    </div>
                    <div className="product-img-selected" key="123">
                      <img
                        alt=""
                        src={`${
                          s3ImagePathCreator + selectedImages[selectedImg]
                        }`}
                        className="w-100 h-100"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 product-detail">
                  <h2>{inputFields.title}</h2>
                  <div className="product-seller-detail">
                    <div
                      className="product-seller-logo"
                      style={{
                        background: 'url(./images/seller-logo.png)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                      }}
                    />
                    Jacob Jones
                  </div>
                  <div className="product-type">
                    <span>Type:</span>
                    {getTypeName(inputFields.type_id)}
                  </div>
                  <div className="product-specification">
                    <h5>Detail</h5>
                    <p>{inputFields.description}</p>
                  </div>
                </div>
              </div>
              <PlatformTag
                setPlatformTags={setPlatformTags}
                Platformtags={Platformtags}
                onItemSelect={(value) => getSelectedAppId(value)}
              />

              <div className="item-details-input">
                <div className="button-row">
                  <CommonButton
                    label="Back"
                    type="button"
                    onClick={ProductCancel}
                    isPrimary={false}
                    disabled={loading}
                    context="Profile"
                  />
                  <CommonButton
                    label={isAppsSelected ? 'Publish' : 'Upload'}
                    loading={loading}
                    type="button"
                    onClick={HandleDiscussion}
                    className={`primary-btn-model ${loading ? 'loading' : ''}`}
                    isPrimary
                    context="Profile"
                    disabled={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="link_view">
        <Link onClick={handleViewAll}>
          {showAll ? 'Show Less' : 'View All'}
        </Link>
      </div>
    </div>
  )
}
export default Giveaway
