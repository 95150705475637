/* eslint-disable no-undef */
/* eslint space-infix-ops: "off" */
import React, { useEffect, useState } from 'react'
import './photos.css'
import { toast } from 'react-toastify'
import Autocomplete from 'react-google-autocomplete'
import 'cropperjs/dist/cropper.css'
// import upload from '../../images/upload.svg'
import {
  imageUploadCreator,
  addPost,
  getPost,
  deletePost,
  s3ImagePathCreator,
  editPost,
  locationSuggestion
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import Loader from '../Loader/Loader'
import CloseIco from '../../images/CloseIco.svg'
import Plus from '../../images/plus-rounded.svg'
import AddIco from '../../images/AddIco.svg'
import CommonImageUpload from '../ImageCroper/StoreImageCroper'
import CommonButton from '../ButtonComponent/ButtonCommon'
import CommonInput from '../InputComponet/CommonInput'

const AddPhotos = () => {
  const [images, setImages] = useState()
  const [imageObj, setImageObj] = useState()
  const [getImage, setGetImage] = useState([])
  const [loading, setLoading] = useState(true)
  const [loader, setLoader] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)
  const [updatingIndex, setUpdatingIndex] = useState(null)
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
  const [isFormModified, setIsFormModified] = useState(false)

  const [inputFields, setInputFields] = useState({
    attachment: '',
    description: '',
    app_id: JSON.parse(localStorage.getItem('app_id')).id,
    location: ''
  })
  const [ShowVideoModel, setShowVideoModel] = useState({
    show: false,
    isEditing: false
  })
  const [localStorageValue, setLocalStorageValue] = useState(() => {
    return JSON.parse(localStorage.getItem('app_id')) || null
  })

  const AddVideo = () => {
    setShowVideoModel({ ...ShowVideoModel.isEditing, show: true })
  }

  const EditPostData = (image, postId) => {
    setLoader(true)
    const data = {
      app_id: inputFields.app_id
    }
    editPost(image, postId, data).then((res) => {
      if (res.data.success) {
        setImages(s3ImagePathCreator + image.attachment)
        setInputFields({
          attachment: s3ImagePathCreator + image.attachment,
          description: image.description,
          app_id: JSON.parse(localStorage.getItem('app_id')).id,
          location: image.location,
          post_id: postId
        })
        toast.success(res?.data?.message)
        setShowVideoModel({ show: false, isEditing: false })
        setLoader(false)
        ResetValue()
        getPostData()
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const ResetValue = () => {
    setInputFields({
      attachment: '',
      description: '',
      location: '',
      app_id: JSON.parse(localStorage.getItem('app_id')).id
    })
    setImages('')
    setIsFormModified(false)
  }

  const HandleCancel = () => {
    setShowVideoModel({ show: false, isEditing: false })
    ResetValue()
  }

  const ShowConfirm = () => {
    setShowConfirmModel(true)
  }

  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
  }

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    setIsFormModified(true)
    validateFields({ ...inputFields, [e.target.name]: e.target.value })
  }

  const validateFields = (fields) => {
    const { description, location } = fields
    setIsSaveButtonDisabled(!(description && location))
  }

  const getPostData = () => {
    const data = {
      app_id: JSON.parse(localStorage.getItem('app_id')).id
      // creator_user_id: params.id
    }
    getPost(data).then((res) => {
      if (res.data.success) {
        setGetImage(dataDecrypt(res.data.data).data)
      }
    })
  }

  const handleImageChange = (file) => {
    if (file) {
      const imageUrl = URL.createObjectURL(file)
      setImages(imageUrl)
      setImageObj(file)
      setIsFormModified(true)
      validateFields({ ...inputFields, attachment: file })
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (isSaveButtonDisabled) return

    setLoader(true)
    const formData = new FormData()
    formData.append('file', imageObj)
    formData.append('type', 'post')

    if (inputFields.post_id) {
      EditPostData(inputFields, inputFields.post_id)
    } else {
      imageUploadCreator(formData).then((res) => {
        if (res.data.success) {
          inputFields.attachment = dataDecrypt(res.data.data)
          addPost(inputFields).then((resData) => {
            if (resData.data.success) {
              getPostData()
              toast.success(resData?.data?.message)
              setShowVideoModel({ show: false, isEditing: false })
              setLoader(false)
              ResetValue()
            } else {
              toast.error(resData?.data?.message)
              setLoader(false)
            }
          })
        } else {
          toast.error(res?.data?.message)
          setLoader(false)
        }
      })
    }
  }

  const deletePhoto = (postId) => {
    const data = {
      app_id: inputFields.app_id,
      post_id: postId
    }
    setLoading(true)
    deletePost(data).then((res) => {
      if (res.data.success) {
        toast.success(res?.data?.message)
        setShowConfirmModel(false)
        getPostData()
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(JSON.parse(localStorage.getItem('app_id')) || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [])

  useEffect(() => {
    if (localStorageValue !== null) {
      getPostData()
    }
  }, [localStorageValue])

  return (
    <div className="stories-component add-photos common-card-body">
      <div className="gallery_add_btn">
        <h3>Gallery</h3>
        {getImage.length < 3 && (
          <div className="add-video-rows main_btn">
            <CommonButton
              label="Add New Image"
              imgSrc={AddIco}
              onClick={AddVideo}
              isPrimary
              className="add_new_content"
            />
          </div>
        )}
      </div>

      <div className="photos-grid">
        {getImage.length === 0 ? (
          <div className="photos-grid-item">
            <div className="image_add">
              <button type="button" onClick={AddVideo}>
                <img src={Plus} alt="add library" />
              </button>
            </div>
          </div>
        ) : (
          getImage.map((image, index) => (
            <div
              key={index}
              className="photos-grid-item photos-grid-item-added"
            >
              <img src={s3ImagePathCreator + image.attachment} alt="Uploaded" />
              <div className="image-buttons">
                <label
                  onClick={() => {
                    setInputFields({
                      description: image.description,
                      app_id: JSON.parse(localStorage.getItem('app_id')).id,
                      location: image.location,
                      post_id: image.post_id
                    })
                    setImages(s3ImagePathCreator + image.attachment)
                    setShowVideoModel({ show: true, isEditing: true })
                  }}
                >
                  {/* <input
                  className="d-none"
                  type="file"
                  accept="image/*"
                  // onChange={(event) => handleReplaceImage(image.key, event)}
                /> */}
                </label>
                {/* <button type="button" onClick={() => handleDeleteImage(image.key)}></button> */}
                <button type="button" onClick={ShowConfirm} />
                {ShowConfirmModel && (
                  <div className="add-item-model">
                    <div className="add-item-contents h-auto w-auto">
                      <div className="item-details-input pt-0">
                        <h3 className="mb-0 text-center">
                          Do you want to delete this?
                        </h3>
                        <div className="button-row justify-content-center">
                          <button
                            type="button"
                            className="secondary-btn-modelConfirm"
                            onClick={HandleCancelConfirm}
                          >
                            <span>Cancel</span>
                          </button>
                          <button
                            type="button"
                            className="primary-btn-modelConfirm"
                            onClick={() => {
                              setUpdatingIndex(index)
                              deletePhoto(image.post_id)
                            }}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {loading && updatingIndex === index && <Loader />}
            </div>
          ))
        )}
      </div>
      {ShowVideoModel.show && (
        <div className="add-item-model">
          <div className="add-item-content">
            <div className="d-flex justify-content-between model-header header">
              <h3 className="Gallery_header">
                {ShowVideoModel.isEditing ? 'Edit Image' : 'Add Image'}
              </h3>
              <button type="button" onClick={HandleCancel} disabled={loader}>
                <img className="close-btns" src={CloseIco} alt="" />
              </button>
            </div>
            <div className="scroll-bars">
              <div className="item-details-input pt-0">
                <form onSubmit={handleSubmit}>
                  <div className="upload-input upload-input-with-border">
                    <label htmlFor="upload-file">
                      <CommonImageUpload
                        handleImageChange={handleImageChange}
                        index={0}
                        buttonText="Upload Cover"
                        selectedImg={images}
                        defaultImg=""
                        className=""
                        forImage="image"
                        forName="video"
                      />
                    </label>
                    {/* <img src={images} alt="" /> */}

                  </div>

                  <div>
                    <label>Location</label>
                    <Autocomplete
                      placeholder="Add Location Here"
                      required
                      onChange={handleChange}
                      name="location"
                      value={inputFields.location}
                      apiKey={locationSuggestion}
                      onPlaceSelected={(place) => {
                        setInputFields({
                          ...inputFields,
                          location: place.formatted_address
                        })
                        setIsFormModified(true)
                      }}
                    />
                  </div>

                  <div>
                    <label>Decription</label>
                    <CommonInput
                      name="description"
                      required
                      placeholder="Add Description Here"
                      onChange={handleChange}
                      value={inputFields.description}
                    />
                  </div>

                  <div className="button-row">
                    <CommonButton
                      label="Cancel"
                      type="button"
                      onClick={HandleCancel}
                      isPrimary={false}
                      disabled={loader}
                      context="Profile"
                    />
                    <CommonButton
                      label="Save"
                      loading={loader}
                      type="submit"
                      isPrimary
                      disabled={isSaveButtonDisabled || !isFormModified || loader}
                      context="Profile"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default AddPhotos
