import React, { useState, useEffect } from 'react'
import './profile.css'
import { useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'
import Dropdown from '../Dropdown/Dropdown'
import { UserProfile, s3ImagePath } from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
import PlusIco from '../../images/PlusIco.svg'
import EditIco from '../../images/EditIco.svg'
import 'react-loading-skeleton/dist/skeleton.css'
import CommonButton from '../ButtonComponent/ButtonCommon'

const Profiles = () => {
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const getUserProfiles = async () => {
    setLoading(true)
    UserProfile().then((res) => {
      if (res.data.success) {
        setUserData(dataDecrypt(res.data.data))
        setLoading(false)
      }
    })
  }

  const onEditClick = (userData) => {
    localStorage.setItem('app_id', JSON.stringify(userData.application_data))
    if (userData.add_ons_data[0].name === 'Store') {
      navigate(`/store-builder/${userData.user_id}`)
    } else if (userData.add_ons_data[0].name === 'Entertainment') {
      navigate(`/profile-builder/${userData.user_id}`)
    }
  }

  useEffect(() => {
    getUserProfiles()
  }, [])

  return (
    <div className="profiles-section">
      <div className="profiles-section-sub">
        <div className="d-flex align-items-center justify-content-between profiles-btn">
          <h1>Profiles</h1>
          <div className="profile-button-group">
            <CommonButton
              label="Create New Profile"
              imgSrc={PlusIco}
              onClick={() => navigate('/create-profile')}
              isPrimary
              className="add_new_content primary-btn disabledDropdown"
            />
            <Dropdown
              title="Profile type"
              customClass="profile-type-dropdown disabledDropdown"
            />
          </div>
        </div>
        <p className="sub-text">
          Here is an overview of your Profiles so far. Click on which you want
          to see a preview of and customize it to your needs!
        </p>
        <div className="step-4">
          {loading ? (
            <div className="add-ons">
              <div className="add-ons-main-ing">
                <Skeleton height={150} width={200} />
              </div>
              <div className="footer-row">
                <div className="images-block profile_addons">
                  <Skeleton height={50} width={50} />
                </div>
                <div className="add-ons-detail">
                  <h4>
                    <Skeleton width={100} />
                  </h4>
                  <p>
                    <Skeleton width={150} />
                  </p>
                  <p className="addons_name">
                    <Skeleton width={120} />
                  </p>
                  <button
                    type="button"
                    disabled
                    className="secondary-btn-model border border-0 mt-3"
                  >
                    <Skeleton width={80} height={25} />
                  </button>
                </div>
              </div>

              <div className="custome-badge shadow-none">
                <Skeleton className="rounded-3" width={80} height={30} />
              </div>

              <button
                type="button"
                className="btn-white border border-0 shadow-none"
              >
                <Skeleton className="rounded-3" width={100} height={30} />
              </button>
            </div>
          ) : (
            userData.map((i, index) => (
              <div key={index} className="add-ons">
                <div className="add-ons-main-ing">
                  <img alt="" src={s3ImagePath + i.icon} />
                </div>
                <div className="footer-row">
                  <div className="images-block profile_addons">
                    <img alt="" src={s3ImagePath + i.application_data.icon} />
                  </div>
                  <div className="add-ons-detail">
                    <h4>{i.user_name}</h4>
                    <p>{i.full_name}</p>
                    {/* <p className="addons_name">{i?.add_ons_data?.name}</p> */}
                    <CommonButton
                      label="Preview"
                      type="button"
                      isPrimary={false}
                      className="mt-2"
                      disabled
                    />
                  </div>
                </div>
                <div className="custome-badge badge-pending">Pending</div>
                <button
                  type="button"
                  onClick={() => onEditClick(i)}
                  className="btn-white"
                >
                  <img className="me-2" src={EditIco} alt="" />
                  <span className="edit_profile_text">Edit Profile</span>
                </button>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  )
}
export default Profiles
