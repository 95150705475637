import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import { toast } from 'react-toastify'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import {
  addEditBanner,
  deleteBanner,
  getBanner,
  imageUpload,
  s3ImagePath
} from '../../services/Service'
import { dataDecrypt } from '../../services/http-services'
// import edit_icon from '../../images/edit_icon.svg'
// import delete_icon from '../../images/delete_icon.svg'
import Loader from '../Loader/Loader'
import UploadImg from '../../images/upload.svg'
import CommonInput from '../InputComponet/CommonInput'

const InputComponent = () => {
  const [banner, setBanner] = useState([])
  const [loading, setLoading] = useState(false)
  const [ShowConfirmModel, setShowConfirmModel] = useState(false)

  const [inputFields, setInputFields] = useState({
    banner: ''
  })
  const fileInputRef = useRef(null)

  const handleChange = (e) => {
    setInputFields({ ...inputFields, [e.target.name]: e.target.value })
    EditBanner(e.target.files[0])
  }

  const ShowConfirm = () => {
    setShowConfirmModel(true)
  }
  const HandleCancelConfirm = () => {
    setShowConfirmModel(false)
  }

  const getBannerData = async () => {
    setLoading(true)
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    const platFormData = {
      app_id: parsedData.id
    }
    await getBanner(platFormData).then((res) => {
      if (res.data.success) {
        setBanner(dataDecrypt(res.data.data))
        console.log(dataDecrypt(res.data.data))
        setLoading(false)
      }
    })
  }

  const deleteMainBanner = (data) => {
    const platFormData = {
      app_id: data.app_id,
      banner_id: data.id
    }
    setLoading(true)
    deleteBanner(platFormData).then((res) => {
      if (res.data.success) {
        setBanner(dataDecrypt(res.data.data))
        toast.success(res?.data?.message)
        setLoading(false)
        setShowConfirmModel(false)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const EditBanner = ({ file }) => {
    setLoading(true)
    imageUpload(file).then((res) => {
      if (res.data.success) {
        inputFields.banner = dataDecrypt(res.data.data)
        userDataSubmit()
        // setLoading(false)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const userDataSubmit = () => {
    const dataId = localStorage.getItem('app_id')
    const parsedData = JSON.parse(dataId)
    inputFields.app_id = parsedData.id
    setLoading(true)
    addEditBanner(inputFields).then((res) => {
      if (res.data.success) {
        getBannerData()
        toast.success(res?.data?.message)
        // setLoading(false)
      } else {
        toast.error(res?.data?.message)
      }
    })
  }

  const onEditLocalFile = (event) => {
    event?.preventDefault()
    fileInputRef.current.click()
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      getBannerData()
    }, 1500)

    return () => clearTimeout(timer)
  }, [])

  const componentRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setShowConfirmModel(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const [localStorageValue, setLocalStorageValue] = useState(() => {
    return JSON.parse(localStorage.getItem('app_id')) || null
  })

  useEffect(() => {
    const handleStorageChange = () => {
      setLocalStorageValue(JSON.parse(localStorage.getItem('app_id')) || null)
    }

    window.addEventListener('storage', handleStorageChange)

    return () => window.removeEventListener('storage', handleStorageChange)
  }, [])

  useEffect(() => {
    if (localStorageValue !== null) {
      getBannerData()
    }
  }, [localStorageValue])

  return (
    <div className="item-details-input-component">
      {banner.length > 0 ? (
        banner.map((d, i) => (
          <div key={i} className="banner-main position-relative">
            <img alt="" className="banner-image" src={s3ImagePath + d.banner} />
            <div className="image-buttons z-1">
              <label className="overflow-hidden">
                <ImgCrop rotate aspect={16 / 9}>
                  <Upload
                    name="banner"
                    listType="picture"
                    showUploadList={false}
                    customRequest={EditBanner}
                  >
                    <button
                      type="button"
                      className="d-none"
                    />
                  </Upload>
                </ImgCrop>
              </label>
              <button
                type="button"
                className="p-0 banner-delete"
                onClick={ShowConfirm}
              />
            </div>
            <div className="banner-btn">
              <form onSubmit={onEditLocalFile}>
                <CommonInput
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  type="file"
                  id="banner-img"
                  onChange={handleChange}
                />
                {/* <button type="button"
                className="edit-delete" onClick={(e) => onEditLocalFile(e)}>
                  <img alt="" src={edit_icon} />
                </button> */}
              </form>

              {/* <button type="button" className="edit-delete" onClick={ShowConfirm}>
                <img alt="" src={delete_icon} />
              </button> */}
              {ShowConfirmModel && (
                <div className="add-item-model">
                  <div
                    className="add-item-contents h-auto w-auto"
                    ref={componentRef}
                  >
                    <div className="item-details-input pt-0">
                      <h3 className="mb-0 text-center">
                        Do you want to delete this?
                      </h3>
                      <div className="button-row justify-content-center">
                        <button
                          type="button"
                          className="secondary-btn-model"
                          onClick={HandleCancelConfirm}
                        >
                          <span>Cancel</span>
                        </button>
                        <button
                          type="button"
                          className="primary-btn-model"
                          onClick={() => {
                            deleteMainBanner(d)
                          }}
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="upload-input upload-input-with-border banner-height">
          {loading && <Loader /> }
          {!loading && (
            <label htmlFor="upload-file" className="banner_label">
              <label htmlFor="upload-file">
                <ImgCrop rotate aspect={16 / 9}>
                  <Upload
                    name="banner"
                    listType="picture"
                    showUploadList={false}
                    customRequest={EditBanner}
                  >
                    <div className="upload-img">
                      <img alt="" src={UploadImg} />
                    </div>
                    <h4>Upload a Photo</h4>
                    <p>Drag and drop your file or click the button below to upload</p>
                    <button
                      type="button"
                      htmlFor="upload-file"
                    >
                      <span>Upload File</span>
                    </button>
                  </Upload>
                </ImgCrop>
              </label>

            </label>

          )}
        </div>
      )}
    </div>
  )
}
export default InputComponent
