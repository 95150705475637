import React, { useState, useEffect } from 'react'
import './CreatorProfile.css'
import { useParams, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'
import { dataDecrypt } from '../../services/http-services'

import { getAddOns, s3ImagePath, editAddonsData } from '../../services/Service'
import CommonButton from '../ButtonComponent/ButtonCommon'

const InstallAddons = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [addOnsData, setAddOnsData] = useState([])
  const [app_id] = useState(JSON.parse(localStorage.getItem('app_id')).id)
  const [isLoading, setIsLoading] = useState(false)
  const [initialAddOnsData, setInitialAddOnsData] = useState([])

  console.log(params.id)
  useEffect(() => {
    setIsLoading(true)
    getAddOns().then((res) => {
      if (res.data.success) {
        const Arr = dataDecrypt(res.data.data)
        Arr.forEach((item1, index) => {
          JSON.parse(localStorage.getItem('app_id')).add_ons_data.forEach(
            (item2) => {
              if (item2.id === item1.id) {
                Arr[index].checked = true
              }
            }
          )
        })
        setAddOnsData(Arr)
        setInitialAddOnsData(JSON.parse(JSON.stringify(Arr)))
        setIsLoading(false)
      }
    })
  }, [])

  const installClick = () => {
    const add_ons = addOnsData
      .filter(({ checked }) => checked)
      .map((e) => e.id)
    const data = {
      app_id,
      add_ons
    }
    editAddonsData(data).then((res) => {
      if (res.data.success) {
        window.dispatchEvent(new Event('storage'))
        navigate(`/profile-builder/${params.id}`)
        toast.success(res?.data?.message)
      }
    })
  }


  const isInstallButtonDisabled = () => {
    return addOnsData.every(
      (addon, index) => addon.checked === initialAddOnsData[index].checked
    )
  }

  return (
    <div className="creator-profile-main creator-profile-create">
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <p className="sub-text">Choose Add ons:</p>
        </div>
        <div className="step-4">
          {isLoading ? (
            <div className="add-ons">
              <div className="add-ons-main-ing">
                <Skeleton width={100} height={100} />
              </div>
              <div className="footer-row">
                <div className="images-block">
                  <Skeleton width={100} height={100} />
                </div>
                <div className="add-ons-detail">
                  <h4>
                    <Skeleton max-width={175} />
                  </h4>
                  <p>
                    <Skeleton max-width={175} height={50} />
                  </p>
                </div>
              </div>
              <div className="custome-checkbox">
                <Skeleton type="checkbox" width={20} height={20} />
              </div>
            </div>
          ) : (
            addOnsData.map((i, index) => (
              <div key={index} className="add-ons">
                <div className="add-ons-main-ing">
                  <img alt="" src={s3ImagePath + i.image} />
                </div>
                <div className="footer-row">
                  <div className="images-block">
                    <img alt="" src={s3ImagePath + i.icon} />
                  </div>
                  <div className="add-ons-detail">
                    <h4>{i.name}</h4>
                    <p>{i.description}</p>
                  </div>
                </div>
                <div className="custome-checkbox">
                  <input
                    type="checkbox"
                    value={i.id}
                    checked={i.checked}
                    disabled={i.disabled}
                    onChange={(e) => {
                      const newCheckboxes = [...addOnsData]
                      newCheckboxes[index].checked = e.target.checked
                      setAddOnsData(newCheckboxes)
                    }}
                  />
                </div>
              </div>
            ))
          )}
        </div>
        <div className="steps-button">
          {/* <button
            disabled={isInstallButtonDisabled()}
            className="primary-button btn-next"
            type="button"
            onClick={installClick}
          >
            <span>Install</span>
          </button> */}
          <CommonButton
            label="Install"
            type="button"
            onClick={installClick}
            isPrimary
            className="primary-button rounded-pill"
            disabled={isInstallButtonDisabled()}
            context="Profile"
          />
        </div>
      </div>
    </div>
  )
}
export default InstallAddons
